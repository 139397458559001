import { css as CSS } from "@codemirror/lang-css";
import CodeMirror from "@uiw/react-codemirror";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
// @ts-ignore
import {
  BackButton,
  FormFooter,
  Loader,
  PageHeadeline,
} from "../../components";
import { MainContainer } from "../../components/layout/MainContainer";
import {
  TEMPLATES_API,
  TEMPLATES_PATH,
  USER_PROFILE_URL,
} from "../../constants";
import { useFetch } from "../../hooks";
import { ResetButton } from "./components";
import { PreviewButton } from "./components/PreviewButton";
import { ITemplate, templateIState } from "./interface";

export const GlobalCSS = () => {
  const [css, setCss] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [globalCssTemplate, setGlobalCssTemplate] =
    useState<ITemplate>(templateIState);

  const {
    apiCall: getGlobalCSSTemplate,
    response: global,
    loading,
  } = useFetch("get");
  const { apiCall: updateGlobalCSSTemplate, loading: updateTemplateLoading } =
    useFetch("put");

  useEffect(() => {
    getGlobalCSSTemplate(`${TEMPLATES_API}/10`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (global) {
      setGlobalCssTemplate(global);
      setCss(global?.previewCss);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);

  const onCSSEditorChange = useCallback((value: string) => {
    setIsDirty(true);
    setCss(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetGlobalCss = () => {
    setIsDirty(true);
    toast.success("Global CSS has been reset to default!");
    setCss(globalCssTemplate?.defaultCss || "");
  };

  const onTemplateFormSubmit = () => {
    const templateObj = {
      ...globalCssTemplate,
      previewCss: css,
      css,
    };

    updateGlobalCSSTemplate(
      `${TEMPLATES_API}/${globalCssTemplate?.id}`,
      templateObj,
      (response) => {
        toast.success("Global CSS updated successfully!");
        setGlobalCssTemplate(response);
      },
      (err) => {
        console.log("error", err);
      }
    );
  };

  // Update default css (only for devs!)
  // const onTemplateDefaultFormSubmit = () => {
  //   const templateObj = {
  //     ...globalCssTemplate,
  //     defaultCss: css,
  //   };

  //   updateTemplate(
  //     `https://templates.porta.gjirafa.dev/api/v2/templates/${globalCssTemplate?.id}`,
  //     templateObj,
  //     () => {
  //       toast.success("Template updated successfully!");
  //     },
  //     (err) => {
  //       console.log("error", err);
  //     }
  //   );
  // };

  const onTemplatePreview = () => {
    const templateObj = {
      ...globalCssTemplate,
      previewCss: css,
    };

    updateGlobalCSSTemplate(
      `${TEMPLATES_API}/${globalCssTemplate?.id}`,
      templateObj,
      () => {
        // toast.success("Template updated successfully!");
      },
      (err) => {
        console.log("error", err);
      }
    );

    window.open(`${USER_PROFILE_URL}?mode=preview` || "", "_blank");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <MainContainer>
      <div>
        <BackButton to={TEMPLATES_PATH} title="Go Back" />
        <PageHeadeline title="Global CSS" description="" />
      </div>
      <div className="border border-primary-stroke rounded mt-6">
        <div className="border-b border-primary-stroke py-6 px-4 flex justify-end bg-white">
          <div className="flex items-center">
            <PreviewButton onClick={onTemplatePreview} />
            <ResetButton onClick={onResetGlobalCss} />
          </div>
        </div>
        <div className="p-2">
          <CodeMirror
            value={css}
            theme="dark"
            // onUpdate={(value: any) => onEditorUpdate(value)}
            extensions={[CSS()]}
            onChange={onCSSEditorChange}
          />
        </div>

        <FormFooter
          onSubmit={onTemplateFormSubmit}
          disabled={!isDirty}
          loading={updateTemplateLoading}
          mtAuto
          pb="6"
          pt="6"
          className="py-6 px-4 sticky bottom-0 bg-white"
          saveBtnText="Save & Publish"
        />
      </div>
    </MainContainer>
  );
};
