import Select from "react-select";
import Search from "../../../components/form/Search";
import { reactSelectStyle } from "../../../utils";
import { ILngState } from "../interface";

interface IEditKeysHeader {
  resource: {
    name: string;
    numberOfFilteredResources: string;
    numberOfResources: string;
  };
  handleNameSpaceSelect: (selected: any) => void;
  handleSearch: (e: any) => void;
  lState: ILngState;
}

export const EditKeysHeader: React.FC<IEditKeysHeader> = ({
  resource,
  handleNameSpaceSelect,
  lState,
  handleSearch,
}) => {
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "select-dropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });

        window.scrollTo(0, 0);
      }
    }, 15);
  };

  return (
    <div className="flex items-center justify-between mb-6">
      <h6 className="text-primary-mainText">
        Language Keys
        <span className="ml-2 text-sm text-primary-secText">
          {resource.numberOfFilteredResources} / {resource.numberOfResources}{" "}
          Key Phrases
        </span>
      </h6>

      <div className="flex items-center">
        <Select
          onChange={handleNameSpaceSelect}
          defaultValue={lState.namespace}
          options={lState.namespaces}
          onMenuOpen={onMenuOpen}
          getOptionLabel={(x) => x.beautifiedNamespace}
          getOptionValue={(x) => x.nameSpace}
          hideSelectedOptions={false}
          isSearchable
          menuPortalTarget={document.body}
          classNamePrefix="porta-react-select"
          className="w-48 text-sm mr-4 select-dropdown"
          styles={reactSelectStyle}
        />

        <Search
          onChange={handleSearch}
          value={lState.term}
          placeholder="Search..."
          className="h-full"
        />
      </div>
    </div>
  );
};
