import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import {
  DropdownOption,
  ThreeDotsButton,
  ToggleSwitch,
  Table,
  Td,
  PageHeadeline,
} from "../../components";
import { SETTINGS_LANGUAGES_PATH } from "../../constants";
import { useFetch } from "../../hooks";
import { cx, SEO } from "../../utils";
import { Dropdown } from "../clients/api-resources/components";
import { DefaultTag } from "./components";
import { ILanguage } from "./interface";
import { MainContainer } from "../../components/layout/MainContainer";

export const Languages: React.FC = () => {
  const [languages, setLanguages] = useState<ILanguage[]>();
  const [defaultLang, setDefaultLang] = useState<ILanguage>();

  const { apiCall: getLanguages, response, loading } = useFetch("get");
  const { apiCall: updateLanguages } = useFetch("post");

  useEffect(() => {
    SEO({
      title: "Porta - Languages",
    });

    getLanguages("Localization/Languages");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLanguages(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const findDefaultLange = (languages: ILanguage[]) => {
    return languages?.find((language) => language.isDefault);
  };

  useEffect(() => {
    setDefaultLang(findDefaultLange(languages || []));
  }, [languages]);

  const handleDefaultLanguage = (selected: ILanguage) => {
    const filterLanguages = languages?.map((language) => {
      if (selected?.id === language.id) {
        return { ...language, isDefault: true, isSelected: true };
      } else {
        return { ...language, isDefault: false };
      }
    });

    const defaultLang = findDefaultLange(filterLanguages || []);

    setLanguages(filterLanguages);
    document.body.click();

    updateLanguages("Localization/Languages", filterLanguages, () => {
      toast.success(`${defaultLang?.name} has been set as a default language!`);
    });
  };

  const handleCheckedLanguage = (laguage: ILanguage, value: any) => {
    const languagesStatus = languages?.map((language) => {
      const isSelected = value.target.checked;

      if (laguage.id === language.id) {
        return {
          ...language,
          isSelected,
        };
      } else {
        return { ...language };
      }
    });

    setLanguages(languagesStatus);

    updateLanguages("Localization/Languages", languagesStatus, () => {
      toast.success("Language Settings updated successfully!");
    });
  };

  return (
    <MainContainer>
      <PageHeadeline
        className="mb-10"
        title="Languages"
        description="Manage languages and the default source text that is displayed to end-users throughout their authentication experience."
      />

      <form className="flex flex-col h-full">
        <Table
          names={[
            { children: "Name" },
            { children: "Locale" },
            { children: "", className: "text-right border-transparent" },
          ]}
          loading={loading}
          values={languages || []}
          renderTr={(el: ILanguage) => {
            const editLangKeysPath = `${SETTINGS_LANGUAGES_PATH}/${el?.languageCode}`;
            const editLangKeysState = {
              selectedRow: {
                defaultLang: defaultLang?.name,
                languages: response,
                defaultLangCode: defaultLang?.countryCode,
                ...el,
              },
            };

            return (
              <tr className="hover:bg-gray-50 transition" key={el.id}>
                <Td
                  className={cx([
                    "text-primary-mainText w-60",
                    el.isDefault && "font-medium",
                  ])}
                >
                  <NavLink
                    to={editLangKeysPath}
                    state={editLangKeysState}
                    className="hover:text-primary"
                  >
                    {el.name}
                  </NavLink>{" "}
                  {el.isDefault && <DefaultTag className="ml-3" />}
                </Td>
                <Td className="relative text-primary-mainText">
                  <div className="flex items-center">
                    <img
                      src={`https://flagcdn.com/48x36/${el?.countryCode.toLocaleLowerCase()}.png`}
                      width={18}
                      alt=""
                      className="mr-2"
                    />{" "}
                    {el?.languageCode}
                  </div>
                </Td>

                <Td className="text-primary-secText" align="right">
                  <div className="flex items-center justify-end">
                    <ToggleSwitch
                      key={el.id}
                      wrapperClassName="mr-10"
                      inputHolderClass={cx([el.isDefault && "cursor-default"])}
                      id={el?.id.toString()}
                      checked={el.isSelected || false}
                      disabled={el.isDefault}
                      onChange={(event) => {
                        handleCheckedLanguage(el, event);
                      }}
                    />

                    <Dropdown
                      width="w-40"
                      noPadding
                      dropdownContent={
                        <>
                          <DropdownOption
                            disabled={el.isDefault}
                            label="Make Default"
                            onClick={() => handleDefaultLanguage(el)}
                          />

                          <DropdownOption
                            label="Edit Keys"
                            isLink
                            to={editLangKeysPath}
                            state={editLangKeysState}
                          />
                        </>
                      }
                    >
                      <ThreeDotsButton />
                    </Dropdown>
                  </div>
                </Td>
              </tr>
            );
          }}
        />
      </form>
    </MainContainer>
  );
};
