import { FC, KeyboardEvent, useState, ChangeEvent, useEffect } from "react";

import { NavLink } from "react-router-dom";

import { useCurrentPage, useFetch, useToggle } from "../../hooks";
import {
  DropdownOption,
  ThreeDotsButton,
  SearchHeader,
  Table,
  Td,
  PageHeadeline,
  Avatar,
  AvatarSize,
  AvatarRounded,
} from "../../components";
import { CMP_API_URL, DOMAINS_PATH } from "../../constants";
import { Dropdown } from "../clients/api-resources/components";
import { CreateDomainModal } from "./components";
import { IDomain } from "../../interfaces";
import { SEO } from "../../utils";
import { MainContainer } from "../../components/layout/MainContainer";

export const Domains: FC = () => {
  // TODO: should add interface
  const [response, setResponse] = useState<any>();
  const { domains, pagingInfo } = !!response && response;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const { toggle, visible } = useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();
  const { apiCall: getDomains, loading, errors } = useFetch("get");

  const PER_PAGE = 10;

  useEffect(() => {
    SEO({
      title: "Porta - Domains",
    });
  }, []);

  const getDomainsData = () => {
    getDomains(
      `${CMP_API_URL}/DomainListView?search=${searchTerm}&page=${currentPage}&pageSize=${PER_PAGE}`,
      {},
      (response) => {
        setResponse(response);
      }
    );
  };

  useEffect(() => {
    getDomainsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value);
  };

  // TODO: should move these functions to Search component, or SearchHeader
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getDomainsData();
    }
  };

  const handleSearchBtnClick = () => {
    searchTerm !== "" && getDomainsData();
  };

  const handleClearInputBtn = () => {
    setSearchTerm("");
  };

  const emptySectionText =
    !!searchTerm && domains?.length < 1
      ? `No domain found.`
      : "There are no domains yet.";

  return (
    <>
      <MainContainer>
        <PageHeadeline
          title="Domains"
          description="Create and manage domains for your applications."
          className="mb-10"
        />

        <SearchHeader
          searchValue={searchTerm}
          onSearchChange={handleSearch}
          onClearInputBtn={handleClearInputBtn}
          onSearchBtnClick={handleSearchBtnClick}
          onSearchKeypress={handleKeyPress}
          onButtonClick={toggle}
          buttonLabel="Add Domain"
        />
        <Table
          loading={loading}
          wrapperClassName="mt-4 sm:mt-6"
          names={[
            { children: "Name" },
            // { children: "Code" },
            { children: "Cookies" },
            { children: "Your script" },
            { children: "Details", className: "text-right" },
          ]}
          values={domains}
          renderTr={(el: IDomain) => {
            return (
              <tr className="transition hover:bg-gray-50" key={el?.encryptedId}>
                <Td>
                  <NavLink
                    to={`${DOMAINS_PATH}/${el?.encryptedId}/overview`}
                    className="hover:text-primary transition"
                  >
                    <div className="flex items-center">
                      <Avatar
                        size={AvatarSize.md}
                        rounded={AvatarRounded.rounded}
                        imgUrl=""
                        text={el?.name}
                        className="mr-4"
                        colourful
                        index={el.colorId}
                      />

                      {el?.name}
                    </div>
                  </NavLink>
                </Td>
                {/* <Td className="relative">{el?.code}</Td> */}
                <Td>{el?.cookies?.length}</Td>
                <Td>View</Td>
                <Td className="text-primary-secText" align="right">
                  <Dropdown
                    width="w-44"
                    noPadding
                    dropdownContent={
                      <>
                        <DropdownOption
                          isLink
                          to={`${DOMAINS_PATH}/${el?.encryptedId}/overview`}
                          label="View Details"
                        />
                      </>
                    }
                  >
                    <ThreeDotsButton />
                  </Dropdown>
                </Td>
              </tr>
            );
          }}
          pagination={{
            pageSize: PER_PAGE,
            totalCount: pagingInfo?.totalItems,
            onPageChange: handlePageChange,
            page: currentPage,
            length: domains?.length,
          }}
          emptyView={{ text: errors || emptySectionText }}
        />
      </MainContainer>

      {visible && (
        <CreateDomainModal
          visible={visible}
          toggle={toggle}
          response={response}
          setResponse={setResponse}
        />
      )}
    </>
  );
};
