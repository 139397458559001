import { ReactNode } from "react";
import { cx } from "../../utils";

export enum TooltipPosition {
  left = "left-0",
  center = "left-1/2 transform -translate-x-1/2",
  right = "right-0",
  bottom = "bottom-0",
  underTooltip = "-bottom-20 z-50",
}

export enum ArrowPosition {
  left = "left-2",
  center = "left-1/2 transform -translate-x-1/2",
  right = "right-2",
  top = "-top-1 transform rotate-180 h-1",
}

interface ITooltip {
  className?: string;
  innerClassName?: string;
  text: string;
  preserveText?: boolean;
  tooltipPosition?: TooltipPosition;
  arrowPosition?: ArrowPosition;
  children: ReactNode;
  bottom?: string;
}

export const Tooltip: React.FC<ITooltip> = ({
  className,
  innerClassName,
  text,
  preserveText = false,
  tooltipPosition = TooltipPosition.center,
  arrowPosition = ArrowPosition.center,
  children,
  bottom = "bottom-10",
}) => {
  return (
    <div className={cx(["relative group inline-block", className])}>
      {children}

      <div
        className={cx([
          "py-1 px-2 text-xs text-white bg-primary-mainText rounded w-21 absolute invisible group-hover:visible opacity-0 transition group-hover:opacity-100",
          tooltipPosition,
          innerClassName,
          bottom,
        ])}
      >
        {preserveText ? <pre>{text}</pre> : <span>{text}</span>}

        <div className={cx(["absolute -bottom-1", arrowPosition])}>
          <svg
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.56569 3.43432C4.25327 3.74673 3.74673 3.74673 3.43431 3.43431L1.27146e-07 6.99382e-07L8 0L4.56569 3.43432Z"
              fill="#4B4B4B"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
