import { FC, KeyboardEvent, useState, ChangeEvent, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch, useToggle } from "../../hooks";
import { Dropdown } from "../../pages/clients/api-resources/components";
import {
  Modal,
  ThreeDotsButton,
  DropdownOption,
  LimitedTagsDropdown,
  SearchHeader,
  Table,
  Td,
  PageHeadeline,
  Avatar,
  AvatarSize,
  AvatarRounded,
} from "../../components";
import { ModalSize } from "../../components/modal";
import { BRANDINGS_PATH } from "../../constants";
import { IBranding } from "../../interfaces";
import { SEO } from "../../utils";
import { MainContainer } from "../../components/layout/MainContainer";

export const Brandings: FC = () => {
  // TODO: should pass IBrandingsResonse type
  const [brandingsResponse, setBrandingsResponse] = useState<any>();
  const [branding, setBranding] = useState<IBranding>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigate = useNavigate();

  const { toggle, visible } = useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();
  const PER_PAGE = 10;

  const { apiCall: getBrandings, response, loading, errors } = useFetch("get");
  const { apiCall: deleteBranding, loading: deleteBrandingLoading } =
    useFetch("delete");

  useEffect(() => {
    setBrandingsResponse(response);
  }, [response]);

  const { totalCount, data } = !!brandingsResponse && brandingsResponse;

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value);
  };

  useEffect(() => {
    SEO({
      title: "Porta - Branding",
    });
  }, []);

  const getBrandingData = () => {
    getBrandings(
      `BrandingConfigurations/GetBrandingsList?searchText=${searchTerm}&page=${currentPage}&pageSize=${PER_PAGE}`
    );
  };

  useEffect(() => {
    getBrandingData();
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getBrandingData();
    }
  };

  const handleSearchBtnClick = () => {
    searchTerm !== "" && getBrandingData();
  };

  const handleClearInputBtn = () => {
    setSearchTerm("");
  };

  const redirectToCreate = () => {
    navigate(`${BRANDINGS_PATH}/create`);
  };

  const onDeleteBranding = () => {
    deleteBranding(
      `BrandingConfigurations/Delete?id=${branding?.id}`,
      {},
      () => {
        toggle();
        toast.success("Branding deleted successfullu!");

        const data = brandingsResponse?.data?.filter((item: IBranding) => {
          return item.id !== branding?.id;
        });

        setBrandingsResponse(() => ({
          ...brandingsResponse,
          data,
          totalCount: totalCount - 1,
        }));
      }
    );
  };

  const emptySectionText =
    !!searchTerm && data?.length < 1
      ? `No branding configuration found.`
      : "No brading configurations yet.";

  return (
    <MainContainer>
      <PageHeadeline
        className="mb-10"
        title="Universal Login"
        description="Customize the look and feel of your login and registration pages."
      />

      <SearchHeader
        searchValue={searchTerm}
        onClearInputBtn={handleClearInputBtn}
        onSearchKeypress={handleKeyPress}
        onSearchBtnClick={handleSearchBtnClick}
        onSearchChange={handleSearch}
        onButtonClick={redirectToCreate}
        buttonLabel="Create Customization"
      />

      <Table
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Name" },
          { children: "Applications" },
          { children: "Date" },
          { children: "Actions", className: "text-right" },
        ]}
        loading={loading}
        values={data}
        renderTr={(el: IBranding) => {
          return (
            <tr className="hover:bg-gray-50 transition" key={el.id}>
              <Td className="py-3">
                <NavLink
                  to={`${BRANDINGS_PATH}/${el.id}`}
                  className="hover:text-primary flex items-center"
                >
                  <Avatar
                    size={AvatarSize.md}
                    rounded={AvatarRounded.rounded}
                    imgUrl=""
                    text={el?.name}
                    className="mr-4"
                    colourful
                    index={el.colorId}
                  />
                  <div>
                    {el.name}

                    {el.isDefault && (
                      <div className="text-xs text-primary-mainText flex items-center mt-1">
                        <span className="w-1.5 h-1.5 bg-primary-secText rounded-full inline-block mr-2" />
                        Default
                      </div>
                    )}
                  </div>
                </NavLink>
              </Td>
              <Td className="relative py-3">
                <LimitedTagsDropdown tagLimit={4} tags={el?.applicationNames} />
              </Td>

              <Td className="py-3">
                <p className="text-sm text-primary-mainText">
                  Created at{" "}
                  {format(new Date(el?.dateCreated), "MMMM dd, yyyy")}
                </p>
                <p className="text-sm text-primary-mainText">
                  {!!el?.dateUpdated
                    ? `Updated at  ${format(
                        new Date(el?.dateUpdated),
                        "MMMM dd, yyyy"
                      )}`
                    : "Not updated yet."}
                </p>
              </Td>

              <Td className="text-primary-secText py-3" align="right">
                <Dropdown
                  width="w-44"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        isLink
                        withIcon
                        iconName="Edit"
                        label="Edit"
                        to={`${BRANDINGS_PATH}/${el.id}`}
                      />

                      <DropdownOption
                        withIcon
                        iconName="Delete"
                        label="Delete"
                        onClick={() => {
                          toggle();
                          setBranding(el);
                        }}
                      />
                    </>
                  }
                >
                  <ThreeDotsButton />
                </Dropdown>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: data?.length,
        }}
        emptyView={{ text: errors || emptySectionText }}
      />

      {!!visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Delete branding"
          onConfirmClick={onDeleteBranding}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={deleteBrandingLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">{branding?.name}</strong>?
          </p>
        </Modal>
      )}
    </MainContainer>
  );
};
