import { ButtonHTMLAttributes } from "react";
// @ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";
import { Spinner, SpinnerSize } from "../ui/Spinner";

export enum IconSize {
  normal = "px-2",
  medium = "px-3",
  large = "px-4",
}
export enum BtnWidth {
  default = "max-w-max",
  large = "max-w-full",
}

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  iconName: string;
  iconSize?: IconSize;
  btnSize?: BtnWidth;
  text: string;
  fontSize?: string;
  rounded?: string | boolean;
  loading?: boolean;
  disabled?: boolean;
  onButtonClick?: () => void;
}

export const IconButton: React.FC<IconButtonProps> = ({
  className,
  iconName,
  btnSize,
  iconSize,
  fontSize,
  text,
  disabled,
  loading,
  rounded = true,
  onButtonClick,
  ...props
}) => {
  return (
    <button
      type="button"
      onClick={onButtonClick}
      className={cx([
        `inline-flex items-center py-1 ${
          iconSize ? iconSize : IconSize.medium
        } w-full ${
          btnSize ? btnSize : BtnWidth.default
        } text-left transition hover:text-blue-mainText hover:border-blue-tertText focus:outline-none group`,
        disabled && "opacity-60 pointer-events-none",
        typeof rounded === "string" ? rounded : rounded && "rounded",
        className,
      ])}
      {...props}>
      <div className="mr-2 max-w-max flex-shrink-0 group-hover:text-primary">
        {loading ? (
          <Spinner
            size={SpinnerSize.xs}
            color="text-blue-tertText "
            padding="p-0 mr-2"
          />
        ) : (
          <span className="inline-block w-4 h-4">
            <Icon name={iconName} size={17} />
          </span>
        )}
      </div>
      <span className="text-sm">{text || ""}</span>
    </button>
  );
};
