import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Card,
  CardSpace,
  CardStyle,
  FormFooter,
  Paragraph,
  SectionTitle,
  Input,
  InputSize,
  Label,
  Tooltip,
} from "../../components";
import { TooltipPosition, ArrowPosition } from "../../components/tooltip";
import { useDomainContext } from "../../context";
import { inputClasses, reactSelectStyle, SEO } from "../../utils";
import { IDomain } from "../../interfaces";
import { useFetch } from "../../hooks";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { CMP_API_URL, DOMAINS_PATH } from "../../constants";
import { ICompliance, IDomainSettings } from "./interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

export const schema = object().shape({
  cookiePolicyUrl: string().url().nullable().required("Please enter website"),
  privacyPolicyUrl: string().url().nullable().required("Please enter website"),
});

export const DomainSettings: React.FC<IDomainSettings> = () => {
  const { domain, setDomain } = useDomainContext();
  const { languageList, geolocationEnum, geolocationEnumAsString } =
    !!domain && domain;
  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { apiCall: editGeolocationSettings, loading } = useFetch("post");
  const [complianceTypes, setComplianceTypes] = useState<ICompliance[]>();
  const gdprCompliantDescription =
    "The cookie banner will be shown to all users anywhere in the world. No cookies will be set unless the user has given their consent. The user will decide which cookies they would like to set except for essential cookies. Visitors in the United States will also be shown a “Do Not Sell My Personal Information” button.";

  const ccpaCompliantDescription =
    "The cookie banner will be shown to all users anywhere in the world. No cookies will be set unless the user has given their consent. The user will decide which cookies they would like to set except for necessary cookies.";

  let watchGeolocationValue = watch("geolocationEnumAsString");
  let complianceTypeDescription =
    watchGeolocationValue?.id === 1
      ? gdprCompliantDescription
      : ccpaCompliantDescription;

  const onEditGeolocationSettings: SubmitHandler<any> = (data: IDomain) => {
    console.log(
      "🚀 ~ file: DomainSettings.tsx ~ line 63 ~ data",
      data.geolocationEnumAsString
    );
    const domainObj = {
      ...data,
      encryptedLanguageId: data.language.encryptedId,
      geolocationEnumAsString: data.geolocationEnumAsString.name,
    };

    editGeolocationSettings(
      `${CMP_API_URL}/EditGeolocationSettings`,
      domainObj,
      () => {
        toast.success("Settings saved successfully!");
        setDomain({
          ...domain,
          geolocationEnumAsString: domainObj?.geolocationEnumAsString,
          language: domainObj?.language,
          cookiePolicyUrl: domainObj?.cookiePolicyUrl,
          privacyPolicyUrl: domainObj?.privacyPolicyUrl,
        });
      }
    );
  };

  useEffect(() => {
    SEO({
      title: "Porta - Domain Settings",
    });
  }, []);

  useEffect(() => {
    const compType = {
      id: geolocationEnum,
      name: geolocationEnumAsString,
    };
    const domainObj = { ...domain, geolocationEnumAsString: compType };
    reset(domainObj);
  }, [domain, reset, geolocationEnumAsString, geolocationEnum]);

  useEffect(() => {
    const complianceArr: ICompliance[] = [];
    domain?.geolocationEnumValues?.forEach((item: string) => {
      complianceArr.push({
        id: item === "GDPRCompliant" ? 1 : 2,
        name: item,
      });
    });
    setComplianceTypes(complianceArr);
  }, [domain]);

  return (
    <form>
      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mt-6"
      >
        <div className="flex items-center mb-6">
          <SectionTitle noMargin>Geolocation Settings</SectionTitle>
          <div className="relative group inline-block ml-2">
            <Tooltip
              text="When geolocation is enabled, Porta will show the consent banner to all users. The users in the United States will also be shown a “Do Not Sell My Personal Information” button. Porta recommends using settings and options that are GDPR Compliant and for websites with users in the United States GDPR & CCPA compliant."
              innerClassName="mb-0 w-96"
              className="text-sm text-primary-secText w-7 flex items-center"
              tooltipPosition={TooltipPosition.left}
              arrowPosition={ArrowPosition.left}
              // bottom="bottom-0"
            >
              <Icon name="Info" width={7} />
            </Tooltip>
          </div>
        </div>
        <Label required text="Geolocation" />
        <div className="flex flex-wrap flex-col">
          <Controller
            control={control}
            name="geolocationEnumAsString"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                // onChange={() => onChange(onChangeHandler)}
                onChange={onChange}
                options={complianceTypes}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                value={value || ""}
                isSearchable
                ref={ref}
                classNamePrefix="porta-react-select"
                menuPortalTarget={document.body}
                className="flex-1 text-sm mb-2 lg:w-3/4 w-full sm:mt-0 mt-4"
                styles={reactSelectStyle}
              />
            )}
          />
          <Paragraph className="flex-1 lg:w-3/4 w-full ">
            {complianceTypeDescription}
          </Paragraph>
        </div>
      </Card>

      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mt-6"
      >
        <div className="flex items-center mb-6">
          <SectionTitle noMargin>Language Settings</SectionTitle>
          <div className="relative group  ml-2">
            <Tooltip
              text="This is the primary language used for the consent notice, consent banner and other related dialogues."
              innerClassName="w-96 mb-0 "
              className="text-sm text-primary-secText flex items-center w-7"
              tooltipPosition={TooltipPosition.left}
              arrowPosition={ArrowPosition.left}
            >
              <Icon name="Info" width={7} />
            </Tooltip>
          </div>
        </div>

        <Label required text="Default language" />

        <div className="flex flex-wrap flex-col">
          <Controller
            control={control}
            name="language"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                onChange={onChange}
                options={languageList}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                value={value || ""}
                isSearchable
                ref={ref}
                classNamePrefix="porta-react-select"
                menuPortalTarget={document.body}
                className="flex-1 text-sm mb-2 lg:w-3/4 w-full sm:mt-0 mt-4"
                styles={reactSelectStyle}
              />
            )}
          />
        </div>
      </Card>

      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mt-6"
      >
        <SectionTitle noMargin className="mb-6">
          Privacy Policy
        </SectionTitle>

        <Label required text="Link to privacy policy" />
        <div className="flex flex-wrap flex-col">
          <Input
            containerClassname="flex-1 sm:mt-0 lg:w-3/4 w-full mt-4"
            {...register("privacyPolicyUrl", {
              // required: "This field is required",
            })}
            inputSize={InputSize.sm}
            className={inputClasses}
            type="url"
            error={
              (!!errors.privacyPolicyUrl && (
                <em>URL must follow pattern: https://www.abc.com</em>
              )) ||
              ""
            }
          />
          <Paragraph className="flex-1 lg:w-3/4 w-full ">
            Type the URL to your Privacy Policy page. This URL will be loaded
            when the user clicks the “Privacy Policy” link on the consent
            banner.
          </Paragraph>
        </div>
      </Card>

      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mt-6"
      >
        <SectionTitle noMargin className="mb-6">
          Cookie Policy
        </SectionTitle>

        <Label required text="Link to cookie policy" />
        <div className="flex flex-wrap flex-col ">
          <Input
            containerClassname="flex-1 sm:mt-0 lg:w-3/4 w-full"
            {...register("cookiePolicyUrl", {
              // required: "This field is required",
            })}
            inputSize={InputSize.sm}
            className={inputClasses}
            type="url"
            error={
              (!!errors.cookiePolicyUrl && (
                <em>URL must follow pattern: https://www.abc.com</em>
              )) ||
              ""
            }
          />
          <Paragraph className="flex-1 mt-2 lg:w-3/4 w-full ">
            Type the URL to your Cookie Policy page. This URL will be loaded
            when the user clicks the “Cookie Policy” link on the consent banner.
          </Paragraph>
        </div>
      </Card>

      <FormFooter
        onSubmit={handleSubmit(onEditGeolocationSettings)}
        loading={loading}
        backToLink={`${DOMAINS_PATH}/${domain?.encryptedId}/overview`}
      />
    </form>
  );
};
