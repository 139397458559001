import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

import { ISecret } from "../../interfaces";
import { BackButton, Container, SectionTitle } from "../../components";
import { NewSecretForm, SecretsTable } from "./components";
import { secretIState } from "../../utils";
import { useCurrentPage, useFetch } from "../../hooks";

export interface ISecrets {
  endpoint: string;
  mainTitle: string;
  backTo: string;
}

export const Secrets: FC<ISecrets> = ({ endpoint, mainTitle, backTo }) => {
  let { id: paramId } = useParams();
  const { state } = useLocation() as any;

  const [response, setResponse] = useState<any>();
  const { currentPage, setCurrentPage, handlePageChange } = useCurrentPage();
  const { apiCall: getSecrets, loading } = useFetch("get");
  const { apiCall: addSecret, loading: addSecretLoading } = useFetch("post");

  const PER_PAGE = 10;

  const { secrets } = !!response && response;

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!!paramId)
      getSecrets(
        `${endpoint}/${paramId}/Secrets?page=${currentPage}&pageSize=${PER_PAGE}`,
        {},
        (response) => {
          setResponse(response);
        }
      );
  }, [paramId, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddSecret: SubmitHandler<any> = (data: ISecret) => {
    const { type, value, hashType, expiration } = data;

    const secretObject = {
      ...secretIState,
      type: type?.value || secretIState.type.value,
      expiration: expiration || secretIState.expiration,
      value: value || secretIState.value,
      hashType: hashType?.value || secretIState.hashType.value,
    };

    addSecret(`${endpoint}/${paramId}/Secrets`, secretObject, (res) => {
      setResponse({
        ...response,
        secrets: [res, ...secrets],
        totalCount: response.totalCount + 1,
      });

      reset();
      toast.success("New secret added successfully!");
    });
  };

  return (
    <>
      <Container>
        <BackButton to={`${backTo}/${paramId}`} label="Back">
          <></>
        </BackButton>
        <SectionTitle>
          {mainTitle}({state?.name})
        </SectionTitle>

        <NewSecretForm
          register={register}
          onSubmit={handleSubmit(onAddSecret)}
          errors={errors}
          control={control}
          className="mb-6"
          setValue={setValue}
          loading={addSecretLoading}
        />

        <SecretsTable
          endpoint={endpoint}
          response={response}
          setResponse={setResponse}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={PER_PAGE}
          handlePageChange={handlePageChange}
          loading={loading}
        />
      </Container>
    </>
  );
};
