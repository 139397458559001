import { Controller } from "react-hook-form";

import { CategoriesSelect } from ".";
import {
  FormControl,
  Input,
  InputSize,
  Label,
  Textarea,
  ToggleSwitch,
} from "../../../components";
import { IReactHookForm } from "../../../interfaces";
import { inputClasses, textareaClasses } from "../../../utils";

interface IManageCookieForm extends IReactHookForm {
  domainId: string;
}

export const ManageCookieForm: React.FC<IManageCookieForm> = (props) => {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="sm:w-1/2 sm:pr-3 sm:flex-none flex-1">
          <FormControl>
            <Label required text="Name" />
            <Input
              autoFocus
              {...props.register("name", { required: "Name is required" })}
              error={!!props.errors.name && props.errors.name.message}
              inputSize={InputSize.sm}
              className={inputClasses}
            />
          </FormControl>

          <FormControl>
            <Label text="Hostname" />
            <Input
              {...props.register("cookieDomain")}
              inputSize={InputSize.sm}
              className={inputClasses}
            />
          </FormControl>

          <FormControl>
            <Label text="Path" />
            <Input
              {...props.register("path")}
              inputSize={InputSize.sm}
              className={inputClasses}
            />
          </FormControl>
        </div>

        <div className="sm:w-1/2 sm:pl-3 flex-1">
          <FormControl className="flex">
            <div className="w-full">
              <Label text="Lifetime" />
              <Input
                type="number"
                placeholder="Enter cookie lifetime in minutes ..."
                {...props.register("expiration", {
                  required: "Expiration name is required",
                })}
                error={
                  !!props.errors.expiration && props.errors.expiration.message
                }
                inputSize={InputSize.sm}
                className={inputClasses}
              />

              {/* {!!props.errors.expirationUnit &&
                props.errors.expirationUnit.message && (
                  <Message
                    type={MessageTypes.error}
                    message={props.errors.expirationUnit.message}
                  />
                )} */}
            </div>

            {/* <div className="flex-1 pl-4">
              <Label text="&nbsp;" />
              <Controller
                control={props.control}
                name="expirationUnit"
                rules={{ required: "Expiration unit is required" }}
                render={({ field: { ref, onChange, value } }: any) => (
                  <Select
                    selectRef={ref}
                    placeholder=""
                    className={inputClasses}
                    options={cookieExprity}
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
              />
            </div> */}
          </FormControl>

          <FormControl>
            <CategoriesSelect
              domainId={props.domainId}
              control={props.control}
              errors={props.errors}
            />
          </FormControl>

          <FormControl>
            <Controller
              control={props.control}
              name="is_secure"
              render={({ field: { onChange, value, ref } }: any) => (
                <ToggleSwitch
                  id="secure"
                  label="Secure (Only accessable on https)"
                  onChange={onChange}
                  checked={value || false}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Controller
              control={props.control}
              name="is_httponly"
              render={({ field: { onChange, value, ref } }: any) => (
                <ToggleSwitch
                  id="http"
                  label="HTTP only (not accessable using java script)"
                  onChange={onChange}
                  checked={value || false}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>
        </div>
      </div>

      <FormControl description="What is the cookie used for, who’s the actial data collector and why should the user allow it? ">
        <Label text="Description" htmlFor="description" />
        <Textarea
          id="description"
          {...props.register("description")}
          maxLength={3000}
          className={textareaClasses}
        />
      </FormControl>
    </>
  );
};
