import { FC } from "react";
import { toast } from "react-toastify";
import { Modal, ModalSize } from "../../../../components";
import {
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS,
} from "../../../../constants";
import { useFetch } from "../../../../hooks";
import { IModel } from "../../interface";
import { handleErrors } from "../../utils/handle-errors";

interface IPublishModelModal {
  visible: boolean;
  toggle: () => void;
  props: any;
}

export const PublishModelModal: FC<IPublishModelModal> = ({
  visible,
  toggle,
  props,
}) => {
  const { apiCall: publishModel, loading: publishModelLoading } =
    useFetch("put");

  const onModelPublish = () => {
    publishModel(
      `${USER_PROFILE_API}/integration-models/toggle-publish/?id=${props.id}`,
      {},
      () => {
        const updatedModels: IModel[] = [];

        props.models.forEach((model: IModel) => {
          if (model.id === props.id) model.isPublished = !model.isPublished;
          updatedModels.push(model);
        });
        props.setModels(updatedModels);
        toggle();
        toast.success(`Model ${props.name} changed status successfully!`);
      },
      (err) => {
        handleErrors(err);
      },
      USER_PROFILE_API_HEADERS
    );
  };

  return (
    <Modal
      visible={visible}
      modalSize={ModalSize.md}
      hide={toggle}
      title={props.isPublished ? "Unpublish Model" : "Publish Model"}
      onConfirmClick={onModelPublish}
      confirmBtnText={props.isPublished ? "Unpublish" : "Publish"}
      withFooter
      warningModal
      blockOutsideClick
      loading={publishModelLoading}
      disabled={publishModelLoading}
    >
      <p className="text-sm text-blue-mainText font-medium">
        {!props.isPublished
          ? "You are about to publish this model live. This will become visible to all users under their account. Are you sure you want to publish this model?"
          : "Are you sure you want to Unpublish this model? This action can be undone by re-publishing the model."}
      </p>
    </Modal>
  );
};
