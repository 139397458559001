import React, { InputHTMLAttributes, ReactElement, forwardRef } from "react";
import { cx } from "../../utils";

export interface IRadioButton extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  wrapperClassName?: string;
  inputRef?: any;
  label: string | ReactElement;
}

export const RadioButton: React.FC<IRadioButton> = forwardRef(
  ({ id, wrapperClassName, inputRef, label, ...props }, ref) => {
    return (
      <>
        <div className={cx(["flex items-center", wrapperClassName])}>
          <input
            id={id}
            type="radio"
            name="radio"
            {...props}
            className="hidden"
            ref={inputRef}
          />
          <label
            htmlFor={id}
            className="flex items-center cursor-pointer radio-btn-label text-primary-mainText text-sm"
          >
            <span className="w-4 h-4 inline-block mr-2 rounded-full border relative">
              <span className="w-2 h-2 inner-circle transition rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"></span>
            </span>
            {label}
          </label>
        </div>
      </>
    );
  }
);
