
import {useState} from 'react'

export const useLoadMore = (initial:number, load:number) => {
    const [itemNum, setItemNum] = useState(initial);

    function onLoadMore() {
      setItemNum((prevItemNum) => prevItemNum + load);
    }

    return {
        onLoadMore,
        itemNum
    };
  };