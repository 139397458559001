import { FC } from "react";

interface IFileList {
  width?: string;
  height?: string;
}

export const FileList: FC<IFileList> = ({ width, height }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
      style={{
        width: width || 32,
        height: height || 32,
      }}
    >
      <path d="M9.33329 9.83333C8.59691 9.83333 7.99996 10.4303 7.99996 11.1667V13.8333C7.99996 14.5697 8.59691 15.1667 9.33329 15.1667H12C12.7363 15.1667 13.3333 14.5697 13.3333 13.8333V11.1667C13.3333 10.4303 12.7363 9.83333 12 9.83333H9.33329Z" />
      <path d="M7.99996 19.1667C7.99996 18.4303 8.59691 17.8333 9.33329 17.8333H12C12.7363 17.8333 13.3333 18.4303 13.3333 19.1667V21.8333C13.3333 22.5697 12.7363 23.1667 12 23.1667H9.33329C8.59691 23.1667 7.99996 22.5697 7.99996 21.8333V19.1667Z" />
      <path d="M17.3333 10.1667C16.781 10.1667 16.3333 10.6144 16.3333 11.1667C16.3333 11.719 16.781 12.1667 17.3333 12.1667H24C24.5522 12.1667 25 11.719 25 11.1667C25 10.6144 24.5522 10.1667 24 10.1667L17.3333 10.1667Z" />
      <path d="M16.3333 16.5C16.3333 15.9477 16.781 15.5 17.3333 15.5L24 15.5C24.5522 15.5 25 15.9477 25 16.5C25 17.0523 24.5522 17.5 24 17.5L17.3333 17.5C16.781 17.5 16.3333 17.0523 16.3333 16.5Z" />
      <path d="M17.3333 20.8333C16.781 20.8333 16.3333 21.2811 16.3333 21.8333C16.3333 22.3856 16.781 22.8333 17.3333 22.8333L24 22.8333C24.5522 22.8333 25 22.3856 25 21.8333C25 21.281 24.5522 20.8333 24 20.8333L17.3333 20.8333Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66663 7.16667C2.66663 4.95753 4.45749 3.16667 6.66663 3.16667H25.3333C27.5424 3.16667 29.3333 4.95753 29.3333 7.16667V25.8333C29.3333 28.0425 27.5424 29.8333 25.3333 29.8333H6.66663C4.45749 29.8333 2.66663 28.0425 2.66663 25.8333V7.16667ZM6.66663 5.16667H25.3333C26.4379 5.16667 27.3333 6.0621 27.3333 7.16667V25.8333C27.3333 26.9379 26.4379 27.8333 25.3333 27.8333H6.66663C5.56206 27.8333 4.66663 26.9379 4.66663 25.8333V7.16667C4.66663 6.0621 5.56206 5.16667 6.66663 5.16667Z"
      />
    </svg>
  );
};
