import { Dispatch, useEffect } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  FormControl,
  Label,
  Modal,
  ModalSize,
  Input,
  InputSize,
  Textarea,
} from "../../../../components";
import { IRole, IRoles } from "../../../../interfaces";
import { inputClasses, textareaClasses, userIState } from "../../../../utils";
import { useFetch } from "../../../../hooks";

interface IUserCreateModal {
  visible: boolean;
  toggle: () => void;
  setResponse: Dispatch<IRoles>;
  response: IRoles;
}

export const RoleCreateModal: React.FC<IUserCreateModal> = ({
  visible,
  toggle,
  setResponse,
  response,
}) => {
  const { apiCall: createRole, loading } = useFetch("post");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(userIState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    toggle();
    reset();
  };

  const onCreateRole: SubmitHandler<any> = (data: IRole) => {
    createRole(
      `/Roles`,
      data,
      (res) => {
        setResponse({
          ...response,
          roles: [res, ...response?.roles],
        });
        closeModal();
        toast.success("Role created successfully!");
      },
      (error) => {
        const errRes = error?.response?.data;
        toast.error(errRes);
      }
    );
  };

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      modalSize={ModalSize.md}
      title="Create Role"
      onConfirmClick={handleSubmit(onCreateRole)}
      confirmBtnText="Create"
      loading={loading}
      withFooter
      blockOutsideClick
      confirmBtnType="submit"
    >
      <FormControl>
        <Label required text="Name" htmlFor="name" />
        <Input
          id="name"
          autoFocus
          {...register("name", {
            required: "Name is required",
            validate: {
              notValidName: (value: string) =>
                !!value.trim() || "Name is required",
            },
          })}
          error={!!errors.name && errors.name.message}
          inputSize={InputSize.sm}
          className={inputClasses}
          type="text"
        />
      </FormControl>

      <FormControl lastChild>
        <Label text="Description" htmlFor="description" />
        <Textarea
          id="description"
          {...register("description")}
          className={textareaClasses}
          maxLength={3000}
        />
      </FormControl>
    </Modal>
  );
};
