import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import {
  DropdownOption,
  LimitedTagsDropdown,
  Modal,
  ModalSize,
  ThreeDotsButton,
  Table,
  Td,
  Avatar,
  AvatarSize,
  AvatarRounded,
} from "../../../components";
import { Dropdown, Status } from "../../clients/api-resources/components";
import { USERS_PATH } from "../../../constants";
import { ManageMemberModal } from ".";
import { IInvitedMember, IMember } from "../../../interfaces";
import { toast } from "react-toastify";

interface IMembersListView {
  searchTerm: string;
  makeACall?: string;
}

export const MembersListView: React.FC<IMembersListView> = ({
  searchTerm,
  makeACall,
}) => {
  const [members, setMembers] = useState<any>();
  const [selectedMember, setSelectedMember] = useState<
    IMember & IInvitedMember
  >();
  const { visible, toggle } = useToggle();
  const { visible: deleteMemberModalVisible, toggle: deleteMemberModalToggle } =
    useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();

  const {
    apiCall: getMembers,
    loading: getMembersLoading,
    response: membersResponse,
  } = useFetch("get");

  const { apiCall: deleteMember, loading: deleteMemberLoading } =
    useFetch("post");

  // const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const getAllMembers = () => {
    getMembers(
      `Users/DashboardMembersListView?searchText=${searchTerm}&page=${currentPage}&pageSize=10`
    );
  };

  useEffect(() => {
    debugger;
    getAllMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, makeACall]);

  useEffect(() => {
    setMembers(membersResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersResponse]);

  const onDeleteMember = () => {
    deleteMember(`Users/DeleteMember`, selectedMember, () => {
      setMembers({
        ...members,
        data: members?.data?.filter(
          (item: any) => item.userId !== selectedMember?.userId
        ),
        totalCount: members?.totalCount - 1,
      });
      toast.success("Member successfully deleted!");
      deleteMemberModalToggle();
    });
  };

  const emptySectionText =
    members?.data?.length < 1 && searchTerm !== ""
      ? "No member found."
      : "No members yet.";

  return (
    <>
      <Table
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Name" },
          { children: "Roles" },
          { children: "Applications" },
          { children: "MFA" },
          { children: "Actions", className: "text-right" },
        ]}
        loading={getMembersLoading}
        values={members?.data}
        renderTr={(el: IMember & IInvitedMember) => {
          return (
            <tr className="transition hover:bg-gray-50">
              <Td>
                <NavLink
                  to={`${USERS_PATH}/${el.userId}/user-details`}
                  className="group block"
                >
                  <div className="flex items-center">
                    <Avatar
                      imgUrl=""
                      text={`${el?.firstAndLastName[0]}`}
                      size={AvatarSize.sm}
                      rounded={AvatarRounded["rounded-full"]}
                      className="mr-4"
                      colourful
                      index={el.colorId}
                    />

                    <div>
                      <p className="text-sm group-hover:text-primary transition">
                        {`${el?.firstAndLastName[0]} ${
                          el?.firstAndLastName[1] || ""
                        }`}
                      </p>
                      <p className="text-primary-secText text-xs">
                        {el?.email}
                      </p>
                    </div>
                  </div>
                </NavLink>
              </Td>
              <Td>
                {el?.roles?.map((role: string, index: number) => {
                  return (
                    <p className="mr-2 text-sm inline-block" key={index}>
                      {role === "SuperAdmin" ? "Admin" : role}
                      {index !== el?.roles?.length - 1 && ","}
                    </p>
                  );
                })}
              </Td>
              <Td className="relative">
                <LimitedTagsDropdown tagLimit={3} tags={el?.applications} />
              </Td>
              <Td>
                <Status enabled={el?.mfaEnabled} />
              </Td>
              <Td align="right">
                <Dropdown
                  width="w-36"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        withIcon
                        iconName="Edit"
                        label="Edit"
                        onClick={() => {
                          toggle();
                          setSelectedMember(el);
                        }}
                      />

                      <DropdownOption
                        withIcon
                        iconName="Delete"
                        label="Delete"
                        onClick={() => {
                          deleteMemberModalToggle();
                          setSelectedMember(el);
                        }}
                      />
                    </>
                  }
                >
                  <ThreeDotsButton />
                </Dropdown>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: members?.pageSize,
          totalCount: members?.totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: members?.data?.length,
        }}
        emptyView={{ text: emptySectionText }}
      />

      {deleteMemberModalVisible && (
        <Modal
          hide={deleteMemberModalToggle}
          visible={deleteMemberModalVisible}
          title="Delete Member"
          onConfirmClick={onDeleteMember}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={deleteMemberLoading}
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">
              {selectedMember?.firstAndLastName[0]}{" "}
              {selectedMember?.firstAndLastName[1]}
            </strong>
            ?
          </p>
        </Modal>
      )}

      {visible && (
        <ManageMemberModal
          toggle={toggle}
          visible={visible}
          listView={members}
          setListView={setMembers}
          editMode
          member={selectedMember}
        />
      )}
    </>
  );
};
