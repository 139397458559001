import { FC, useState } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { toast } from "react-toastify";

import {
  FormControl,
  Input,
  InputSize,
  Label,
  Modal,
  Table,
  Td,
} from "../../../../components";
import { useFetch, useToggle } from "../../../../hooks";
import { IClaim } from "../../../../interfaces";
import { ITableProps } from "../interface";

interface IClaimsTable {
  tableProps: ITableProps;
}

export const ClaimsTable: FC<IClaimsTable> = ({
  tableProps: {
    loading,
    totalCount,
    handlePageChange,
    currentPage,
    setResponse,
    response,
  },
}) => {
  const { visible, toggle } = useToggle();
  const [currentClaim, setCurrentClaim] = useState<IClaim>({
    value: "",
    id: 0,
    type: "",
  });

  const { apiCall: deleteClaim, loading: deleteClaimLoading } =
    useFetch("delete");

  const onDeleteClaim = (claim: IClaim) => {
    deleteClaim(`Clients/Claims/${claim.id}`, {}, () => {
      toast.success("Client claim deleted successfully!");
      toggle();

      setResponse({
        ...response,
        totalCount: totalCount - 1,
        clientClaims: response.clientClaims?.filter((item: IClaim) => {
          return item.id !== claim.id;
        }),
      });
    });
  };

  return (
    <>
      <Table
        loading={loading || false}
        names={[{ children: "Type" }, { children: "Value" }, { children: "" }]}
        values={response?.clientClaims}
        renderTr={(el: IClaim) => {
          return (
            <tr className="hover:bg-gray-50 transition">
              <Td>{el.type}</Td>
              <Td>{el.value}</Td>
              <Td className="text-primary-secText" align="right">
                <button
                  type="button"
                  onClick={() => {
                    toggle();
                    setCurrentClaim(el);
                  }}
                  className="transition text-primary-secText hover:text-warning focus:shadow-outlineWarning focus:outline-none rounded"
                >
                  <span className="w-5 h-5 inline-block">
                    <Icon name="Delete" size={22} />
                  </span>
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: 10,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: response?.clientClaims?.length,
        }}
        emptyView={{ text: "There are no claims yet." }}
      />

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          title="Delete Client Claim"
          withFooter
          warningModal
          confirmBtnText="Delete"
          onConfirmClick={() => onDeleteClaim(currentClaim)}
          loading={deleteClaimLoading}
          blockOutsideClick
        >
          <p className="mb-6 text-sm">
            Are you sure u want to delete{" "}
            <strong className="font-medium">{currentClaim?.value}</strong>?
          </p>

          <FormControl>
            <Label text="Type" />
            <Input
              inputSize={InputSize.sm}
              readOnly
              value={currentClaim?.type}
              className="bg-primary-stroke"
            />
          </FormControl>

          <FormControl>
            <Label text="Value" />
            <Input
              inputSize={InputSize.sm}
              readOnly
              value={currentClaim?.value}
              className="bg-primary-stroke"
            />
          </FormControl>
        </Modal>
      )}
    </>
  );
};
