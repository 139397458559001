import { FC, useEffect } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Card,
  CardSpace,
  CardStyle,
  DeleteSection,
  FormControl,
  Label,
  Modal,
  ModalSize,
  Input,
  InputSize,
  Textarea,
  BtnSize,
  BtnStyle,
  Button,
} from "../../../components";
import { useUserContext } from "../../../context/UserContext";
import { useFetch, useToggle } from "../../../hooks";
import { IRole } from "../../../interfaces";
import {
  cx,
  inputClasses,
  primaryBtnClasses,
  textareaClasses,
} from "../../../utils";
import { ROLES_PATH } from "../../../constants";
import { Spinner, SpinnerSize } from "../../../components/ui/Spinner";

export const RoleSettings: FC = () => {
  const { role, setRole } = useUserContext();
  const { toggle, visible } = useToggle();
  const navigate = useNavigate();
  const { apiCall: deleteRole, loading } = useFetch("delete");
  const { apiCall: updateRole, loading: updateRoleLoading } = useFetch("put");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    reset(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const onDeleteRole = () => {
    deleteRole(`/Roles/${role?.id}`, {}, () => {
      toggle();
      navigate(ROLES_PATH);
      toast.success("Role deleted successfully!");
    });
  };

  const onUpdateRole: SubmitHandler<any> = (data: IRole) => {
    updateRole(
      `/Roles`,
      data,
      () => {
        setRole(data);
        toast.success("Role updated successfully!");
      },
      (error) => {
        toast.error(error?.response?.data?.errors["DuplicateRoleName"][0]);
      }
    );
  };

  return (
    <>
      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="my-6"
      >
        <h6 className="text-primary-mainText">{role?.name}</h6>
        <p className="text-sm text-primary-secText">{role?.description}</p>

        <form onSubmit={handleSubmit(onUpdateRole)}>
          <FormControl className="mt-8 pr-4 sm:w-1/3">
            <Label text="Name" required htmlFor="name" />
            <Input
              id="name"
              {...register("name", {
                required: "Name is required",
              })}
              className={inputClasses}
              error={!!errors.name && errors.name.message}
              inputSize={InputSize.sm}
            />
          </FormControl>

          <FormControl className="pr-4 sm:w-1/3 mt-2">
            <Label text="Description" htmlFor="description" />
            <Textarea
              {...register("description")}
              className={textareaClasses}
              maxLength={3000}
            />
          </FormControl>

          <Button
            type="submit"
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx(["flex items-center", primaryBtnClasses])}
            disabled={!isDirty || updateRoleLoading}
          >
            {updateRoleLoading && (
              <Spinner
                className="mr-2"
                size={SpinnerSize.xs}
                color="text-white"
                padding="p-0"
              />
            )}
            Save
          </Button>
        </form>
      </Card>

      <Card cardStyle={CardStyle.bordered} cardSpace={CardSpace.xl}>
        <DeleteSection
          title="Delete role"
          message="This action will affect all group members and cannot be undone."
          onDelete={toggle}
          className="flex-col"
        />
      </Card>

      {visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Delete role"
          onConfirmClick={onDeleteRole}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={loading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">{role?.name}</strong>?
          </p>
        </Modal>
      )}
    </>
  );
};
