import { FC } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { applicationsRoutes } from "../../router/Routes";
import { onlyForRoles } from "../../context";
import { cx } from "../../utils";

export type BreadcrumbsPropsItem = {
  name?: string;
  to?: string;
};

export interface BreadcrumbsProps {
  wrapperClassName?: string;
  showMainName?: boolean;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  wrapperClassName,
  showMainName = true,
}) => {
  return (
    <Routes>
      {applicationsRoutes.map((link) => {
        if (!!link?.permissions && onlyForRoles(link?.permissions)) {
          return (
            <Route
              key={`${link.path}`}
              path={link.path || ""}
              element={
                <>
                  {showMainName && (
                    <p className="leading-6 select-none text-white">
                      {link.name}
                    </p>
                  )}
                  <ul className={cx(["flex select-none", wrapperClassName])}>
                    {link.crumbs?.map((el, key) => {
                      return (
                        <li
                          key={key}
                          className="flex items-center text-xs font-medium leading-5 text-white"
                        >
                          {el.to ? (
                            <>
                              <NavLink
                                className="hover:text-blue-200"
                                to={el.to}
                              >
                                {el.name}
                              </NavLink>
                              {link.crumbs!.length - 1 !== key && (
                                <span className="mx-2 w-3.5 h-3.5 inline-block">
                                  <Icon name="ArrowRight" size={14} />
                                </span>
                              )}
                            </>
                          ) : (
                            <span
                              className={cx([
                                "flex items-center pr-3",
                                link.crumbs!.length - 1 === key &&
                                  // When active
                                  "text-white",
                              ])}
                            >
                              {el.name}
                              {link.crumbs!.length - 1 !== key && (
                                <span className="px-2 w-3.5 h-3.5 inline-block">
                                  <Icon name="ArrowRight" size={14} />
                                </span>
                              )}
                            </span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </>
              }
            />
          );
        }

        return null;
      })}
    </Routes>
  );
};
