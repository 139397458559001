import { FC, ReactNode } from "react";
import { cx } from "../../utils";

interface IModalFooter {
  className?: string;
  children: ReactNode;
}

export const ModalFooter: FC<IModalFooter> = (props) => {
  return (
    <div className={cx(["px-12 pt-2 pb-12 flex justify-end", props.className])}>
      {props.children}
    </div>
  );
};
