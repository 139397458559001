import { FC, useState } from "react";

interface IShowMoreText {
  text: string;
}

export const ShowMoreText: FC<IShowMoreText> = ({ text }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <>
      {showMore ? text : `${text.substring(0, 20)}...`}
      <button
        type="button"
        onClick={() => setShowMore(!showMore)}
        className="text-primary underline"
      >
        {showMore ? "less" : "more"}
      </button>
    </>
  );
};
