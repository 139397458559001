const SAVED_URI = 'PREV_PATH_ADMIN';
const FORBIDDEN_URIS = ['', '/'];
const DEFAULT_URI = '/';

export const setPostLoginUri = () => {
  const currentPath = window.location.pathname;
  const savedURI = sessionStorage.getItem(SAVED_URI);

  if (FORBIDDEN_URIS.includes(currentPath) || savedURI) {
    return;
  }

  sessionStorage.setItem(SAVED_URI, currentPath);
}

export const getPostLoginUri = (retain: boolean) => {
  const savedURI = sessionStorage.getItem(SAVED_URI);

  if (!retain) {
    sessionStorage.removeItem(SAVED_URI);
  }

  return savedURI || DEFAULT_URI;
}
