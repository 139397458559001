import { FC } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
// import { useTemplateContext } from "../../../../context";

export const Banner: FC<any> = ({ theme, width, setShow }) => {
  //   const { switchBannerType, setSwitchBannerType, theme } = useTemplateContext();
  const privacyPolicy = "Privacy Policy";
  const cookiePolicy = "Cookie Policy";
  const allowAllButton = "Allow All";
  const settingsButton = "Settings";
  const bannerMessage =
    "We and our partners use cookies, tags and other tracking technologies to customise advertisements, analyse how the site is used, enhance the site’s personalisation and perform some other necessary functions.";

  return (
    <div
      className={` absolute bottom-3 w-full layout-item flex ${
        width > 768 && "p-4"
      }`}>
      <div
        className="cc-compliance bg-white relative  rounded-md shadow-configCard lg:p-6"
        style={{ width: width }}>
        <div className="control-section p-2 absolute top-0 right-0  flex ">
          <span
            aria-label="dismiss cookie message"
            className={`cursor-pointer ${
              theme.showLanguagesIcon ? "" : "hidden"
            }`}
            // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
            role="button"
            tab-index="0">
            <Icon name="Globe" size={20} />
          </span>
          <span
            className={`cursor-pointer ${
              theme.showCloseButton ? "" : "hidden"
            }`}
            // className={`cursor-pointer ${!showCloseButton && "hidden"}`}
            aria-label="dismiss cookie message"
            role="button"
            tab-index="0">
            <Icon name="Close" size={20} />
          </span>
        </div>
        <div
          id="cmp"
          className={`cmp-container  transform  banner-wrapper flex  justify-between	max-w-7xl mx-auto ${
            width < 768 ? "flex-col" : "flex-row"
          }`}>
          <div
            className={` cmp-banner-description  mb-9 md:mb-0 leading-4 ${
              width < 768 ? "w-full" : "w-2/3"
            }`}>
            <p className="text-base font-bold text-primary-mainText mb-3">
              {theme.bannerName}
            </p>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0 md:mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!theme.showDNSMD ? "" : "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div
            className={`banner-wrapper-controls  flex items-end justify-center  text-sm ${
              width < 768 ? "w-full flex-col" : "w-1/3 justify-end"
            }`}>
            <button
              className={`border border-blue bg-blue text-white leading-4 rounded-sm px-3  py-2 ${
                width < 768 ? "w-full my-2" : "mr-2 "
              }`}
              id="accept-all-cookies-at-once">
              {allowAllButton}
            </button>
            <button
              className={`border border-blue bg-white text-blue-400 leading-4 rounded-sm px-3 py-2 ${
                width < 768 && "w-full"
              }`}
              id="accept-cookies"
              onClick={() => setShow("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
