import { DefaultTag } from ".";

interface IDefaultLangHeader {
  defaultLang: {
    name: string;
    code: string;
  };
}

export const DefaultLangHeader: React.FC<IDefaultLangHeader> = ({
  defaultLang,
}) => {
  const { name, code } = defaultLang;
  return (
    <div className="border-b border-primary-stroke flex justify-between pb-4 mb-6 relative">
      <div className="flex items-center">
        <img
          src={`https://flagcdn.com/48x36/${code.toLocaleLowerCase()}.png`}
          width={18}
          alt=""
          className="mr-2"
        />
        <span className="text-sm text-primary-secText">{name}</span>
      </div>

      <DefaultTag className="absolute right-0 top-0" />
    </div>
  );
};
