import { FC } from "react";

import { cx } from "../../utils";

interface IArrowRotateLeft {
  className?: string;
}

export const ArrowRotateLeft: FC<IArrowRotateLeft> = ({ className }) => {
  return (
    <span className={cx([className])}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-current"
      >
        <path d="M15.1875 9C15.1875 6.01215 12.6898 3.5625 9.5743 3.5625C7.22104 3.5625 5.21516 4.96335 4.37886 6.9375L6 6.9375C6.31066 6.9375 6.5625 7.18934 6.5625 7.5C6.5625 7.81066 6.31066 8.0625 6 8.0625H3.375C2.85723 8.0625 2.4375 7.64277 2.4375 7.125V4.5C2.4375 4.18934 2.68934 3.9375 3 3.9375C3.31066 3.9375 3.5625 4.18934 3.5625 4.5V6.03306C4.67699 3.89462 6.95512 2.4375 9.5743 2.4375C13.2803 2.4375 16.3125 5.36044 16.3125 9C16.3125 12.6396 13.2803 15.5625 9.5743 15.5625C6.64971 15.5625 4.15034 13.7457 3.2214 11.1923C3.11519 10.9004 3.26575 10.5776 3.5577 10.4714C3.84964 10.3652 4.1724 10.5158 4.27861 10.8077C5.04525 12.915 7.12101 14.4375 9.5743 14.4375C12.6898 14.4375 15.1875 11.9879 15.1875 9Z" />
      </svg>
    </span>
  );
};
