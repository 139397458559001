import { ReactNode, FC, MouseEvent } from "react";

import { createPortal } from "react-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { BtnSize, BtnStyle, BtnType, Button } from "../../components/button";

import { ModalFooter, ModalBody } from "../../components/modal";
import {
  cancelBtnClasses,
  cx,
  primaryBtnClasses,
  warningBtnClasses,
} from "../../utils";

export enum ModalSize {
  sm = "max-w-md",
  md = "max-w-xl",
  lg = "max-w-3xl",
  xl = "max-w-6xl",
  unset = "max-w-full",
}

export interface IModalProps {
  visible: boolean;
  children: ReactNode;
  noCloseButton?: boolean;
  modalSize?: ModalSize;
  title?: string;
  headerClassName?: string;
  withFooter?: boolean;
  noHeader?: boolean;
  onCancel?: () => void;
  hide?: () => void;
  onConfirmClick?: () => void;
  onFormSubmit?: any;
  onCloseClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  confirmBtnText?: string;
  warningModal?: boolean;
  blockOutsideClick?: boolean;
  confirmBtnType?: "submit" | "button";
  fullHeight?: boolean;
}

const doNothing = () => {
  return;
};

export const Modal: FC<IModalProps> = (props): JSX.Element | null =>
  props.visible
    ? createPortal(
        <>
          <div
            onClick={!props.blockOutsideClick ? props.hide : doNothing}
            className={cx([
              "fixed top-0 left-0",
              "w-full h-full overflow-auto bg-primary-secText bg-opacity-40",
              "animate-fade-in z-30",
              !props.fullHeight && "py-5",
            ])}
          >
            <div className="flex items-center justify-center min-h-full">
              <div
                className={cx([
                  "w-full relative bg-white overflow-y-auto",
                  props.modalSize || ModalSize.md,
                  !ModalSize.unset && "m-5",
                  !props.fullHeight && "rounded shadow-lg",
                ])}
                onClick={(event: MouseEvent<HTMLDivElement>): void =>
                  event.stopPropagation()
                }
              >
                {!props.noCloseButton && (
                  <button
                    onClick={props.onCloseClick || props.hide}
                    className={cx([
                      "p-1 self-start rounded transition bg-gray-50",
                      "focus:outline-none text-primary-mainText hover:bg-gray-50 hover:text-primary focus:shadow-outlinePrimary active:shadow-outlinePrimary",
                      "absolute top-10 right-10 w-8 h-8",
                    ])}
                    title="Close Modal"
                    type="button"
                  >
                    <span className="inline-block w-6 h-6">
                      <Icon name="Close" size={24} />
                    </span>
                  </button>
                )}

                <div>
                  {!props.noHeader && (
                    <div
                      className={cx([
                        "pl-12 pt-12 pr-24",
                        props.headerClassName,
                      ])}
                    >
                      <h5 className="text-primary-mainText font-medium">
                        {props.title}
                      </h5>
                    </div>
                  )}

                  {!props.withFooter && props.children}

                  {props.withFooter && (
                    <form onSubmit={props.onFormSubmit}>
                      <ModalBody>{props.children}</ModalBody>

                      <ModalFooter>
                        <Button
                          btnType={BtnType.secondary}
                          btnStyle={BtnStyle.neutral}
                          btnSize={BtnSize.normal}
                          className={cancelBtnClasses}
                          type="button"
                          onClick={props.onCancel || props.hide}
                          disabled={props.loading}
                        >
                          Cancel
                        </Button>

                        <Button
                          type={props.confirmBtnType || "button"}
                          onClick={props.onConfirmClick}
                          btnStyle={
                            props.warningModal
                              ? BtnStyle.warning
                              : BtnStyle.primary
                          }
                          btnSize={BtnSize.normal}
                          className={cx([
                            "ml-4 flex items-center",
                            props.warningModal
                              ? warningBtnClasses
                              : primaryBtnClasses,
                          ])}
                          disabled={props.disabled || props.loading}
                        >
                          {props.loading && (
                            <img
                              src="/images/oval.svg"
                              alt="Spinner"
                              width={16}
                              className="mr-2"
                            />
                          )}

                          {props.confirmBtnText}
                        </Button>
                      </ModalFooter>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;
