import { FC, useState } from "react";
import { useTemplateContext } from "../../../../../context";
import { Widget } from "./Widget";

export const WidgetsLayouts: FC = () => {
  const { theme } = useTemplateContext();
  const [selected, setSelected] = useState<string>(theme?.widgetPosition);

  const widgetPlacements = [
    // { title: "Bottom Right", position: "bottom-right" },
    { title: "Bottom Left", position: "bottom-left" },
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {widgetPlacements?.map(({ title, position }, index) => (
        <Widget
          key={index}
          title={title}
          bannerId={index}
          position={position}
          selected={position === selected}
          onClick={() => setSelected(position)}
        />
      ))}
    </div>
  );
};
