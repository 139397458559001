import { FC } from "react";

import { Button, BtnSize, BtnType, BtnStyle } from "../../components";
import { Paragraph } from "..";
import { cx, primaryBtnClasses, warningBtnClasses } from "../../utils";
import { Spinner, SpinnerSize } from "./Spinner";

interface IButtonSection {
  className?: string;
  title: string;
  description?: string;
  btnText: string;
  primary?: boolean;
  onClick?: () => void;
  lastUpdated?: string;
  disabled?: boolean;
  loading?: boolean;
  inlineButtonSection?: boolean;
}

export const ButtonSection: FC<IButtonSection> = ({
  className,
  title,
  description,
  btnText,
  primary,
  onClick,
  lastUpdated,
  disabled,
  loading,
  inlineButtonSection,
}) => {
  return (
    <div className={cx([className])}>
      {inlineButtonSection ? (
        <div>
          <p className="text-sm text-red-900 font-medium">{title}</p>

          <Paragraph className="mt-1 text-warning-hover" noMargin>
            {description}
          </Paragraph>
        </div>
      ) : (
        <>
          <p className="text-sm text-primary-mainText font-medium">{title}</p>

          <Paragraph className="mb-4 mt-1" noMargin>
            {description}
          </Paragraph>
        </>
      )}

      {primary ? (
        <>
          <Button
            type="button"
            btnSize={BtnSize.normal}
            btnType={BtnType.primary}
            className={cx(["flex items-center", primaryBtnClasses])}
            onClick={onClick}
            disabled={disabled}
          >
            {loading && (
              <Spinner
                size={SpinnerSize.xs}
                color="text-white"
                padding="p-0 mr-2"
              />
            )}

            {btnText}
          </Button>
          {!!lastUpdated && <Paragraph>Last sent: {lastUpdated}</Paragraph>}
        </>
      ) : (
        <Button
          type="button"
          btnSize={BtnSize.normal}
          btnStyle={BtnStyle.warning}
          className={warningBtnClasses}
          onClick={onClick}
          disabled={disabled}
        >
          {btnText}
        </Button>
      )}
    </div>
  );
};
