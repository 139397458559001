import { FC, forwardRef, InputHTMLAttributes } from "react";
import { cx } from "../../utils";
import { Paragraph } from "../ui/Paragraph";

interface IToggleSwitch extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  description?: string;
  wrapperClassName?: string;
  inputHolderClass?: string;
  inputRef?: any;
  leftLabel?: boolean;
}

export const ToggleSwitch: FC<IToggleSwitch> = forwardRef(
  (
    {
      id,
      label,
      inputRef,
      wrapperClassName,
      description,
      inputHolderClass,
      leftLabel,
      ...props
    },
    ref
  ) => {
    return (
      <div className={wrapperClassName}>
        <label
          htmlFor={id}
          className={cx([
            "inline-flex cursor-pointer",
            !Boolean(description) && "items-center",
          ])}
        >
          {!!Boolean(label) && leftLabel && (
            <p className="text-primary-secText text-sm block pb-0 font-medium mr-3 hover:text-primary-mainText transition">
              {label}
            </p>
          )}
          <div className={cx(["group relative", inputHolderClass])}>
            <input
              type="checkbox"
              id={id}
              className="sr-only"
              {...props}
              ref={inputRef}
            />
            <div
              className={cx([
                "switch-holder",
                "block border border-primary-stroke hover:border-gray-400 w-9 h-6 rounded-full transition",
              ])}
            ></div>
            <div
              className={cx([
                "dot absolute left-1 top-1",
                "w-4 h-4 rounded-full transition bg-primary-tertText",
              ])}
            ></div>
          </div>
          {!!Boolean(label) && !leftLabel && (
            <p className="text-primary-secText text-sm block pb-0 font-medium ml-3 hover:text-primary-mainText transition">
              {label}
            </p>
          )}
        </label>

        {!!Boolean(description) && !leftLabel && (
          <Paragraph className="pl-12" noMargin>
            {description}
          </Paragraph>
        )}
      </div>
    );
  }
);
