import { ReactNode } from "react";
import { cx } from "../../utils";

export enum CardStyle {
  bordered = "border border-primary-stroke",
  shadowd = "shadowed-content",
  classic = "shadow-sm",
}

export enum CardSpace {
  none = "p-0",
  sm = "p-2",
  md = "p-3",
  x = "p-4",
  xl = "sm:p-6 p-4",
}

interface ICard {
  children: ReactNode;
  className?: string;
  cardStyle?: CardStyle;
  cardSpace?: CardSpace;
  tabIndex?: number;
  id?: string;
  onClick?: () => void;
}

export const Card: React.FC<ICard> = (props) => {
  return (
    <div
      tabIndex={props.tabIndex}
      className={cx([
        "rounded bg-white",
        props.className,
        props.cardSpace,
        props.cardStyle,
      ])}
      id={props.id}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
