import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  BackButton,
  Card,
  CardSpace,
  CardStyle,
  Container,
  FormWrapper,
  Loader,
  Paragraph,
  ToggleSwitch,
} from "../../components";
import { ATTACK_PROTECTION_PATH } from "../../constants";
import { useFetch } from "../../hooks";
import { SEO } from "../../utils";
import { HeadlineStatus } from "./components";

export const BreachPassDetection: FC = () => {
  const { handleSubmit, control, reset, watch } = useForm();
  const navigate = useNavigate();

  const {
    apiCall: getBreachPasswordSettings,
    loading,
    response,
  } = useFetch("get");
  const { apiCall: editBreachPassSettings } = useFetch("post");

  useEffect(() => {
    SEO({
      title: "Porta - Breached Password Detection",
    });

    getBreachPasswordSettings(`Users/BreachedPasswordProtectionSettings`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const brachedPassObj = {
      ...response,
      showUserPwnValidation: !response?.showUserPwnValidation,
    };
    reset(brachedPassObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const saveBreachPassSettings = (data: any) => {
    const breachPassObj = {
      ...data,
      showUserPwnValidation: !data?.showUserPwnValidation,
    };

    editBreachPassSettings(
      `Users/EditBreachedPasswordProtectionSettings`,
      breachPassObj,
      () => {
        toast.success("Breached password settings saved successfully!");
        navigate(ATTACK_PROTECTION_PATH);
      }
    );
  };

  const watchBreachPasswordDetection = watch("enablePwnValidation");

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <BackButton
              to={ATTACK_PROTECTION_PATH}
              label="Back to Attack Protection"
              className="mb-4"
            >
              <></>
            </BackButton>
            <HeadlineStatus
              control={control}
              title="Breached Password Detection"
              description="Porta can detect if a specific user’s credentials were included in a major public security breach."
              toggleId="enable-pwn-validation"
              toggleName="enablePwnValidation"
              statusEnabled={watchBreachPasswordDetection}
            />
          </Container>

          <FormWrapper
            onSubmit={handleSubmit(saveBreachPassSettings)}
            className="mt-6"
          >
            <h6 className="text-primary-mainText mb-6">Response</h6>

            <Card
              cardSpace={CardSpace.xl}
              cardStyle={CardStyle.bordered}
              className="flex justify-between"
            >
              <div>
                <h6 className="text-sm text-primary-mainText font-medium mb-1">
                  Block sign up with compromised credentials
                </h6>
                <Paragraph noMargin>
                  Enforce breached password security by blocking users from
                  signing up with compromised credentials
                </Paragraph>
              </div>

              <Controller
                control={control}
                name="showUserPwnValidation"
                render={({ field: { onChange, value, ref } }: any) => (
                  <ToggleSwitch
                    id="show-user-pwn-validation"
                    onChange={onChange}
                    checked={value || false}
                    inputRef={ref}
                  />
                )}
              />
            </Card>
          </FormWrapper>
        </>
      )}
    </div>
  );
};
