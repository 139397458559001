import { useState, useEffect } from "react";

import Select, { components } from "react-select";
import { Modal } from "../../../../components";
import { useDebounce, useFetch } from "../../../../hooks";
import { reactSelectStyle } from "../../../../utils";

interface IAssignRoleModal {
  visible: boolean;
  toggle: () => void;
  passSelectedRoles: any;
  loading?: boolean;
}

interface IRole {
  id: string;
  name: string;
}

export const AssignRoleModal: React.FC<IAssignRoleModal> = ({
  visible,
  toggle,
  passSelectedRoles,
  loading,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [roles, setRoles] = useState<IRole[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IRole[]>([]);

  const [noOptionText, setNoOptionText] = useState<string>("Type to search...");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { apiCall: getRoles } = useFetch("get");

  const handleSelectValue = (e: any) => {
    setSelectedRoles(e);
    setSearchTerm("");
  };

  const handleInputChange = (e: any) => {
    setSearchTerm(e);
  };

  useEffect(() => {
    if (searchTerm !== "") {
      // getRoles();
      getRoles(`/Users/GetRoles`, {}, (response) => {
        setRoles(response);
      });
      if (roles?.length < -1) setNoOptionText("Role not found");
    } else {
      setRoles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const passRoles = () => {
    if (selectedRoles?.length > 0) {
      passSelectedRoles(selectedRoles);
      // setDisabled(false);
    }
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">{noOptionText}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Modal
      visible={visible}
      hide={toggle}
      disabled={loading}
      confirmBtnText="Assign"
      title="Assign Roles"
      onConfirmClick={passRoles}
      withFooter
      loading={loading}
      blockOutsideClick
    >
      <>
        <Select
          components={{ NoOptionsMessage }}
          isMulti
          options={roles}
          getOptionLabel={(x) => x.name}
          getOptionValue={(x) => x.id}
          isSearchable
          menuPortalTarget={document.body}
          onChange={handleSelectValue}
          onInputChange={handleInputChange}
          classNamePrefix="porta-react-select"
          placeholder="Type to search..."
          className="text-sm"
          styles={reactSelectStyle}
        />
      </>
    </Modal>
  );
};
