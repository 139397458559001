import { FC, useEffect, useState } from "react";
import { AppDataTable } from "../../../components";
import { IExtraProp } from "../../../components/applications/interface";
import { API_SCOPES_PATH } from "../../../constants";
import { SEO } from "../../../utils";

export const ApiScopes: FC = () => {
  const [extraProps, setExtraProps] = useState<IExtraProp>({
    searchTerm: "",
    itemsCount: 0,
  });

  const { searchTerm, itemsCount } = extraProps;

  useEffect(() => {
    SEO({
      title: "Porta - API Scopes",
    });
  }, []);

  const props = {
    endpoint: "/ApiScopes",
    itemsKey: "scopes",
    addButtonLabel: "New API Scope",
    deleteSuccessMessage: "API Scope deleted successfully!",
    emptyViewMessage: `${
      searchTerm !== "" && itemsCount < 1
        ? "No API scope found."
        : "There are no API scopes yet."
    }`,
    path: API_SCOPES_PATH,
    searchKey: "search",
    pageTitle: "API Scopes",
    pageDescription:
      "Set-up an API Scope to use as a mechanism for limiting an application’s access to a user’s account.",
    deleteModalTitle: "Delete API Scope",
  };

  return <AppDataTable {...props} setExtraProps={setExtraProps} />;
};
