import { FC } from "react";
import { toast } from "react-toastify";
import { Modal, ModalSize } from "../../../../components";
import {
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS,
} from "../../../../constants";
import { useFetch } from "../../../../hooks";
import { IModel } from "../../interface";
import { handleErrors } from "../../utils/handle-errors";

interface IDeleteModelModal {
  visible: boolean;
  toggle: () => void;
  props: any;
}

export const DeleteModelModal: FC<IDeleteModelModal> = ({
  visible,
  toggle,
  props,
}) => {
  const { apiCall: deleteModel, loading: deleteModelLoading } =
    useFetch("delete");

  const onModelDelete = () => {
    deleteModel(
      `${USER_PROFILE_API}/integration-models?id=${props.id}`,
      USER_PROFILE_API_HEADERS,
      () => {
        const filteredModels = props.models.filter(
          (model: IModel) => model.id !== props.id
        );
        props.setModels(filteredModels);
        toggle();
        toast.success(`Model ${props.name} deleted successfully!`);
      },
      (err) => {
        handleErrors(err);
      }
    );
  };

  return (
    <Modal
      visible={visible}
      modalSize={ModalSize.md}
      hide={toggle}
      title="Delete Model"
      onConfirmClick={onModelDelete}
      confirmBtnText="Delete"
      withFooter
      warningModal
      blockOutsideClick
      loading={deleteModelLoading}
      disabled={deleteModelLoading}
    >
      <p className="text-sm text-blue-mainText font-medium">
        Are you sure you want to delete this model? All configurations and
        changes you have made will be lost.
      </p>
      <p className="text-sm text-blue-mainText font-normal mt-1">
        This action cannot be undone.
      </p>
    </Modal>
  );
};
