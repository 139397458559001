import { cx } from "../../../utils";

export enum CookieStatus {
  "success" = "bg-confirm",
  "warning" = "bg-warning",
}

interface ICookieTag {
  className?: string;
  text: string;
  cookieStatus?: CookieStatus;
}

export const CookieTag: React.FC<ICookieTag> = ({
  className,
  text,
  cookieStatus = CookieStatus.success,
}) => {
  return (
    <div
      className={cx([
        "px-2 py-1 mr-1 text-white rounded-full text-sm",
        className,
        cookieStatus,
      ])}
    >
      {text}
    </div>
  );
};
