import { FC, useEffect } from "react";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { MainContainer } from "../../components/layout/MainContainer";
import { useFetch } from "../../hooks";
import { RadioButtonSection, EmailVerificationOption } from "./components";
import { HeadlineStatus } from "../security/components";
import { cx } from "../../utils";
import { Loader } from "../../components";

export const PasswordLess: FC = () => {
  const { control, handleSubmit, reset, watch, register } = useForm();

  const { apiCall: enablePasswordLessLogin } = useFetch("post");
  const { apiCall: getPasswordLessStatus, loading } = useFetch("get");

  useEffect(() => {
    getPasswordLessStatus("Security/PasswordlessLogin", {}, (data) => {
      reset({
        ...data,
        passwordlessMagicLinkEnabled:
          data.passwordlessMagicLinkEnabled === true ? "true" : "false",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const passLessLogin = watch("passwordlessLoginEnabled");
  const magicLinkEnabled = watch("passwordlessMagicLinkEnabled");
  const magicLinkEnabledValue = magicLinkEnabled === "true" ? true : false;

  const onEnablePasswordLessLogin = (data: any) => {
    const dataObje = {
      ...data,
      passwordlessMagicLinkEnabled:
        data?.passwordlessMagicLinkEnabled === "true" ? true : false,
    };

    enablePasswordLessLogin(`Security/PasswordlessLogin`, dataObje, (res) => {
      toast.success("Passwordless Login data saved successfully!");
    });
  };

  return (
    <MainContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeadlineStatus
            control={control}
            title="Passwordless Login"
            description="Configure passwordless login options like Email login and others so that you can let your users login without having to choose yet another password."
            statusEnabled={passLessLogin}
            toggleId="passwordless-login"
            toggleName="passwordlessLoginEnabled"
            onToggleSwitchChange={() =>
              handleSubmit(onEnablePasswordLessLogin)()
            }
          />

          <h6 className="text-primary-mainText font-normal mt-8 mb-4">
            Email verification type
          </h6>

          <div
            className={cx([
              "flex gap-6",
              !passLessLogin && "opacity-60 pointer-events-none",
            ])}
          >
            <RadioButtonSection
              checked={!magicLinkEnabledValue}
              register={register("passwordlessMagicLinkEnabled", {
                onChange: () => handleSubmit(onEnablePasswordLessLogin)(),
              })}
              value="false"
              id="email-verification-code"
              disabled={!passLessLogin}
              content={
                <EmailVerificationOption
                  name="Code"
                  description="Send users a one-time-use code which users will enter instead of their password"
                />
              }
            />

            <RadioButtonSection
              checked={magicLinkEnabledValue}
              register={register("passwordlessMagicLinkEnabled", {
                onChange: (e) => handleSubmit(onEnablePasswordLessLogin)(),
              })}
              value="true"
              id="email-verification-link"
              disabled={!passLessLogin}
              content={
                <EmailVerificationOption
                  name="Magic link"
                  description="Send users a one-time magic link which logs users in directly when clicking on it"
                />
              }
            />
          </div>
        </>
      )}
    </MainContainer>
  );
};
