import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Dropdown } from "../../clients/api-resources/components";
import {
  DropdownOption,
  Modal,
  ModalSize,
  SectionTitle,
  ThreeDotsButton,
  Table,
  Td,
} from "../../../components";
import { ICookie } from "../../../interfaces";
import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import { CookieTag, EditCookieModal } from ".";
import { CookieStatus } from "./CookieTag";
import { CMP_API_URL } from "../../../constants";
import { IDomainCategory } from "../interface";

interface ICookiesTable {
  sectionName: string;
  className?: string;
  categories: IDomainCategory[];
  setCategories: Dispatch<SetStateAction<IDomainCategory[] | undefined>>;
  cookies: {
    pageSize: number;
    totalCount: number;
    data: ICookie[];
  };
  domainId: string;
  setCategoryId: Dispatch<SetStateAction<string>>;
  setPage: Dispatch<SetStateAction<number>>;
}

export const CookiesTable: FC<ICookiesTable> = (props) => {
  const { toggle, visible } = useToggle();
  const { toggle: editCookieToggle, visible: editCookieVisible } = useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();


  const [cookies, setCookies] = useState<any>();
  const [cookie, setCookie] = useState<any>();

  const { apiCall: deleteCookie, loading } = useFetch("delete");

  useEffect(() => {
    setCookies(props?.cookies);
    // console.log("🚀 ~", cookies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.cookies]);

  useEffect(() => {
    props.setPage(currentPage);
    props.setCategoryId(cookies?.data[0]?.encryptedCategoryId || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const deleteCookieCategory = (
    categories: IDomainCategory[],
    categoryName: string,
    cookieId: number
  ) => {
    return categories?.map((category) => {
      if (category.categoryName === categoryName) {
        return {
          ...category,
          cookies: {
            ...category.cookies,
            data: category.cookies.data.filter(
              (cookie) => cookie.id !== cookieId
            ),
          },
        };
      } else {
        return {
          ...category,
        };
      }
    });
  };

  const onDeleteCookie = () => {
    deleteCookie(
      `${CMP_API_URL}/DeleteCookie?id=${cookie?.encryptedId}&domainId=${props.domainId}`,
      {},
      () => {
        toggle();
        const filteredCookies = deleteCookieCategory(
          props.categories,
          cookie?.category?.name,
          cookie.id
        );
        props.setCategories(filteredCookies);
        toast.success("Cookie deleted successfully!");
      }
    );
  };

  return (
    <div
      className={props.className || ""}
      // id={props.sectionName?.toLocaleLowerCase()?.replace(/ /g, "-")}
    >
      <SectionTitle noMargin>{props.sectionName}</SectionTitle>
      <Table
        wrapperClassName="mt-4"
        names={[
          { children: "Name" },
          { children: "Hostname" },
          { children: "Lifetime" },
          { children: "Tags" },
          { children: "", className: "border-none" },
        ]}
        values={cookies?.data}
        renderTr={(el: ICookie) => {
          // console.log(el);
          return (
            <tr className="hover:bg-gray-50 transition">
              <Td>{el?.name}</Td>
              <Td className="relative">{el.cookieDomain}</Td>
              <Td>
                {el?.cookieLifeTime ? el?.cookieLifeTime : <em>no expiration date</em>}
              </Td>
              <Td>
                <div className="inline-flex flex-wrap">
                  {el?.is_secure && <CookieTag text="Secure" />}

                  {el?.is_httponly && <CookieTag text="HTTP only" />}

                  {el?.is_third_party && (
                    <CookieTag
                      text="3rd party"
                      cookieStatus={CookieStatus.warning}
                    />
                  )}
                </div>
              </Td>

              <Td className="text-primary-secText" align="right">
                <Dropdown
                  width="w-36"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        withIcon
                        iconName="Edit"
                        label="Edit"
                        onClick={() => {
                          editCookieToggle();
                          setCookie(el);
                        }}
                      />

                      <DropdownOption
                        withIcon
                        iconName="Delete"
                        label="Delete"
                        onClick={() => {
                          toggle();
                          setCookie(el);
                        }}
                      />
                    </>
                  }>
                  <ThreeDotsButton />
                </Dropdown>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: cookies?.pageSize,
          totalCount: cookies?.totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: cookies?.data?.length,
        }}
        emptyView={{ text: "There are no cookies yet." }}
      />

      {!!visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Delete domain"
          onConfirmClick={onDeleteCookie}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={loading}
          blockOutsideClick>
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">{cookie?.name}</strong>?
          </p>
        </Modal>
      )}

      {editCookieVisible && (
        <EditCookieModal
          categories={props.categories}
          setCategories={props.setCategories}
          domainId={props.domainId}
          cookie={cookie}
          visible={editCookieVisible}
          toggle={editCookieToggle}
        />
      )}
    </div>
  );
};
