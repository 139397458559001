export const CMP_API_URL = 'https://cmp.gjirafa.dev';
export const CMP_CRAWLER_URL = 'https://cmp-hangfire.gjirafa.dev/';
export const USER_PROFILE_URL = process.env.REACT_APP_USER_PROFILE;
export const USER_PROFILE_MAIN_PATH = process.env.REACT_APP_USER_PROFILE_MAIN_PATH; 
export const USER_PROFILE_API = process.env.REACT_APP_USER_PROFILE_API; 
export const TEMPLATES_API = `${process.env.REACT_APP_TEMPLATES_API}/${process.env.REACT_APP_TEMPLATES_API_VERSION}/templates`
export const USER_PROFILE_MODELS_API = process.env.REACT_APP_USER_PROFILE_MODELS_API; 
export const ORGANIZATION = process.env.REACT_APP_ORGANIZATION;
export const USER_PROFILE_API_HEADERS = {
    headers: {
        "x-organization-id": ORGANIZATION,
      }
};

export const APPS_PATH = "/applications"
export const API_RES_PATH = "/api-resources"
export const API_SCOPES_PATH = "/api-scopes"
export const I_RES_PATH = "/indentity-resources"

export const USERS_PATH = "/users"
export const ROLES_PATH = "/roles"


export const DOMAINS_PATH = "/domains"
export const UI_BUILDER_PATH = "/ui-builder"
export const CONSENT_LOG = "/consent-log"
export const CONSENT_SCAN = "/cookie-scan"

export const BRANDINGS_PATH = "/brandings"
export const TEMPLATES_PATH = "/templates"
export const GLOBAL_CSS_PATH = "/global-css"

export const ATTACK_PROTECTION_PATH = "/attack-protection"
export const MULTI_FACTOR_AUTH_PATH = "/multi-factor-auth"
export const BOT_DETECTION_PATH = "/bot-detection"
export const IP_THROTTLING_PATH = "/suspicious-ip-throttling"
export const BRUTE_FORCE_PATH = "/brute-force-protection"
export const BREACHED_PASS_PATH = "/breached-password-detection"

export const I_RESOLUTION_PATH = "/identity-resolution"

export const M_AUDIT_LOGS = "/audit-logs"
export const M_ERROR_LOGS = "/error-logs"

export const SETTINGS_PATH = "/settings"
export const SETTINGS_MEMBER_PATH = `${SETTINGS_PATH}/members`
export const SETTINGS_GENERAL_PATH = `${SETTINGS_PATH}/general-settings`
export const SETTINGS_LANGUAGES_PATH = `${SETTINGS_PATH}/languages`

export const MANAGE_USER_DATA_DELETION_PATH = `${APPS_PATH}/manage-user-data-deletion`

export const INTEGRATIONS_PATH = "/email-providers"
export const SMART_EMAILING_PATH = `${INTEGRATIONS_PATH}/smart-emailing`

export const AUTHENTICATION = `authentication`
export const AUTHENTICATION_FLOW = `${AUTHENTICATION}/flow`
export const AUTHENTICATION_PASSLESS = `${AUTHENTICATION}/password-less`
export const AUTHENTICATION_SETTINGS_PATH =  `${AUTHENTICATION}/settings`

export const MODELS_PATH = `/models`
export const MODELS_CREATE_PATH = `${MODELS_PATH}/create`