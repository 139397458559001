import { useEffect, useState } from "react";

import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Avatar,
  AvatarRounded,
  AvatarSize,
  LimitedTagsDropdown,
  Loader,
  Modal,
  ModalSize,
  Table,
  Td,
} from "../../../components";
import { useCurrentPage, useFetch, useToggle } from "../../../hooks";

interface IGrant {
  apiGrantNames: string[];
  clientId: string;
  clientLogoUrl: string;
  clientName: string;
  clientUrl: string;
  created: string;
  description: string;
  expires: string;
  identityGrantNames: string[];
  colorId?: number;
}

interface IGrantData {
  grants: IGrant[];
  pageSize: number;
  totalCount: number;
}

export const UserAuthorizedApps = () => {
  const { toggle, visible } = useToggle();
  const { id: userId } = useParams();
  const { currentPage, handlePageChange } = useCurrentPage();

  const [grantsData, setGrantsData] = useState<IGrantData>({
    grants: [
      {
        apiGrantNames: [""],
        clientId: "",
        clientLogoUrl: "",
        clientName: "",
        clientUrl: "",
        created: "",
        description: "",
        expires: "",
        identityGrantNames: [""],
      },
    ],
    totalCount: 0,
    pageSize: 0,
  });
  const [grant, setGrant] = useState<IGrant>();

  const { apiCall: getUserGrants, response, loading, errors } = useFetch("get");
  const { apiCall: revokeAccess, loading: revokeAccessLoading } =
    useFetch("post");

  useEffect(() => {
    getUserGrants(`PersistedGrants/GetUserGrants?userId=${userId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGrantsData(response);
  }, [response]);

  const onRevokeAccess = () => {
    revokeAccess(
      `PersistedGrants/RevokeByUser?clientId=${grant?.clientId}&userId=${userId}`,
      {},
      () => {
        toast.success("Access revoked successfully!");
        toggle();
        const filteredGrants = grantsData?.grants?.filter(
          (grantItem) => grantItem.clientId !== grant?.clientId
        );

        setGrantsData({
          ...grantsData,
          grants: filteredGrants,
        });
      }
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Name" },
          { children: "Created" },
          { children: "Expired" },
          { children: "Identity Grants" },
          { children: "", className: "text-right" },
        ]}
        values={grantsData?.grants || []}
        renderTr={(el: IGrant) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.clientId}>
              <Td className="py-3">
                {/* <NavLink
                  to=""
                  className="hover:text-primary transition"
                  state={{
                    index: el.colorId,
                  }}
                > */}
                <div className="flex items-center">
                  <Avatar
                    imgUrl={el?.clientLogoUrl}
                    text={el?.clientName}
                    size={AvatarSize.sm}
                    rounded={AvatarRounded["rounded-full"]}
                    className="mr-4"
                    colourful
                    index={el.colorId}
                  />
                  {el?.clientName}
                </div>
                {/* </NavLink> */}
              </Td>
              <Td className="relative py-3">
                {el?.created
                  ? format(new Date(el?.created), "MMMM dd, yyyy")
                  : ""}
              </Td>
              <Td className="py-3">
                {el?.created
                  ? format(new Date(el?.expires), "MMMM dd, yyyy")
                  : ""}
              </Td>
              <Td className="py-3">
                <LimitedTagsDropdown
                  tagLimit={2}
                  tags={el?.identityGrantNames}
                />
              </Td>
              <Td className="text-primary-secText py-3" align="right">
                <button
                  type="button"
                  onClick={() => {
                    toggle();
                    setGrant(el);
                  }}
                  className="p-2 transition hover:bg-gray-100 rounded focus:outline-none focus:shadow-outlineGray text-warning"
                >
                  Revoke Access
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: 10,
          totalCount: grantsData?.totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: grantsData?.grants?.length,
        }}
        emptyView={{ text: errors || "No grants yet." }}
      />

      {!!visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Are you sure you want to revoke this permission?"
          onConfirmClick={onRevokeAccess}
          confirmBtnText="Yes"
          modalSize={ModalSize.md}
          withFooter
          loading={revokeAccessLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            This application will no longer receive the information this user
            has provided.
          </p>
        </Modal>
      )}
    </>
  );
};
