import {
  FC,
  ReactElement,
  cloneElement,
  isValidElement,
  ReactNode,
} from "react";
import { Button, ButtonProps } from "../../components/button";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

interface HeaderProps {
  logo?: ReactElement;
  buttonProps?: ButtonProps;
  headerClass?: string;
  children: ReactNode;
}

export const Header: FC<HeaderProps> = ({
  children,
  headerClass = "",
  buttonProps,
  logo,
}) => {
  return (
    <header
      className={cx([
        "flex items-center md:pr-6 pr-2 py-3 shadow-md bg-white fixed top-0 lef-0 w-full",
        headerClass,
      ])}
    >
      <div className="flex md:w-72 mr-6 pl-6">
        {buttonProps && (
          <Button
            {...buttonProps}
            className={cx([buttonProps.className, "mr-6  md:hidden"])}
          >
            {buttonProps.children ? (
              buttonProps.children
            ) : (
              <span className="w-4 h-4 inline-block">
                <Icon name="Menu" />
              </span>
            )}
          </Button>
        )}
        {isValidElement(logo) && cloneElement(logo)}
      </div>
      {children && children}
    </header>
  );
};
