import axios from "axios";
import { createContext, useState, useContext, useEffect } from "react";
import { IRole, IUser } from "../interfaces";

const UserContext = createContext<any>(null);

const UserContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IUser>();
  const [userId, setUserId] = useState<string>();

  const [role, setRole] = useState<IRole>();
  const [roleId, setRoleId] = useState<string>();

  const getUser = async (id?: string) => {
    setLoading(true);
    try {
      const response = await axios.get(`/Users/${id}`);
      setUser({
        ...response.data,
      });
      setLoading(false);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getRole = async (id?: string) => {
    setLoading(true);
    try {
      const response = await axios.get(`/Roles/${id}`);
      setRole(response.data);
      setLoading(false);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (!!userId) getUser(userId);
  }, [userId]);

  useEffect(() => {
    if (!!roleId) getRole(roleId);
  }, [roleId]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loading,
        setLoading,
        setUserId,
        setRoleId,
        role,
        setRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  return useContext(UserContext);
};

export { UserContextProvider, useUserContext };
