
const tfaPAath = "two-factor-authentication";

export const redirectRoute: { [key: string]: string } = {
  security: "security",
  personalization: "personalization",
  profile: "profile",
  myaccount: "",
  "two-factor-authentication": tfaPAath,
  "recovery-codes": `${tfaPAath}/recovery-codes`,
  "authenticator-app": `${tfaPAath}/authenticator-app`,
  "text-message": `${tfaPAath}/text-message`,
  "text-message-modal": `${tfaPAath}/text-message`,
  "email-authenticator": `${tfaPAath}/email-authenticator`,
  "email-auth-modal": `${tfaPAath}/email-authenticator`,
  permissions: `permissions`,
  "recovery-email-modal": `recovery-email`,
  "recovery-email": `recovery-email`,
  "download-data": `download-data`,
  "delete-account": `delete-account`,
  "manage-devices": `manage-devices`,
  "device-details": `manage-devices/details`,
  newPasswordField: `change-password`,
  "change-password": `change-password`,
  passwordField: `change-password`,
  "external-logins": `external-logins`,
  "change-profile-photo": `profile`,
};