import { ChangeEvent, KeyboardEvent, useState, useEffect } from "react";

import { NavLink } from "react-router-dom";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import {
  AddNewAppModal,
  Table,
  Td,
  SearchHeader,
  Avatar,
  PageHeadeline,
  Button,
  BtnType,
  BtnStyle,
  BtnSize,
} from "../../../components";
import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import { APPS_PATH } from "../../../constants";
import { MainContainer } from "../../../components/layout/MainContainer";
import { Permission } from "../../../context";
import { cancelBtnClasses, copyToClipboard, cx, SEO } from "../../../utils";
import { IClient } from "./interface";
import { AppsArrangement } from "./components";
import { Arrange } from "../../../components/Icons";

export const Applications: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { currentPage, handlePageChange } = useCurrentPage();
  const { visible, toggle } = useToggle();
  const { visible: sidebarVisible, toggle: sidebarToggle } = useToggle();

  const {
    apiCall: getApplications,
    loading,
    response: applicationsRes,
    errors,
  } = useFetch("get");

  const PER_PAGE = 7;

  console.log("fetch errors", errors);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    // TODO: should set query parameters on page url
    setSearchTerm(e.target?.value);
  };

  useEffect(() => {
    SEO({
      title: "Porta - Applications",
    });
  }, []);

  const getAllApplications = () => {
    getApplications(
      `/Clients/v1?searchText=${searchTerm}&page=${currentPage}&pageSize=${PER_PAGE}`
    );
  };

  useEffect(() => {
    getAllApplications();
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      getAllApplications();
    }
  };

  const handleSearchBtnClick = () => {
    searchTerm !== "" && getAllApplications();
  };

  const handleClearInputBtn = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { admin, appsEditor } = Permission;

  const emptySectionText =
    !!searchTerm && applicationsRes?.clients.length < 1
      ? `No application found.`
      : "There are no applications yet.";

  return (
    <MainContainer
      className={cx([sidebarVisible && "active-sidebar", "overflow-hidden"])}
    >
      <PageHeadeline
        className="mb-10"
        title="Applications"
        description="Add a web, mobile or device-to-device application to use Porta for Authentication."
      />

      <div className="flex flex-1 relative">
        <div className="h-full flex flex-col flex-grow applications-list ">
          <SearchHeader
            onButtonClick={toggle}
            onSearchBtnClick={handleSearchBtnClick}
            onSearchChange={handleSearch}
            onSearchKeypress={handleKeyPress}
            onClearInputBtn={handleClearInputBtn}
            buttonLabel="New Application"
            searchValue={searchTerm}
            permissions={[admin, appsEditor]}
            rightContentClassName="flex items-center"
            nextSearchContent={
              <Button
                btnType={BtnType.secondary}
                btnStyle={BtnStyle.neutral}
                btnSize={BtnSize.normal}
                className={cx([cancelBtnClasses, "flex items-center mr-4"])}
                type="button"
                onClick={sidebarToggle}
              >
                <Arrange className="mr-2 w-4.5 inline-block" />
                Arrange
              </Button>
            }
          />

          <Table
            loading={loading}
            wrapperClassName="mt-4 sm:mt-6"
            names={[{ children: "Name" }, { children: "Client ID" }]}
            values={applicationsRes?.clients}
            renderTr={(el: IClient) => {
              const { clientName, clientId, clientType, logoUri, id } = el;

              return (
                <tr className="transition hover:bg-gray-50" key={el.clientId}>
                  <Td>
                    <NavLink
                      to={`${APPS_PATH}/${id}` || ""}
                      state={{
                        index: el.colorId,
                      }}
                      title="Edit"
                      className="flex items-center"
                    >
                      <Avatar
                        imgUrl={logoUri}
                        text={clientName}
                        index={el.colorId}
                        colourful
                      />
                      <div className="ml-3">
                        <h6 className="text-normal font-medium text-primary-mainText group-hover:text-primary transition">
                          {clientName}
                        </h6>
                        <p className="text-sm text-primary-secText mt-1">
                          {clientType}
                        </p>
                      </div>
                    </NavLink>
                  </Td>
                  <Td>
                    <div className="flex items-center">
                      <p className="text-sm text-primary-mainText">
                        {clientId}
                      </p>
                      <button
                        className="ml-2 transition hover:text-primary focus:outline-none text-primary-secText focus:shadow-outlinePrimary rounded"
                        type="button"
                        onClick={() =>
                          copyToClipboard(
                            clientId,
                            "Client ID copied successfully!"
                          )
                        }
                      >
                        <span className="w-5 h-5 inline-block">
                          <Icon name="Copy" size={20} />
                        </span>
                      </button>
                    </div>
                  </Td>
                </tr>
              );
            }}
            pagination={{
              pageSize: PER_PAGE,
              totalCount: applicationsRes?.totalCount,
              onPageChange: handlePageChange,
              page: currentPage,
              length: applicationsRes?.clients?.length,
            }}
            emptyView={{ text: errors || emptySectionText }}
          />
        </div>

        {sidebarVisible && (
          <div className="pb-10 flex flex-1 arrange-apps-sidebar ml-6 bg-white">
            <AppsArrangement toggle={sidebarToggle} />
          </div>
        )}
      </div>

      {visible && <AddNewAppModal visible={visible} toggle={toggle} />}
    </MainContainer>
  );
};
