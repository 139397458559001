import { FC } from "react";

export const PasteClipboard: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path d="M7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H16C16.4142 10.25 16.75 10.5858 16.75 11C16.75 11.4142 16.4142 11.75 16 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11Z" />
      <path d="M8 14.25C7.58579 14.25 7.25 14.5858 7.25 15C7.25 15.4142 7.58579 15.75 8 15.75H12C12.4142 15.75 12.75 15.4142 12.75 15C12.75 14.5858 12.4142 14.25 12 14.25H8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28224 3.34497C8.69938 2.54566 9.53597 2 10.5 2H13.5C14.464 2 15.3006 2.54566 15.7178 3.34497C15.8259 3.28448 15.9506 3.25 16.0833 3.25C18.1084 3.25 19.75 4.89162 19.75 6.91667V18C19.75 20.0711 18.0711 21.75 16 21.75H8C5.92893 21.75 4.25 20.0711 4.25 18V6.91667C4.25 4.89162 5.89162 3.25 7.91667 3.25C8.0494 3.25 8.17408 3.28448 8.28224 3.34497ZM10.5 3.5H13.5C14.0523 3.5 14.5 3.94772 14.5 4.5C14.5 5.05228 14.0523 5.5 13.5 5.5H10.5C9.94772 5.5 9.5 5.05228 9.5 4.5C9.5 3.94772 9.94772 3.5 10.5 3.5ZM8.01176 4.74403C7.98062 4.74797 7.94888 4.75 7.91667 4.75C6.72005 4.75 5.75 5.72005 5.75 6.91667V18C5.75 19.2426 6.75736 20.25 8 20.25H16C17.2426 20.25 18.25 19.2426 18.25 18V6.91667C18.25 5.72005 17.28 4.75 16.0833 4.75C16.0511 4.75 16.0194 4.74797 15.9882 4.74403C15.8656 6.01022 14.7984 7 13.5 7H10.5C9.20162 7 8.13442 6.01022 8.01176 4.74403Z"
      />
    </svg>
  );
};
