import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Checkbox,
  FormControl,
  FormFooter,
  Label,
  PageHeadeline,
  Input,
  InputSize,
  Loader,
} from "../../components";
import { MainContainer } from "../../components/layout/MainContainer";
import { useFetch } from "../../hooks";
import { inputClasses, SEO } from "../../utils";

interface IAuthSettings {
  pwnIsEnabled: boolean;
  showUserPwnValidationEnabled: boolean;
  requiredLength: string;
  requireDigit: boolean;
  requireLowercase: boolean;
  requireUppercase: boolean;
  requireNonAlphanumeric: boolean;
  canNotContainFirstName: boolean;
  canNotContainLastName: boolean;
  canNotContainEmail: boolean;
}

export const AuthSettings: FC = () => {
  const { apiCall: getAuthSettings, response, loading } = useFetch("get");
  const { apiCall: editAuthSettings, loading: editLoading } = useFetch("post");

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta - Authentication Settings",
    });

    getAuthSettings("Users/AuthenticationSettings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const onEditAuthSettings: SubmitHandler<any> = (data: IAuthSettings) => {
    const editAuthSettingsObj = {
      ...data,
      requiredLength: parseInt(data.requiredLength),
    };

    editAuthSettings(
      `Users/EditAuthenticationSettings`,
      editAuthSettingsObj,
      () => {
        toast.success("Authentication Settings saved successfully!");
      }
    );
  };

  return (
    <MainContainer>
      <PageHeadeline
        title="Authentication Settings"
        description="Password strength is an important consideration when using passwords for authentication. A strong password policy will make it difficult, if not improbable, for someone to guess a password through either manual or automated means."
        className="mb-10"
      />
      {loading ? (
        <Loader />
      ) : (
        <form className="h-full flex flex-grow flex-col">
          <h6 className="text-primary-mainText font-medium mb-6">
            Password Settings
          </h6>
          <FormControl className="max-w-md">
            <Label text="Minimum length" htmlFor="requiredLength" />
            <Input
              {...register("requiredLength", {
                validate: {
                  minLength: (value) =>
                    value > 5 || "Password length should be greater than 6",
                  maxLength: (value) =>
                    value < 101 || "Password length should be smaller than 20",
                },
              })}
              error={!!errors.requiredLength && errors.requiredLength.message}
              inputSize={InputSize.sm}
              className={inputClasses}
              maxLength={2}
              type="number"
            />
          </FormControl>

          <FormControl lastChild className="mb-auto">
            <h6 className="text-sm font-medium text-primary-mainText mb-2">
              Complexity requirements
            </h6>

            <Controller
              control={control}
              name="requireLowercase"
              render={({ field: { onChange, value, ref } }: any) => (
                <Checkbox
                  label="Lower case letter"
                  id="lowercase"
                  checked={value || false}
                  inputRef={ref}
                  onChange={onChange}
                  wrapperClassName="mb-1"
                />
              )}
            />

            <Controller
              control={control}
              name="requireUppercase"
              render={({ field: { onChange, value, ref } }: any) => (
                <Checkbox
                  label="Upper case letter"
                  id="uppercase"
                  checked={value || false}
                  inputRef={ref}
                  onChange={onChange}
                  wrapperClassName="mb-1"
                />
              )}
            />

            <Controller
              control={control}
              name="requireDigit"
              render={({ field: { onChange, value, ref } }: any) => (
                <Checkbox
                  label="Number (0-9)"
                  id="requireDigit"
                  checked={value || false}
                  inputRef={ref}
                  onChange={onChange}
                  wrapperClassName="mb-1"
                />
              )}
            />

            <Controller
              control={control}
              name="requireNonAlphanumeric"
              render={({ field: { onChange, value, ref } }: any) => (
                <Checkbox
                  label="Symbol (e.g., !@#$%^&*)"
                  id="requireNonAlphanumeric"
                  checked={value || false}
                  inputRef={ref}
                  onChange={onChange}
                  wrapperClassName="mb-1"
                />
              )}
            />

            <Controller
              control={control}
              name="canNotContainFirstName"
              render={({ field: { onChange, value, ref } }: any) => (
                <Checkbox
                  label="Does not contain first name"
                  id="canNotContainFirstName"
                  checked={value || false}
                  inputRef={ref}
                  onChange={onChange}
                  wrapperClassName="mb-1"
                />
              )}
            />

            <Controller
              control={control}
              name="canNotContainLastName"
              render={({ field: { onChange, value, ref } }: any) => (
                <Checkbox
                  label=" Does not contain last name"
                  id="canNotContainLastName"
                  checked={value || false}
                  inputRef={ref}
                  onChange={onChange}
                />
              )}
            />
          </FormControl>

          <FormFooter
            onSubmit={handleSubmit(onEditAuthSettings)}
            className="w-full"
            pb="pb-0"
            loading={editLoading}
          />
        </form>
      )}
    </MainContainer>
  );
};
