import { useState } from "react";

import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { useFetch, useToggle } from "../../../../hooks";
import { Modal, Paragraph, ParagraphSize } from "../../../../components";
import { ModalSize } from "../../../../components/modal";
import { cx } from "../../../../utils";

interface IMfaMethods {
  user: {
    mfaEnrollmentVerification: any;
    email: string;
    firstName: string;
    onlyOneActiveMfaMethod: boolean;
    id: string;
    mfaMethods: [
      {
        tfaMethod: string;
        contact: string;
      }
    ];
  };
  setUser: any;
}

export const MfaMethods: React.FC<IMfaMethods> = ({ user, setUser }) => {
  const { toggle, visible } = useToggle();
  const { toggle: resetMfaToggle, visible: resetMfaVisible } = useToggle();
  const { apiCall: resetMfaMethod, loading: resetMfaMethodLoading } =
    useFetch("put");
  const { apiCall: resetMfaMethods, loading: resetMfaMethodsLoading } =
    useFetch("put");
  const [mfaMethod, setMfaMethod] = useState<string>("");

  // console.log("user mfa methods", user);

  const showTfaMethodProps = (method: string) => {
    let props = {
      name: "",
      icon: "",
    };

    switch (method) {
      case "SmsOtp":
        props = {
          name: "Text message (SMS)",
          icon: "Phone",
        };
        break;

      case "EmailOtp":
        props = {
          name: "Email verification",
          icon: "Message",
        };
        break;

      case "AuthenticatorApp":
        props = {
          name: "Authenticator app",
          icon: "Authenticator",
        };
        break;

      default:
        props = {
          name: "",
          icon: "",
        };
    }

    return props;
  };

  const onMfaMethodReset = () => {
    resetMfaMethod(
      `Users/RemoveMfaMethod?userId=${user?.id}&methodName=${mfaMethod}`,
      {},
      () => {
        const tfaMethodsLength = user?.mfaMethods?.length - 1;
        toast.success("Mfa method removed successfully!");
        toggle();
        setUser({
          ...user,
          onlyOneActiveMfaMethod:
            tfaMethodsLength < 2 ? true : user?.onlyOneActiveMfaMethod,
          mfaMethods: user?.mfaMethods?.filter(
            (method) => method.tfaMethod !== mfaMethod
          ),
        });
      }
    );
  };

  const onMfaMethodsReset = () => {
    resetMfaMethods(`Users/ResetMfa?userId=${user?.id}`, {}, () => {
      resetMfaToggle();
      toast.success("Mfa methods reset successfully!");
      setUser({
        ...user,
        twoFactorEnabled: false,
      });
    });
  };

  return (
    <>
      <p className="text-sm text-primary-mainText font-medium">
        Multi-Factor Authentication
      </p>
      <Paragraph className="mb-4 mt-1" noMargin>
        The following MFA method(s) are enabled for this user.
      </Paragraph>

      <div className="rounded-lg border border-primary-stroke p-4 w-1/2 mb-3">
        {user?.mfaMethods?.map(({ tfaMethod, contact }, index) => {
          const { name, icon } = showTfaMethodProps(tfaMethod);
          const lastChild = index === user?.mfaMethods?.length - 1;

          return (
            <div
              className={cx([
                "flex items-center relative group",
                !lastChild && "mb-4 ",
              ])}
            >
              <div className="absolute top-2">
                <span className="w-6 h-6 inline-block">
                  <Icon name={icon} size={24} />
                </span>
              </div>

              <div
                className={cx([
                  "flex items-center flex-1 justify-between ml-10 border-primary-stroke",
                  !lastChild && "border-b pb-4",
                ])}
              >
                <div>
                  <p className="text-sm transition font-medium text-primary-mainText group-hover:text-primary">
                    {name}
                  </p>
                  <p className="text-xs font-medium text-primary-secText">
                    {tfaMethod === "AuthenticatorApp"
                      ? "Authenticator App on Device"
                      : contact}
                  </p>
                </div>

                {!user?.onlyOneActiveMfaMethod && (
                  <button
                    type="button"
                    className="transition text-sm text-primary-secText hover:text-primary outline-none focus:shadow-outlinePrimary rounded"
                    title="Remove"
                    onClick={() => {
                      toggle();
                      setMfaMethod(tfaMethod);
                    }}
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <Paragraph parahraphSize={ParagraphSize.sm} noMargin className="mb-6">
        You can reset the MFA method(s) individually by clicking on the
        respective reset button or{" "}
        <button
          className="text-primary text-sm font-medium transition hover:text-primary-mainText"
          type="button"
          onClick={resetMfaToggle}
        >
          reset all MFA method(s) for this user
        </button>
        .
      </Paragraph>

      {visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Reset Multi-Factor Authentication"
          onConfirmClick={onMfaMethodReset}
          confirmBtnText="Reset"
          warningModal
          modalSize={ModalSize.md}
          withFooter
          loading={resetMfaMethodLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to disable{" "}
            <strong>
              {showTfaMethodProps(mfaMethod)?.name?.toLowerCase()}
            </strong>{" "}
            method this user has set-up? This action cannot be undone and the
            user will have to set their{" "}
            {showTfaMethodProps(mfaMethod)?.name?.toLowerCase()} method again.
          </p>
        </Modal>
      )}

      {resetMfaVisible && (
        <Modal
          hide={resetMfaToggle}
          visible={resetMfaVisible}
          title="Reset Multi-Factor Authentication"
          onConfirmClick={onMfaMethodsReset}
          confirmBtnText="Reset"
          warningModal
          modalSize={ModalSize.md}
          withFooter
          loading={resetMfaMethodsLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to disable 2FA methods this user has set-up?
            This action cannot be undone and the user will have to set their 2FA
            method again.
          </p>
        </Modal>
      )}
    </>
  );
};
