import { TextareaHTMLAttributes, forwardRef } from "react";
import { Label, LabelProps } from "../label";
import { Message } from "../messages";
import { cx } from "../../utils/classnames";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  labelProps?: LabelProps;
  error?: string;
  success?: string;
  containerClassName?: string;
}
export const Textarea = forwardRef(
  (
    {
      labelProps,
      error,
      success,
      className,
      containerClassName,
      ...props
    }: TextareaProps,
    ref: any
  ) => (
    <div className={cx(["flex flex-col", containerClassName])}>
      {labelProps && (
        <Label
          className="mb-1.5"
          {...labelProps}
          htmlFor={labelProps.htmlFor || props.id || props.name}
          error={!!error}
          success={!!success}
        />
      )}
      <textarea
        {...props}
        ref={ref}
        id={props.id || props.name}
        className={cx([
          "text-sm leading-4.5 min-w-full rounded border focus:outline-none focus:border focus:border-primary p-2",
          className,
          success && "border border-confirm",
          error &&
            "border border-warning focus:shadow-warning focus:border-warning",
        ])}
      />
      {error && <Message message={error} containerClassName="mt-2" />}
    </div>
  )
);
