import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";

import { Modal } from "../../../../components";
import { ModalSize } from "../../../../components/modal";
import { useUserContext } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { IUser, IUsersAll } from "../../../../interfaces";

interface IUserBlockModal {
  visible: boolean;
  toggle: () => void;
  user: IUser;
  response: IUsersAll;
  setResponse: Dispatch<SetStateAction<IUsersAll>>;
}

export const UserBlockModal: React.FC<IUserBlockModal> = ({
  visible,
  toggle,
  user,
  response,
  setResponse,
}) => {
  const { user: userFromContext, setUser } = useUserContext();
  const { apiCall: blockUser, loading: blockUserLoading } = useFetch("post");
  const { apiCall: unBlockUser, loading: unBlockUserLoading } =
    useFetch("post");

  function filterUsersArray(isBlocked: boolean) {
    const filteredArr = response.users.map((userItem: any) => {
      if (userItem.id === user.id) {
        return {
          ...userItem,
          isBlocked,
        };
      }

      return userItem;
    });

    setResponse({
      ...response,
      users: filteredArr,
    });
    setUser({
      ...userFromContext,
      isBlocked,
    });
  }

  const onBlockUser = () => {
    blockUser(`/Users/BlockUser?id=${user?.id}`, {}, () => {
      toast.success(`User account blocked successfully!`);

      filterUsersArray(true);
      toggle();
    });
  };

  const onUnBlockUser = () => {
    unBlockUser(`/Users/UnBlockUser?id=${user?.id}`, {}, () => {
      filterUsersArray(false);
      toast.success(`User account unblocked successfully!`);
      toggle();
    });
  };

  const { isBlocked } = user;

  return (
    <Modal
      visible={visible}
      hide={toggle}
      loading={isBlocked ? unBlockUserLoading : blockUserLoading}
      modalSize={ModalSize.sm}
      withFooter
      confirmBtnText={isBlocked ? "Unblock" : "Block"}
      warningModal
      title={`${isBlocked ? "Unblock" : "Block"} user`}
      onConfirmClick={isBlocked ? onUnBlockUser : onBlockUser}
      blockOutsideClick
    >
      <p className="text-sm text-primary-mainText mb-4">
        This operation will {isBlocked ? "unblock" : "block"} any attempt of
        sign-in of{" "}
        <strong className="font-medium">
          {user?.firstName} {user?.lastName}
        </strong>
        ? Are you sure?
      </p>
    </Modal>
  );
};
