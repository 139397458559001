import { FC, useState } from "react";
// import { useFormContext } from "react-hook-form";
// import { Controller, useFormContext } from "react-hook-form";
import { useTemplateContext } from "../../../../../context";
import { Layout } from "./Layout";

export const Layouts: FC = () => {
  const { theme } = useTemplateContext();

  const bannerPlacements = [
    { title: "Bottom", type: "bottom" },
    // { title: "Top", type: "top" },
    // { title: "Left", type: "left" },
    // { title: "Right", type: "right" },
    // { title: "Bottom Right", type: "bottom-right" },
    { title: "Bottom Left", type: "bottom-left" },
    // { title: "Top Right", type: "top-right" },
    // { title: "Top Left", type: "top-left" },
    { title: "Center", type: "center" },
  ];
  const [selected, setSelected] = useState<string>(theme?.bannerPlacement);
  // const { register } = useFormContext();
  const clickHandler = (type: string) => {
    // setTheme({ ...theme, bannerPlacement: type });
    setSelected(type);
  };
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
      {bannerPlacements?.map(({ title, type }, index) => (
        <Layout
          key={index}
          title={title}
          bannerId={index}
          type={type}
          // onClick={() => setSelected(type)}
          onClick={() => clickHandler(type)}
          selected={type === selected}
        />
      ))}
    </div>
  );
};
