import { Dispatch, FC } from "react";
import { Controller } from "react-hook-form";
import { NavLink } from "react-router-dom";

import { IApiScope, IReactHookForm } from "../../../../interfaces";
import {
  BackButton,
  DeleteSection,
  FormControl,
  FormWrapper,
  Label,
  SectionTitle,
  Input,
  InputSize,
  ToggleSwitch,
  Textarea,
  BtnSize,
  BtnStyle,
  Button,
} from "../../../../components";
import { API_SCOPES_PATH } from "../../../../constants";
import { MultiTagsCard } from "../../../../components/ui/MultiTagsCard";
import {
  inputClasses,
  primaryBtnClasses,
  textareaClasses,
} from "../../../../utils";

interface IApiScopeForm extends IReactHookForm {
  onSubmit: () => void;
  apiScope?: IApiScope;
  id?: any;
  loading?: any;
  toggle?: () => void;
  editMode?: boolean;
  claims: string[];
  setClaims: Dispatch<string[]>;
}

export const ApiScopeForm: FC<IApiScopeForm> = (props) => {
  return (
    <>
      <FormWrapper
        submitBtnType="button"
        onSubmit={props.onSubmit}
        loading={props.loading}
      >
        <BackButton
          to={API_SCOPES_PATH}
          className="mb-4"
          label="Back to API Scopes"
        >
          <></>
        </BackButton>

        <SectionTitle>API Scope</SectionTitle>
        <FormControl description="The unique name of the API. This value is used for authentication with introspection and will be added to the audience of the outgoing access token.">
          <Label required text="Name" htmlFor="name" />
          <Input
            id="name"
            autoFocus={!props.editMode}
            {...props.register("name", {
              required: "API Scope name is required",
              validate: {
                notValidName: (value: string) =>
                  !!value.trim() || "API Scope name is required",
              },
            })}
            maxLength={100}
            error={!!props.errors.name && props.errors.name.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl description="This value can be used e.g. on the consent screen.">
          <Label text="Display Name" htmlFor="display-name" />
          <Input
            id="display-name"
            {...props.register("displayName")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl description="This value can be used e.g. on the consent screen.">
          <Label text="Description" htmlFor="description" />
          <Textarea
            id="description"
            {...props.register("description")}
            className={textareaClasses}
            maxLength={3000}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="showInDiscoveryDocument"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="discovery-doc"
                label="Show In Discovery Document"
                description="Specifies whether this scope is shown in the discovery document. Defaults to true."
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="required"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="required"
                label="Required"
                description="Specifies whether the user can de-select the scope on the consent screen (if the consent screen wants to implement such a feature). Defaults to false."
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="enabled"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="enabled"
                label="Enabled"
                description="Indicates if this resource enabled and can be requested. Defaults to true."
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        {props.editMode && (
          <FormControl description="Dictionary to hold any custom API resource-specific values as needed.">
            <Label text="Properties" />
            <NavLink
              to={`${API_SCOPES_PATH}/api-scope-properties/${props.id}`}
              state={{
                id: props?.apiScope?.id,
                name: props?.apiScope?.name,
              }}
            >
              <Button
                type="button"
                btnStyle={BtnStyle.primary}
                btnSize={BtnSize.normal}
                className={primaryBtnClasses}
              >
                Manage API Scope Properties
              </Button>
            </NavLink>
          </FormControl>
        )}

        <FormControl description="List of associated user claim types that should be included in the access token.">
          <Label text="User Claims" />
          <MultiTagsCard
            tags={props.claims}
            setTags={props.setClaims}
            hasInput
            suggestedEndpoint="/Clients/SearchClaims?limit=0"
          />
        </FormControl>

        {props.editMode && (
          <DeleteSection
            className="items-center p-4 rounded bg-opacity-10 bg-red"
            title="Delete API Scope"
            message="Deleting the API scope will not allow an app to request permission to access the specified resource through an authorization server."
            onDelete={props.toggle}
          />
        )}
      </FormWrapper>
    </>
  );
};
