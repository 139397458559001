import { ChangeEvent, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import {
  DefaultLangHeader,
  EditKeysHeader,
  SelectedLangHeader,
  SelectedLngResource,
} from "./components";
import { useDebounce, useFetch } from "../../hooks";
import {
  INameSpace,
  ILngState,
  ILngResource,
  INamespaceModel,
  ILanguage,
} from "./interface";
import { cx, SEO } from "../../utils";
import { Loader } from "../../components";

const namespaceIState = {
  nameSpace: "all",
  beautifiedNamespace: "All Namespaces",
};

export const EditLanguageKeys: React.FC = () => {
  const { state } = useLocation();
  const {
    selectedRow: {
      name,
      defaultLang,
      defaultLangCode,
      languages,
      id,
      countryCode,
      languageCode,
    },
  } = !!state && (state as any);
  const [allResources, setAllResources] = useState<ILngResource[]>();
  const [mergedResources, setMergedResources] = useState<ILngResource[]>();
  const [searchedItems, setSearchItems] = useState<ILngResource[]>();

  const [lState, setLState] = useState<ILngState>({
    term: "",
    hoveredElm: "",
    mappedId: "",
    inputValue: "",
    currentId: "",
    namespace: namespaceIState,
    namespaces: [namespaceIState],
    language: {
      name: name || "",
      id: id || 0,
      countryCode,
      languageCode,
    },
    searchLoading: false,
  });

  const { apiCall: getLocalizations, response, loading } = useFetch("get");
  const { apiCall: editResources, loading: editResourcesLoading } =
    useFetch("post");

  const debouncedSearchTerm = useDebounce(lState.term, 500);

  useEffect(() => {
    SEO({
      title: "Porta - Edit Language Keys",
    });
  }, []);

  useEffect(() => {
    getLocalizations(
      `Localization/EditLocalizationResources?languageCode=${
        lState.language?.languageCode
      }&nameSpace=${
        lState.namespace.nameSpace === "all" ? "" : lState.namespace.nameSpace
      }`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lState.namespace, lState.language?.languageCode]);

  useEffect(() => {
    const mergedArrays = [
      ...(response?.defaultLanguageResources?.map((item: ILngResource) => {
        return {
          ...item,
          isDefault: true,
        };
      }) || []),
      ...(response?.selectedLanguageResources?.map((item: ILngResource) => {
        return {
          ...item,
          isDefault: false,
        };
      }) || []),
    ];
    setAllResources(mergedArrays);
    setMergedResources(mergedArrays);
  }, [response, lState.term]);

  // All about filter select
  useEffect(() => {
    let selectFilterNamespaces: INamespaceModel[] = [];

    response?.localizationNamespaces?.forEach((namespace: INamespaceModel) => {
      selectFilterNamespaces.push(namespace);
    });

    setLState({
      ...lState,
      namespaces: [namespaceIState, ...selectFilterNamespaces],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, lState.namespace]);

  const handleNameSpaceSelect = (selected: any) => {
    setLState({
      ...lState,
      namespace: selected,
    });
  };

  /**
   * @categoryNamespace - returns namespaces of that category based on given index
   * @setResources keeps state up to date based on which namespace was changed
   * @return {tempNameSpaceState, tempState} - current category namespace object & current namespace object
   */

  const handleNameSpaceState = (
    inputValue: string,
    index: number,
    categoryIndex: number,
    touched?: boolean
  ) => {
    const lngResources: ILngResource[] = [
      ...(mergedResources?.filter((item) => !item.isDefault) || []),
    ];

    const categoryNamespaces: INameSpace[] = !!mergedResources
      ? [
          ...(mergedResources?.filter((item) => !item.isDefault)[categoryIndex]
            ?.localizationResources || []),
        ]
      : [];

    categoryNamespaces[index] = {
      ...categoryNamespaces[index],
      touched,
      value: inputValue,
    };

    lngResources[categoryIndex] = {
      ...lngResources[categoryIndex],
      localizationResources: categoryNamespaces,
    };

    const defaultResources =
      mergedResources?.filter((item) => item.isDefault) || [];
    const mergerdedFilteredResources: any = [
      ...defaultResources,
      ...lngResources,
    ];

    !!mergedResources && setMergedResources(mergerdedFilteredResources);

    return { categoryNamespaces, lngResources };
  };

  const onEditResources = (
    namespace: INameSpace,
    index: number,
    categoryIndex: number
  ) => {
    const namespaceObj = {
      ...namespace,
      value: namespace.value,
    };

    editResources(`Localization/EditLocalizationResource`, namespaceObj, () => {
      toast.success("Localization updated successfully!");
      setLState({
        ...lState,
        mappedId: namespaceObj.mappedId || "",
      });

      // Update loacal state (edited namespace)
      handleNameSpaceState(namespace?.value || "", index, categoryIndex, false);
    });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    index: number,
    categoryIndex: number
  ) => {
    const inputValue = e.currentTarget?.value;

    const tempNameSpaceBackendResources: any = !!mergedResources && [
      ...mergedResources?.filter((item) => !item.isDefault)[categoryIndex]
        .localizationResources,
    ];

    const inputPrevValue = tempNameSpaceBackendResources[index].value;
    const touched = inputValue !== inputPrevValue && inputValue !== "";

    handleNameSpaceState(e?.target.value, index, categoryIndex, touched);
  };

  const searchByResourceName = (languages: ILngResource[] = []) => {
    return languages
      ?.map((item) => {
        const localizationResources = item.localizationResources.filter(
          (namespace: INameSpace) =>
            namespace.value
              ?.toLocaleLowerCase()
              .indexOf(lState.term.toLocaleLowerCase()) !== -1
        );
        return {
          ...item,
          localizationResources,
        };
      })
      .filter((item) => item.localizationResources.length > 0);
  };

  // Functions about search by namespace
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target?.value;
    setLState({
      ...lState,
      term: inputVal,
    });

    inputVal === "" ? setSearchItems([]) : setSearchItems(mergedResources);
  };

  const searchByResourceId = (
    languages: ILngResource[] = [],
    selectedResources: ILngResource[] = []
  ) => {
    const ids = selectedResources
      .map(({ localizationResources }) =>
        localizationResources?.map(({ commonId }) => commonId)
      )
      .flat();
    return languages
      ?.map((item) => {
        const localizationResources = item?.localizationResources.filter(
          (namespace) => {
            return ids.includes(namespace.commonId || "");
          }
        );
        return {
          ...item,
          localizationResources,
        };
      })
      .filter((item) => item.localizationResources.length > 0);
  };

  useEffect(() => {
    setMergedResources(
      searchByResourceId(allResources, searchByResourceName(allResources))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const onLanguageChange = (lang: ILanguage) => {
    lState.term !== "" && setSearchItems(mergedResources);

    setLState({
      ...lState,
      language: lang,
    });

    document.body.click();
  };

  useEffect(() => {
    if (searchedItems?.length) {
      setMergedResources(searchByResourceId(allResources, searchedItems));
    }
  }, [allResources, searchedItems]);

  return (
    <div className="flex flex-col h-full">
      <EditKeysHeader
        lState={lState}
        resource={{
          name,
          numberOfFilteredResources: response?.numberOfFilteredResources,
          numberOfResources: response?.numberOfResources,
        }}
        handleNameSpaceSelect={handleNameSpaceSelect}
        handleSearch={handleSearch}
      />

      <form className="h-full">
        <div className="flex flex-1 h-full">
          <div className="border border-primary-stroke rounded py-4 px-6 bg-white w-1/2 mr-3">
            <DefaultLangHeader
              defaultLang={{ name: defaultLang, code: defaultLangCode }}
            />

            {(loading || lState.searchLoading) && <Loader />}
            <div>
              {!mergedResources?.filter((item) => item.isDefault)?.length &&
                !loading &&
                !lState.searchLoading && (
                  <p className="text-sm text-primary-secText">
                    No translations found.
                  </p>
                )}

              {!loading &&
                !lState.searchLoading &&
                mergedResources
                  ?.filter((item) => item.isDefault)
                  .map(
                    ({ beautifiedNamespace, localizationResources }, index) => {
                      return (
                        <div key={index}>
                          <h6
                            className={cx([
                              "font-bold text-sm text-primary-mainText inline-block",
                              index === 0 ? "mb-3" : "my-3",
                            ])}
                          >
                            {beautifiedNamespace}
                          </h6>

                          {localizationResources?.map(
                            (namespace: INameSpace) => (
                              <p
                                className={cx([
                                  "text-sm text-primary-mainText mb-3 h-10 flex items-center relative highlight-key",
                                  lState.hoveredElm === namespace.id &&
                                    "highlighted",
                                ])}
                              >
                                {namespace.value}
                              </p>
                            )
                          )}
                        </div>
                      );
                    }
                  )}
            </div>
          </div>

          <div className="border border-primary-stroke rounded py-4 px-6 bg-white w-1/2">
            <SelectedLangHeader
              languages={languages}
              lState={lState}
              setLState={setLState}
              onLanguageChange={onLanguageChange}
            />

            <div className="mb-6">
              {(loading || lState.searchLoading) && <Loader />}
              <div>
                {!mergedResources?.filter((item) => !item.isDefault)?.length &&
                  !loading &&
                  !lState.searchLoading && (
                    <p className="text-sm text-primary-secText">
                      No translations found.
                    </p>
                  )}

                {!loading &&
                  !lState.searchLoading &&
                  mergedResources
                    ?.filter((item) => !item.isDefault)
                    ?.map(
                      (
                        { beautifiedNamespace, localizationResources },
                        categoryIndex
                      ) => {
                        return (
                          <div key={categoryIndex}>
                            <h6
                              className={cx([
                                "font-bold text-sm text-primary-mainText inline-block",
                                categoryIndex === 0 ? "mb-3" : "my-3",
                              ])}
                            >
                              {beautifiedNamespace}
                            </h6>

                            {localizationResources?.map(
                              (namespace: INameSpace, index: number) => (
                                <SelectedLngResource
                                  key={index}
                                  namespace={namespace}
                                  onMouseEnter={() => {
                                    setLState({
                                      ...lState,
                                      hoveredElm: namespace.mappedId || "",
                                    });
                                  }}
                                  onMouseLeave={() =>
                                    setLState({ ...lState, hoveredElm: "" })
                                  }
                                  onInputChange={(e) =>
                                    handleInputChange(e, index, categoryIndex)
                                  }
                                  onButtonClick={() => {
                                    onEditResources(
                                      namespace,
                                      index,
                                      categoryIndex
                                    );
                                    setLState({
                                      ...lState,
                                      currentId: namespace.id,
                                    });
                                  }}
                                  loading={editResourcesLoading}
                                  currentId={lState.currentId}
                                />
                              )
                            )}
                          </div>
                        );
                      }
                    )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
