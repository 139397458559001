import { useEffect } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { Modal, ModalSize } from "../../../components";
import { useFetch } from "../../../hooks";
import { ManageMemberModalForm } from ".";
import { IInvitedMember, IMember } from "../../../interfaces";
import { toast } from "react-toastify";

interface IManageMemberModal {
  visible: boolean;
  toggle: () => void;
  editMode?: boolean;
  member?: IInvitedMember & IMember;
  listView?: any;
  setListView?: any;
  setCurrentPage?: any;
  setActiveView?: any;
}

export const ManageMemberModal: React.FC<IManageMemberModal> = ({
  visible,
  toggle,
  editMode,
  member,
  listView,
  setListView,
  setActiveView,
}) => {
  const { apiCall: getApplications, response: applications } = useFetch("get");
  const { apiCall: sendMemberInvitation, loading } = useFetch("post");
  const { apiCall: editInvitation, loading: editInvitationLoading } =
    useFetch("post");
  const { apiCall: getRoles, response: roles } = useFetch("get");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const watchSpecificAppsRole = watch("Roles.EditorSpecificApps");
  const watchAdminRole = watch("Roles.SuperAdmin");

  useEffect(() => {
    getApplications(`Clients/GetClientsForDropdown`);
    getRoles(`Users/GetSystemRoles`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredList = applications?.filter((app: any) => {
      return member?.applications?.includes(app.clientId);
    });

    const dataObj = {
      ...member,
      userId: member?.userId,
      applications: filteredList,
      firstName: editMode ? member?.firstAndLastName[0] : member?.firstName,
      lastName: editMode ? member?.firstAndLastName[1] : member?.lastName,
      Roles: {
        EditorSpecificApps: member?.roles?.includes("EditorSpecificApps"),
        EditorUsers: member?.roles?.includes("EditorUsers"),
        SuperAdmin: member?.roles?.includes("SuperAdmin"),
      },
    };

    if (editMode) reset(dataObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, applications]);

  const closeModal = () => {
    toggle();
    if (!editMode) reset();
  };

  const manageMember: SubmitHandler<any> = (
    member: IInvitedMember & IMember
  ) => {
    const applicationIds: string[] = [];
    const roleNames: string[] = [];
    const roleIds: string[] = [];

    member?.applications?.forEach((item) => applicationIds.push(item.clientId));

    let filteredSelectedRoles = Object.keys(member?.Roles).filter(
      (k) => member?.Roles[k]
    );

    for (let i = 0; i < Object.values(filteredSelectedRoles).length; i++) {
      roleNames.push(filteredSelectedRoles[i]);
    }

    roles?.filter((role: { roleName: string; roleId: string }) => {
      return roleNames.map(
        (roleName) => role.roleName === roleName && roleIds.push(role?.roleId)
      );
    });

    const memberObj = {
      ...member,
      firstName: editMode ? member?.firstAndLastName[0] : member?.firstName,
      lastName: editMode ? member?.firstAndLastName[1] : member?.lastName,
      roleIds,
      applicationIds,
      roles: roleIds,
      applications: applicationIds,
    };

    delete memberObj.Roles;

    const rolesValidity = Object.values(member?.Roles).every(
      (item) => item === undefined || item === false
    );

    if (rolesValidity) {
      toast.error("One of the roles should be selected!");
      return;
    }

    if (editMode) {
      editInvitation(
        `Users/EditMember`,
        memberObj,
        (response) => {
          toggle();
          const editedMemberList = listView?.data?.map((member: IMember) =>
            member.userId === response?.userId
              ? Object.assign(response)
              : member
          );

          setListView({
            ...listView,
            data: editedMemberList,
          });

          toast.success("User role edited successfully!");
        },
        (error) => {
          toast.error(error?.response?.data[0]);
        }
      );
    } else {
      sendMemberInvitation(
        `Users/SendMemberInvitation`,
        memberObj,
        (response) => {
          toggle();
          toast.success("Invitation successfully sent!");
          setListView({
            ...listView,
            data: [...listView.data, response],
            totalCount: listView?.data?.length + 1,
          });

          setActiveView(
            (prev: { name: string; id: string }) =>
              (prev = {
                name: "Invitations",
                id: "invitations",
              })
          );
        },
        (error) => {
          toast.error(error?.response?.data[0]);
        }
      );
    }
  };

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      modalSize={ModalSize.md}
      title={editMode ? "Edit User Role" : "Invite New Member"}
      withFooter
      onConfirmClick={handleSubmit(manageMember)}
      confirmBtnText={editMode ? "Edit" : "Invite"}
      loading={editMode ? editInvitationLoading : loading}
      blockOutsideClick
    >
      <ManageMemberModalForm
        control={control}
        register={register}
        errors={errors}
        watchSpecificAppsRole={watchSpecificAppsRole}
        applications={applications}
        disabledInputs={editMode}
        autoFocus={!editMode}
        adminRoleChecked={watchAdminRole}
      />
    </Modal>
  );
};
