
export type CurrentLangTypes = "jsx" | "css";

export interface ILanguage {
    jsx: string;
    css: string
}

export interface ITemplate {
  name: string;
  alias?: string;
  id?: number;
  type?: string;
  icon?: string;
  updated?: string;
  updatedBy?: string;
  css?: string;
  defaultCss?: string;
  html?: string;
  defaultHtml?: string;
  previewHtml?: string;
  previewCss?: string;
  components?: ITemplate[];
}


export const templateIState = {
    name: "",
    id: 0,
    icon: "",
    updated: "",
    updatedBy: "",
    css: "",
    defaultCss: "",
    defaultHtml: "",
    html: "",
  }

  export const languageIState = {
    jsx: `() => (
        <></>
      )`,
    css: ""
  }

  export type ITemplateTypes = "default" | "custom"