import { FC } from "react";
import { NavLink } from "react-router-dom";
import { cx } from "../../utils";

interface IState {
  pathname: string;
  state: object;
}

interface ITabItem {
  path?: string;
  statePath?: IState;
  label: string;
  className?: string;
}

export const Tab: FC<ITabItem> = ({ path, label, statePath, className }) => {
  return (
    <div className={cx(["mr-6 sm:mr-8 text-gray-700 inline-flex", className])}>
      <NavLink
        to={!!statePath ? statePath : path || ""}
        className={({ isActive }) =>
          cx([
            "inline-block pb-4",
            "text-sm leading-4 font-medium",
            "hover:text-primary-mainText",
            !isActive && "text-primary-secText ",
            isActive && "border-primary border-b-2 text-primary-mainText",
          ])
        }
      >
        {label}
      </NavLink>
    </div>
  );
};
