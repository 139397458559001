import { FC, useState } from "react";
import { cx } from "../../utils";

interface ISeeMore {
  fullText: string;
  className?: string;
  breakpoint?: number;
}

export const SeeMore: FC<ISeeMore> = ({
  fullText,
  className,
  breakpoint = 50,
}) => {
  const [showFullText, setShowFullText] = useState(
    fullText.length <= breakpoint
  );

  const truncatedText = fullText.slice(0, breakpoint) + "...";

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div>
      {showFullText ? (
        <p className={cx(["text-primary-mainText max-w-md", className])}>
          {fullText}
          {fullText.length > breakpoint && (
            <span
              onClick={toggleFullText}
              className="text-sm font-medium underline text-primary-secText ml-1 cursor-pointer"
            >
              see less
            </span>
          )}
        </p>
      ) : (
        <p className={cx(["text-primary-mainText max-w-md", className])}>
          {truncatedText}
          <span
            onClick={toggleFullText}
            className="text-sm font-medium underline text-primary-secText ml-1 cursor-pointer"
          >
            see more
          </span>
        </p>
      )}
    </div>
  );
};
