import { toast } from "react-toastify";

export function handleErrors(errors: any) {
    const errs = errors?.response?.data?.Errors;
    const errsDefault = errors?.response?.data?.errors?.default;

    if (errs)
      Object.values(errs)?.forEach((val: any) => toast.error(val[0]));

    if (errsDefault)
      Object.values(errsDefault)?.forEach((val: any) => toast.error(val));
}