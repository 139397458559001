import { useState, useEffect } from "react";

import Select, { components } from "react-select";
import { AssignUserSelectOption } from ".";

import { Modal } from "../../../../components";
import { useDebounce, useFetch } from "../../../../hooks";
import { IUser } from "../../../../interfaces";
import { reactSelectStyle } from "../../../../utils";

interface IAssignUserModal {
  visible: boolean;
  toggle: () => void;
  role: IRole;
  passSelectedUsers: any;
  loading?: boolean;
}

interface IRole {
  id?: string;
  name: string;
}

export const AssignUserModal: React.FC<IAssignUserModal> = ({
  visible,
  toggle,
  passSelectedUsers,
  loading,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [noOptionText, setNoOptionText] = useState<string>("Search users...");
  const [users, setUsers] = useState<any>();
  const { apiCall: getUsers, loading: getUsersLoading } = useFetch("get");

  const handleSelectValue = (e: any) => {
    setSelectedUsers(e);
    setSearchTerm("");
  };

  const handleInputChange = (e: any) => {
    setSearchTerm(e);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (searchTerm !== "") {
      getUsers(
        `Users?searchText=${searchTerm}&page=1&pageSize=10`,
        {},
        (response) => {
          const usersArr: any = [];

          response?.users?.map((user: IUser) => {
            return usersArr.push({
              id: user.id,
              fullName: user.firstName + " " + user.lastName,
              email: user.email,
              firstName: user.firstName,
              picture: user?.picture,
            });
          });

          setUsers(usersArr);
        }
      );
      if (users?.length < -1) setNoOptionText("User not found");
    } else {
      setUsers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const passUsers = () => {
    if (selectedUsers.length > 0) {
      passSelectedUsers(selectedUsers);
    }
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">{noOptionText}</span>
      </components.NoOptionsMessage>
    );
  };

  const MuliValueLabelOption = (props: any) => {
    const { data } = props;

    return (
      <h6 className="text-sm py-1 px-2" title={data.fullName}>
        {data.email}
      </h6>
    );
  };

  return (
    <Modal
      visible={visible}
      hide={toggle}
      confirmBtnText="Assign"
      title="Assign Users"
      onConfirmClick={passUsers}
      loading={loading}
      withFooter
      blockOutsideClick
    >
      <Select
        components={{
          Option: AssignUserSelectOption,
          NoOptionsMessage: NoOptionsMessage,
          MultiValueLabel: MuliValueLabelOption,
        }}
        isLoading={getUsersLoading}
        isMulti
        options={users}
        getOptionLabel={(x) => x.fullName}
        getOptionValue={(x) => x.id}
        isSearchable
        menuPortalTarget={document.body}
        onChange={handleSelectValue}
        onInputChange={handleInputChange}
        className="text-sm"
        classNamePrefix="porta-react-select"
        placeholder="Search..."
        styles={reactSelectStyle}
      />
    </Modal>
  );
};
