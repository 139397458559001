import { FC, useState } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import {
  ArrowPosition,
  BtnSize,
  BtnType,
  Button,
  SectionTitle,
  Tooltip,
  TooltipPosition,
} from "../../components";
import { cx, primaryBtnClasses } from "../../utils";
import { Themes } from "./theme-builder/Themes";

export const DomainUIBuilder: FC = () => {
  const [activeModal, setActiveModal] = useState("");
  return (
    <>
      <div className="flex mt-6 mb-6">
        <SectionTitle noMargin>UI Builder</SectionTitle>
        <div className="relative group inline-block ml-2">
          <Tooltip
            text="When a scan is requested, your domain will be scanned for cookies and a scan report will be generated and available on the list below. You may request 3 scans in a 30-day period. Please be aware that an automatic cookie scan is performed once every day."
            innerClassName="mb-0 w-96 z-50"
            className=" text-sm text-primary-secText w-7"
            tooltipPosition={TooltipPosition.underTooltip}
            arrowPosition={ArrowPosition.top}
            bottom="-bottom-28"
            >
            <Icon name="Info" width={7} />
          </Tooltip>
        </div>
      </div>
      <Button
        btnType={BtnType.primary}
        btnSize={BtnSize.normal}
        className={cx([
          "flex items-center whitespace-nowrap mr-4 mt-6",
          primaryBtnClasses,
        ])}
        onClick={() => setActiveModal("create-template")}>
        <Icon name="Plus" width={7} />
        <span>New Template</span>
      </Button>
      <Themes activeModal={activeModal} setActiveModal={setActiveModal} />
    </>
  );
};
