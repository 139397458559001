import { FC } from "react";

import { cx } from "../../utils";

interface IArrange {
  className?: string;
}

export const Arrange: FC<IArrange> = ({ className }) => {
  return (
    <span className={cx(["w-4 h-4", className])}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-current"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H6C7.24264 1.5 8.25 2.50736 8.25 3.75V6C8.25 7.24264 7.24264 8.25 6 8.25H3.75C2.50736 8.25 1.5 7.24264 1.5 6V3.75ZM3.75 2.625H6C6.62132 2.625 7.125 3.12868 7.125 3.75V6C7.125 6.62132 6.62132 7.125 6 7.125H3.75C3.12868 7.125 2.625 6.62132 2.625 6V3.75C2.625 3.12868 3.12868 2.625 3.75 2.625Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75 3.75C9.75 2.50736 10.7574 1.5 12 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V6C16.5 7.24264 15.4926 8.25 14.25 8.25H12C10.7574 8.25 9.75 7.24264 9.75 6V3.75ZM12 2.625H14.25C14.8713 2.625 15.375 3.12868 15.375 3.75V6C15.375 6.62132 14.8713 7.125 14.25 7.125H12C11.3787 7.125 10.875 6.62132 10.875 6V3.75C10.875 3.12868 11.3787 2.625 12 2.625Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 9.75C2.50736 9.75 1.5 10.7574 1.5 12V14.25C1.5 15.4926 2.50736 16.5 3.75 16.5H6C7.24264 16.5 8.25 15.4926 8.25 14.25V12C8.25 10.7574 7.24264 9.75 6 9.75H3.75ZM6 10.875H3.75C3.12868 10.875 2.625 11.3787 2.625 12V14.25C2.625 14.8713 3.12868 15.375 3.75 15.375H6C6.62132 15.375 7.125 14.8713 7.125 14.25V12C7.125 11.3787 6.62132 10.875 6 10.875Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75 12C9.75 10.7574 10.7574 9.75 12 9.75H14.25C15.4926 9.75 16.5 10.7574 16.5 12V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H12C10.7574 16.5 9.75 15.4926 9.75 14.25V12ZM12 10.875H14.25C14.8713 10.875 15.375 11.3787 15.375 12V14.25C15.375 14.8713 14.8713 15.375 14.25 15.375H12C11.3787 15.375 10.875 14.8713 10.875 14.25V12C10.875 11.3787 11.3787 10.875 12 10.875Z"
        />
      </svg>
    </span>
  );
};
