import { FC } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import {
  FeedbackEmojis,
  FormControl,
  Label,
  Modal,
  RadioButton,
  Textarea,
} from "..";
import { ModalSize } from "../../components/modal";
import { getUserInfo, textareaClasses } from "../../utils";
import { useFetch } from "../../hooks";
import { toast } from "react-toastify";

interface IFeedbackModal {
  toggle: () => void;
  visible: boolean;
}

interface IFeedbackDataPayload {
  userFirstName: string;
  userLastName: string;
  email: string;
  satisfaction: string;
  comment: string;
  canContactUser: string;
  environment: string;
}

export const FeedbackModal: FC<IFeedbackModal> = ({ toggle, visible }) => {
  const { apiCall: sendFeedback, loading } = useFetch("post");
  const {
    profile: { email, given_name, family_name },
  } = getUserInfo();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const onFeedbackSend: SubmitHandler<any> = (data: IFeedbackDataPayload) => {
    const feedbackObj = {
      ...data,
      environment,
      email,
      userFirstName: given_name,
      userLastName: family_name,
      canContactUser: data.canContactUser === "yes" ? true : false,
    };
    sendFeedback(
      `Feedback/SendFeedback`,
      feedbackObj,
      () => {
        toast.success("Your feedback sent sucessfully! Thank you!");
        toggle();
      },
      (err) => {
        const errorResponse = err.response.data;
        if (errorResponse.errors.Comment) {
          toast.error(errorResponse.errors.Comment[0]);
        } else {
          toast.error("Something went wrong");
        }
      }
    );
  };

  return (
    <Modal
      visible={visible}
      hide={toggle}
      title="Share your feedback"
      onConfirmClick={handleSubmit(onFeedbackSend)}
      confirmBtnText="Submit"
      confirmBtnType="submit"
      withFooter
      blockOutsideClick
      loading={loading}
      disabled={!isDirty}
      modalSize={ModalSize.md}
    >
      <FormControl>
        <Label text="How satisfied are you with Porta?" required />

        <FeedbackEmojis register={register} errors={errors} />
      </FormControl>

      <FormControl>
        <Label text="Please tell us how we can improve" />
        <Textarea
          {...register("comment")}
          className={textareaClasses}
          maxLength={3000}
        />
      </FormControl>

      <FormControl lastChild>
        <Label text="Porta may contact me about my feedback" />

        <div className="flex">
          <RadioButton
            id="contact-feedback-yes"
            label="Yes"
            value="yes"
            wrapperClassName="mr-4"
            {...register("canContactUser")}
          />

          <RadioButton
            id="contact-feedback-no"
            label="No"
            value="no"
            {...register("canContactUser")}
          />
        </div>
      </FormControl>
    </Modal>
  );
};
