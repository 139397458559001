
interface ISEO {
    title: string;
    metaDescription?: string
}

export const SEO = (data: ISEO = {title: "", metaDescription: ""}) => {
    data.title = data.title || 'Porta - Dashboard';
    data.metaDescription = data.metaDescription || '';

    document.title = data.title;
    document?.querySelector('meta[name="description"]')?.setAttribute('content', data.metaDescription);
  }