import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { IApiScope } from "../../../interfaces";
import { Loader, Modal } from "../../../components";
import { ModalSize } from "../../../components/modal";
import { API_SCOPES_PATH } from "../../../constants";
import { useFetch, useToggle } from "../../../hooks";
import { IdentityResourceForm } from "./components";
import { SEO } from "../../../utils";

export const IdentityResource = () => {
  const { toggle, visible } = useToggle();
  const navigate = useNavigate();
  let { id } = useParams();

  const [claims, setClaims] = useState<string[]>([]);
  const {
    apiCall: getIdentityResource,
    loading,
    response: iResource,
  } = useFetch("get");
  const {
    apiCall: editIdentityResource,
    loading: editIdentityResourceLoading,
  } = useFetch("put");
  const {
    apiCall: deleteIdentityResource,
    loading: deleteIdentityResourceLoading,
  } = useFetch("delete");

  const { name, userClaims } = !!iResource && iResource;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta - Identity Resource",
    });
  }, []);

  useEffect(() => {
    if (!!id)
      getIdentityResource(`/IdentityResources/${id}`, {}, (response) => {
        reset(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setClaims(userClaims);
  }, [userClaims]);

  const onEditIdentityResource: SubmitHandler<any> = (data: IApiScope) => {
    const iResourceData = {
      ...data,
      name: data.name.trim(),
      userClaims: claims,
    };

    editIdentityResource(
      `/IdentityResources`,
      iResourceData,
      () => {
        toast.success("Identity Resource saved successfully!");
      },
      (error) => {
        const errRes = error?.response?.data;
        toast.error(errRes);

        if (errRes?.errors?.Name) {
          toast.error(errRes?.errors?.Name[0]);
        }

        if (errRes?.errors[`Identity Resource {0} already exists`]) {
          toast.error(
            error?.response?.data?.errors[
              `Identity Resource {0} already exists`
            ][0]
          );
        }
      }
    );
  };

  const onDeleteIdentityResource = () => {
    deleteIdentityResource(`/IdentityResources/${id}`, {}, () => {
      toast.success("Identity Resource deleted successfully!");
      navigate(API_SCOPES_PATH);
      toggle();
    });
  };

  if (loading) {
    return <Loader />;
  }

  const props = {
    onSubmit: handleSubmit(onEditIdentityResource),
    claims,
    setClaims,
    iResource,
    id,
    toggle,
    register,
    control,
    errors,
    editMode: true,
    loading: editIdentityResourceLoading,
  };

  return (
    <>
      <IdentityResourceForm {...props} />

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          title="Delete Identity Resource"
          onConfirmClick={onDeleteIdentityResource}
          confirmBtnText="Delete"
          withFooter
          warningModal
          blockOutsideClick
          loading={deleteIdentityResourceLoading}
          modalSize={ModalSize.sm}
        >
          <p className="text-sm text-gray-700">
            Are you sure you want to delete <strong>{name}</strong>?
          </p>
        </Modal>
      )}
    </>
  );
};
