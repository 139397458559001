import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import WebFont from "webfontloader";

import reportWebVitals from "./reportWebVitals";
import { setupAxios, history } from "./utils";
import { BrowserRouter } from "./router/BrowserRouter";

setupAxios(history);

WebFont.load({
  google: {
    families: ["Inter:200,300,400,500,600"],
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>
);

reportWebVitals();
