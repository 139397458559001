import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";

import { Input, InputSize, Label } from "../../../../../components";
import { cx, inputClasses, reactSelectStyle } from "../../../../../utils";
import { TemplateCheckbox } from "./TemplateCheckbox";

export const Generals: FC = () => {
  // const {
  //   formState: { errors },
  // } = useForm();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const fontFamilies = [
    { name: "Arial", value: "arial" },
    { name: "Inter", value: "inter" },
    { name: "Times New Roman", value: "times-new-roman" },
  ];

  const consentCheckboxData = [
    { name: "Show Close [X] Icon", value: "showCloseButton" },
    { name: "Show Language (Globe) Icon", value: "showLangGlobe" },
    { name: "Show DNSMPI Toggle", value: "showDNSMD" },
    { name: "Show Brand Logo", value: "showBrandLogo" },
  ];

  // const onSubmitHandler: SubmitHandler<any> = (data) => console.log(data);

  return (
    <>
      {/* <FontFamilies /> */}
      <>
        {consentCheckboxData?.map((item: any, index: number) => (
          <TemplateCheckbox
            key={index}
            label={item.name}
            id={item.value}
            register={register}
            name={item.value}
            wrapperClassName="mb-0"
          />
        ))}
      </>
      <>
        <Input
          {...register("brandLogoURL")}
          className={cx([inputClasses, "w-full rouded"])}
          type="url"
          // maxLength={100}
          error={
            (!!errors.brandLogoURL && (
              <em>URL must follow pattern: https://www.abc.com</em>
            )) ||
            ""
          }
          inputSize={InputSize.sm}
          placeholder="Please enter your brand logo URL"
          labelProps={{
            children: <Label required text="Brand Logo:" />,
            className: "mb-1.5 block text-primary-secText",
          }}
        />
      </>

      {/* //TODO FONT FAMILY */}
      <div className="flex flex-wrap flex-col">
        <Controller
          control={control}
          name="fontFamily"
          render={({ field: { onChange, value, ref } }) => (
            <Select
              onChange={onChange}
              options={fontFamilies}
              getOptionLabel={(x) => x.name}
              getOptionValue={(x) => x.value}
              value={value || "inter"}
              isSearchable
              ref={ref}
              classNamePrefix="porta-react-select"
              menuPortalTarget={document.body}
              className="flex-1 text-sm mb-1 w-full sm:mt-0 mt-2"
              styles={reactSelectStyle}
            />
          )}
        />
      </div>
    </>
  );
};
