import { FC, useEffect, useState } from "react";
import { AppDataTable } from "../../../components";
import { IExtraProp } from "../../../components/applications/interface";
import { I_RES_PATH } from "../../../constants";
import { SEO } from "../../../utils";

export const IdentityResources: FC = () => {
  const [extraProps, setExtraProps] = useState<IExtraProp>({
    searchTerm: "",
    itemsCount: 0,
  });

  const { searchTerm, itemsCount } = extraProps;

  useEffect(() => {
    SEO({
      title: "Porta - Identity Resources",
    });
  }, []);

  const props = {
    endpoint: "/IdentityResources",
    itemsKey: "identityResources",
    addButtonLabel: "New Identity Resource",
    deleteSuccessMessage: "Identity Resource deleted successfully!",
    emptyViewMessage: `${
      searchTerm !== "" && itemsCount < 1
        ? "No identity resource found."
        : "There are no identity resources yet"
    }`,
    path: I_RES_PATH,
    searchKey: "searchText",
    pageTitle: "Identity Resources",
    pageDescription:
      "Create Identity Resources to use as a named group of claims about a user so it can be requested using the scope parameter.",
    deleteModalTitle: "Delete Identity Resource",
  };

  return <AppDataTable {...props} setExtraProps={setExtraProps} />;
};
