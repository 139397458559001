import { FC } from "react";

import {
  FormControl,
  Label,
  Input,
  InputSize,
  BtnSize,
  BtnType,
  Button,
} from "../../../components";
import { IReactHookForm } from "../../../interfaces";
import { cx, inputClasses, primaryBtnClasses } from "../../../utils";
import { Spinner, SpinnerSize } from "../../ui/Spinner";

export interface INewProperyForm extends IReactHookForm {
  onSubmit: () => void;
  className?: string;
  loading?: boolean;
}

export const NewProperyForm: FC<INewProperyForm> = ({
  onSubmit,
  register,
  errors,
  className,
  loading,
}) => {
  return (
    <>
      <form className={className && className}>
        <FormControl>
          <Label required text="Key" />
          <Input
            {...register("key", { required: "Property key is required" })}
            error={!!errors.key && errors.key.message}
            inputSize={InputSize.sm}
            className={inputClasses}
            autoFocus
          />
        </FormControl>

        <FormControl>
          <Label required text="Value" />
          <Input
            {...register("value", { required: "Property value is required" })}
            error={!!errors.value && errors.value.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <Button
          onClick={onSubmit}
          type="button"
          btnSize={BtnSize.normal}
          btnType={BtnType.primary}
          disabled={loading}
          className={cx(["flex items-center", primaryBtnClasses])}
        >
          {loading && (
            <Spinner
              size={SpinnerSize.xs}
              color="text-white"
              padding="p-0 mr-2"
            />
          )}
          Add Property
        </Button>
      </form>
    </>
  );
};
