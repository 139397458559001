import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  BackButton,
  Container,
  FormFooter,
  PageHeadeline,
} from "../../components";
import {
  MODELS_PATH,
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS,
} from "../../constants";
import { useFetch } from "../../hooks";
import { ModelActions, ModelMainInfo, ModelProperties } from "./components";
import {
  actionIState,
  ICreateModel,
  IExpectedStatusCode,
  propertiesIState,
} from "./interface";
import { handleErrors } from "./utils/handle-errors";

export const ModelCreate = () => {
  const navigate = useNavigate();
  const { apiCall: createModel, loading } = useFetch("post");
  const methods = useForm({
    defaultValues: {
      alias: "",
      name: "",
      iconField: "",
      description: "",
      properties: propertiesIState,
      actions: [actionIState],
    },
  });

  const { handleSubmit } = methods;

  const handleSubmitForm: SubmitHandler<any> = (data: ICreateModel) => {
    const actions = data?.actions?.map((action) => {
      const visibilitySettings = action?.visibilitySettings?.map(
        (vbSettings) => {
          return {
            propertyName: vbSettings?.property?.name,
            operator: vbSettings.operator,
            value: vbSettings.value,
          };
        }
      );

      const outputs = action?.outputs?.map((output) => {
        return {
          propertyName: output?.property?.name,
          value: output.value,
        };
      });

      const expectedSuccessStatusCodes =
        action?.expectedSuccessStatusCodes?.map(
          (statusCode: IExpectedStatusCode) => statusCode.value
        );

      return {
        ...action,
        methodType: action?.methodType?.value,
        headers: action?.actionType.id === 2 ? action?.headers : null,
        visibilitySettings: action?.showVisibilitySettings
          ? visibilitySettings
          : null,
        outputs: action?.addActionOutput ? outputs : null,
        expectedSuccessStatusCodes,
      };
    });

    data?.properties.splice(0, 1);

    const modelObject = {
      icon: data?.icon,
      name: data?.name,
      description: data?.description,
      modelId: data?.modelId,
      alias: data?.alias,
      body: {
        actions,
        properties: data?.properties,
      },
    };

    createModel(
      `${USER_PROFILE_API}/integration-models`,
      modelObject,
      () => {
        toast.success("Model created successfully!");
        navigate(MODELS_PATH);
      },
      (err) => {
        handleErrors(err);
      },
      USER_PROFILE_API_HEADERS
    );
  };

  return (
    <Container>
      <div>
        <BackButton to={MODELS_PATH} className="mb-4" label="Back to Models" />

        <PageHeadeline
          title="Models"
          description="Create a custom model to bring in data from an external source that you wish to display in the users' profiles."
          className="mb-10"
        />
      </div>

      <form action="">
        <FormProvider {...methods}>
          <ModelMainInfo />

          <p className="mt-9 pb-3 border-b border-primary-stroke text-primary-secText text-m">
            Model Properties
          </p>

          <ModelProperties />

          <p className="mt-9 pb-3 border-b border-primary-stroke text-primary-secText">
            Model Actions
          </p>

          <ModelActions />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit(handleSubmitForm)}
          loading={loading}
        />
      </form>
    </Container>
  );
};
