import { useNavigate } from "react-router-dom";
import { BtnSize, BtnStyle, BtnType, Button } from "../../components/button";
import { cancelBtnClasses, cx, primaryBtnClasses } from "../../utils";
import { Spinner, SpinnerSize } from "../ui/Spinner";

interface IFormFooter {
  className?: string;
  onSubmit: (e: any) => void;
  disabled?: boolean;
  cancelDisabled?: boolean;
  optionalBtnLabelText?: string;
  pb?: string;
  pt?: string;
  loading?: boolean;
  noCancelButton?: boolean;
  hasOptionalButton?: boolean;
  submitBtnType?: "button" | "submit";
  onOptionalButtonClick?: () => void;
  containerSize?: string;
  mtAuto?: boolean;
  saveBtnText?: string;
  backToLink?: string;
}

export const FormFooter: React.FC<IFormFooter> = ({
  className,
  onSubmit,
  disabled,
  hasOptionalButton,
  optionalBtnLabelText,
  cancelDisabled,
  submitBtnType = "button",
  loading,
  containerSize,
  pb,
  pt,
  noCancelButton,
  onOptionalButtonClick,
  mtAuto,
  saveBtnText,
  backToLink,
}) => {
  const navigate = useNavigate();

  const backTo = () => {
    backToLink ? navigate(backToLink) : navigate(-1);
  };

  return (
    <div
      className={cx([
        "border-t border-primary-stroke text-right z-10 flex justify-end mx-auto",
        !pt && "pt-4 sm:pt-6",
        !pb && "pb-4 sm:pb-6",
        !mtAuto ? "sm:mt-24  mt-auto" : " mt-auto",
        className,
        containerSize,
      ])}
    >
      {!noCancelButton && (
        <Button
          type="button"
          btnType={BtnType.secondary}
          btnStyle={BtnStyle.neutral}
          btnSize={BtnSize.normal}
          onClick={backTo}
          disabled={cancelDisabled}
          className={cancelBtnClasses}
        >
          Cancel
        </Button>
      )}

      {hasOptionalButton && (
        <Button
          type="button"
          btnType={BtnType.secondary}
          btnStyle={BtnStyle.neutral}
          btnSize={BtnSize.normal}
          onClick={onOptionalButtonClick}
          className={cx([cancelBtnClasses, "ml-4"])}
        >
          {optionalBtnLabelText}
        </Button>
      )}

      <Button
        type={submitBtnType}
        onClick={onSubmit}
        btnStyle={BtnStyle.primary}
        btnSize={BtnSize.normal}
        className={cx(["ml-4 flex items-center", primaryBtnClasses])}
        disabled={disabled || loading}
      >
        {loading && (
          <Spinner
            className="mr-2"
            size={SpinnerSize.xs}
            color="text-white"
            padding="p-0"
          />
        )}
        {saveBtnText ? saveBtnText : "Save"}
      </Button>
    </div>
  );
};
