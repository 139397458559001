import { FC } from "react";

import { IReactHookForm } from "../../interfaces";
import { Message } from "../messages";
import { Tooltip } from "../tooltip";

interface IFeedbackEmojis extends IReactHookForm {}

export const FeedbackEmojis: FC<IFeedbackEmojis> = ({ register, errors }) => {
  return (
    <div className="mt-3">
      <div className="feedback">
        <label className="relative mr-4" htmlFor="angry">
          <Tooltip text="Unsatisfied" innerClassName="mb-2">
            <input
              {...register("satisfaction", {
                required: "This field is required",
              })}
              type="radio"
              className="absolute top-0 left-0 w-full h-full hidden"
              value="Unsatisfied"
              id="angry"
            />
            <div className="angry item">
              <div>
                <svg className="eye left">
                  <use xlinkHref="#eye" />
                </svg>
                <svg className="eye right">
                  <use xlinkHref="#eye" />
                </svg>
                <svg className="mouth">
                  <use xlinkHref="#mouth" />
                </svg>
              </div>
            </div>
          </Tooltip>
        </label>

        <label className="relative mr-4" htmlFor="sad">
          <Tooltip text="Dislike" innerClassName="mb-2">
            <input
              type="radio"
              {...register("satisfaction", {
                required: "This field is required",
              })}
              className="absolute top-0 left-0 w-full h-full hidden"
              value="Dislike it"
              id="sad"
            />
            <div className="sad item">
              <div>
                <svg className="eye left">
                  <use xlinkHref="#eye" />
                </svg>
                <svg className="eye right">
                  <use xlinkHref="#eye" />
                </svg>
                <svg className="mouth">
                  <use xlinkHref="#mouth" />
                </svg>
              </div>
            </div>
          </Tooltip>
        </label>

        <label className="relative mr-4" htmlFor="ok">
          <Tooltip text="Neutral" innerClassName="mb-2">
            <input
              type="radio"
              className="absolute top-0 left-0 w-full h-full hidden"
              value="Neutral"
              id="ok"
              {...register("satisfaction", {
                required: "This field is required",
              })}
            />
            <div className="ok item">
              <div></div>
            </div>
          </Tooltip>
        </label>

        <label className="relative mr-4" htmlFor="good">
          <Tooltip text="Like" innerClassName="mb-2">
            <input
              type="radio"
              className="absolute top-0 left-0 w-full h-full hidden"
              id="good"
              value="Like it"
              {...register("satisfaction", {
                required: "This field is required",
              })}
            />
            <div className="good item">
              <div>
                <svg className="eye left">
                  <use xlinkHref="#eye" />
                </svg>
                <svg className="eye right">
                  <use xlinkHref="#eye" />
                </svg>
                <svg className="mouth">
                  <use xlinkHref="#mouth" />
                </svg>
              </div>
            </div>
          </Tooltip>
        </label>

        <label className="relative mr-4" htmlFor="happy">
          <Tooltip text="Love" innerClassName="mb-2">
            <input
              type="radio"
              value="Love it"
              className="absolute top-0 left-0 w-full h-full hidden"
              id="happy"
              {...register("satisfaction", {
                required: "This field is required",
              })}
            />
            <div className="happy item">
              <div>
                <svg className="eye left">
                  <use xlinkHref="#eye" />
                </svg>
                <svg className="eye right">
                  <use xlinkHref="#eye" />
                </svg>
              </div>
            </div>
          </Tooltip>
        </label>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
          <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 7"
          id="mouth"
        >
          <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
        </symbol>
      </svg>

      {errors.satisfaction && (
        <Message
          containerClassName="mt-2"
          message={errors.satisfaction && errors.satisfaction.message}
        />
      )}
    </div>
  );
};
