import { useEffect, useState } from "react";

import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Loader, Modal, ModalSize, Table, Td } from "../../../components";
import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import { toast } from "react-toastify";

interface IDevice {
  sessionId: string;
  deviceType: string;
}

export const UserDevices = () => {
  const [devices, setDevices] = useState<IDevice[]>();
  const [device, setDevice] = useState<IDevice>();

  const param = useParams();
  const { toggle, visible } = useToggle();
  const { handlePageChange, currentPage } = useCurrentPage();

  const {
    apiCall: getAllUserDevices,
    response,
    loading,
    errors,
  } = useFetch("get");
  const { apiCall: unlinkDevice, loading: unlinkDeviceLoading } =
    useFetch("post");

  useEffect(() => {
    getAllUserDevices(
      `Device/GetDeviceSessionsByUserIdAdmin?userId=${param?.id}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDevices(response?.activeDeviceSessions);
  }, [response]);

  const onDeviceUnlink = () => {
    unlinkDevice(
      `Device/SignOutFromDeviceSessionByUserIdAdmin?sessionId=${device?.sessionId}&userId=${param?.id}`,
      {},
      () => {
        toast.success("Device unlinked successfully!");
        toggle();

        const filteredDevices = devices?.filter(
          (item) => item.sessionId !== device?.sessionId
        );

        setDevices(filteredDevices);
      }
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Device" },
          { children: "Browser" },
          { children: "Last Activity" },
          { children: "Operating System" },
          { children: "", className: "text-right" },
        ]}
        values={devices || []}
        renderTr={(el) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.sessionId}>
              <Td className="py-3 capitalize">{el?.deviceType || "Unknown"}</Td>
              <Td className="relative py-3">
                {el?.browser ? (
                  <img
                    src={`/images/browsers/${el?.browser?.toLowerCase()}.png`}
                    alt={el?.browser}
                    title={el?.browser}
                    className="w-6"
                  />
                ) : (
                  "Unknown"
                )}
              </Td>
              <Td className="py-3">
                {el?.lastActivity
                  ? format(new Date(el?.lastActivity), "MMMM dd yyyy, hh:mm:ss")
                  : ""}
              </Td>
              <Td className="relative py-3">
                {el?.operatingSystem || "Unknown"}
              </Td>
              <Td className="py-3 text-right" align="right">
                <button
                  type="button"
                  onClick={() => {
                    toggle();
                    setDevice(el);
                  }}
                  className="p-2 transition hover:bg-gray-100 rounded focus:outline-none focus:shadow-outlineGray text-warning"
                >
                  Unlink Device
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: 10,
          totalCount: 10,
          onPageChange: handlePageChange,
          page: currentPage,
          length: devices?.length,
        }}
        emptyView={{ text: errors || "No active devices yet." }}
      />

      {!!visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Are you sure you want to unlink this device?"
          onConfirmClick={onDeviceUnlink}
          confirmBtnText="Yes"
          modalSize={ModalSize.md}
          withFooter
          loading={unlinkDeviceLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            The user will be logged out of their account on this device and they
            will have to sign in again.
          </p>
        </Modal>
      )}
    </>
  );
};
