import React, { ReactElement, ReactNode } from "react";
import { Animation } from "../../components";
import { SidebarMenu, SidebarMenuProps } from "./SidebarMenu";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

export interface ItemProps extends SidebarItemProps {
  nestedList?: SidebarItemProps[];
}

export interface SidebarItemProps {
  to?: string;
  icon?: ReactElement;
  title: string;
  depth?: number;
  arrowIcon?: ReactNode;
  openList?: string | null;
  setOpenList?: (val: string | null) => void;
  nestedList?: ItemProps[];
  renderItem?: SidebarMenuProps["renderItem"];
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  title,
  nestedList,
  depth,
  openList,
  setOpenList,
  to,
  arrowIcon = <Icon name="ArrowRight" width={16} />,
  renderItem,
}) => {
  const _onClick = () => {
    if (setOpenList)
      if (openList === title) setOpenList(null);
      else setOpenList(title);
  };
  if (nestedList)
    return (
      <li className={cx(["select-none text-sm mt-1 mx-4"])}>
        <button
          className={cx([
            // openList === title ? "shadow-outlinePrimary" : "",
            "flex w-full items-center focus:outline-none text-primary-secText font-medium justify-between focus:shadow-outlinePrimary active:shadow-outlinePrimary p-2.5 transform-gpu transition duration-300 hover:text-primary rounded",
          ])}
          style={{ willChange: "transform" }}
          onClick={_onClick}
          type="button"
        >
          <div className="flex">
            {icon ? (
              <span className="w-5 h-5" onClick={_onClick} role="button">
                {icon}
              </span>
            ) : null}
            <span className={cx(["ml-4 flex-1", !icon && "pl-12 "])}>
              {title}
            </span>
          </div>
          <span
            className={`transition transform-gpu w-4 ${
              openList !== title
                ? "rotate-0"
                : `${depth && depth >= 1 ? "-rotate-90" : "rotate-90"}`
            }`}
          >
            {arrowIcon}
          </span>
        </button>

        <Animation type="height" show={openList === title} duration={300}>
          <div>
            <SidebarMenu
              list={nestedList}
              depth={(depth || 0) + 1}
              renderItem={renderItem}
              arrowIcon={<Icon name="ArrowUp" />}
            />
          </div>
        </Animation>
      </li>
    );
  if (to) {
    return (
      <li className={cx(["mt-0.5 mx-4", !nestedList && "stand-alone-item"])}>
        {renderItem && renderItem({ to, icon, nestedList, title, depth })}
      </li>
    );
  } else return null;
};
