import { LabelHTMLAttributes } from "react";
import { cx } from "../../utils";

interface ILabel extends LabelHTMLAttributes<HTMLLabelElement> {
  text: string;
  required?: boolean;
}

export const Label: React.FC<ILabel> = (props) => {
  return (
    <label
      {...props}
      className={cx(["text-primary-secText text-sm block pb-0 mb-1.5"])}
    >
      {props.text} {props.required && <span>*</span>}
    </label>
  );
};
