import { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  ToggleSwitch,
  Input,
  InputSize,
  Label,
} from "../../../../components";
import { IApplication } from "../../../../interfaces";
import { inputClasses } from "../../../../utils";

export const Consent: FC = () => {
  const { register, control } = useFormContext<IApplication>();

  return (
    <>
      <FormControl>
        <Controller
          control={control}
          name="requireConsent"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="require-consent"
              label="Require Consent"
              description="Specifies whether a consent screen is required. Defaults to true."
              onChange={onChange}
              checked={value}
              inputRef={ref}
              value={value || ""}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="allowRememberConsent"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="remember-consent"
              label="Allow Remember Consent"
              description="Specifies whether the user can choose to store consent decisions. Defaults to true."
              onChange={onChange}
              checked={value}
              inputRef={ref}
              value={value || ""}
            />
          )}
        />
      </FormControl>

      <FormControl description="URI to further information about client (used on consent screen)">
        <Label text="Application URL" htmlFor="app-url" />
        <Input
          id="app-url"
          {...register("clientUri")}
          inputSize={InputSize.sm}
          className={inputClasses}
        />
      </FormControl>

      <FormControl description="URI to client logo (used on consent screen)">
        <Label text="Logo URL" htmlFor="logo-url" />
        <Input
          id="logo-url"
          {...register("logoUri")}
          inputSize={InputSize.sm}
          className={inputClasses}
        />
      </FormControl>
    </>
  );
};
