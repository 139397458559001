import { FC, useState, useEffect } from "react";

import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch } from "../../hooks";
import { IScan, IScansAll } from "../../interfaces";
import {
  cx,
  // getUserInfo,
  SEO,
} from "../../utils";
// import { Dropdown } from "../../pages/clients/api-resources/components";
import {
  // DropdownOption,
  // ThreeDotsButton,
  Table,
  Td,
  Button,
  SectionTitle,
  Tooltip,
  TooltipPosition,
  ArrowPosition,
} from "../../components";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import {
  InlineStatus,
  ScanStatusProps,
} from "../../components/ui/InlineStatus";
import { useDomainContext } from "../../context";
import {
  CMP_API_URL,
  // CMP_CRAWLER_URL,
} from "../../constants";
import InfoFillPrimary from "../../components/Icons/InfoFillPrimary";

export const DomainCookieScan: FC = () => {
  const { domain } = useDomainContext();
  const { encryptedId, id } = !!domain && domain;
  const [initiateScan, setInitiateScan] = useState<boolean>(false);

  const PER_PAGE = 10;
  const { currentPage, handlePageChange } = useCurrentPage();

  const {
    apiCall: getScans,
    response: scans,
    apiCall: getRescan,
    loading,
  } = useFetch("get");
  const { crawlerScans, pagingInfo } = !!scans && scans;

  useEffect(() => {
    SEO({
      title: "Porta - Domain Cookie Scan",
    });
    if (encryptedId) {
      getScans(
        `${CMP_API_URL}/GetAllCrawlerSCansByDomainId?domainId=${encryptedId}&page=${currentPage}&pageSize=${PER_PAGE}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onRescanRequest = () => {
    if (id) {
      setInitiateScan((prevState) => (prevState = true));
      getRescan(`${CMP_API_URL}/RequestScanDomainById?domainId=${id}`);
      toast.warning("Scan Initiated...");

      setTimeout(() => {
        setInitiateScan((prevState) => (prevState = false));
      }, 4000);
    }
  };

  const checkScanStatus = (statusCode: number) => {
    let scanStatus = "";
    let scanMessage;
    switch (statusCode) {
      case 1:
        scanMessage = "Completed";
        scanStatus = ScanStatusProps.completed;
        return { scanStatus, scanMessage };
      case 2:
        scanMessage = "Completed";
        scanStatus = ScanStatusProps.completed;
        return { scanStatus, scanMessage };
      case 3:
        scanMessage = "Failed";
        scanStatus = ScanStatusProps.failed;
        return { scanStatus, scanMessage };
      case 4:
        scanMessage = "Failed";
        scanStatus = ScanStatusProps.failed;
        return { scanStatus, scanMessage };
      case 5:
        scanMessage = "Scanning";
        scanStatus = ScanStatusProps.scanning;
        return { scanStatus, scanMessage };

      default:
        return { scanStatus, scanMessage };
    }
  };

  return (
    <>
      <div className="flex mt-6 mb-6">
        <SectionTitle noMargin>Cookie Scan</SectionTitle>
        <div className="relative group inline-block ml-2 ">
          <Tooltip
            text="Porta CMP performs deep scans of your website to detect all cookies and tracking technologies. However, it is important to note that you should perform a final check to ensure that all cookies and tracking technologies have been detected."
            innerClassName="mb-0 w-96 "
            className="text-sm text-primary-secText w-7"
            tooltipPosition={TooltipPosition.underTooltip}
            arrowPosition={ArrowPosition.top}
            bottom="bottom-0">
            <Icon name="Info" width={7} />
          </Tooltip>
        </div>
      </div>
      <Button
        type="button"
        onClick={onRescanRequest}
        className={cx(["flex-1 sm:flex-none align-middle"])}>
        Request Rescan
      </Button>
      {initiateScan && (
        <div className="flex justify-between mt-8 py-2 px-4 info-section bg-primary-100 border-1 border-blue rounded-md w-full">
          <div className="inline-block pt-1">
            <InfoFillPrimary />
          </div>
          <p className="max-w-[70%] text-primary-mainText mr-auto ml-2.5">
            Your website is currently being scanned for cookies. This might take
            a few minutes to a few hours, depending on your website speed and
            the number of pages to be scanned.Please wait until the scan is
            complete to request another scan.
          </p>{" "}
          <button
            className="flex align-top"
            onClick={() => setInitiateScan((prevState) => (prevState = false))}>
            <Icon name="Close" size="20" />
          </button>
        </div>
      )}
      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-2"
        names={[
          { children: "Scan Date" },
          { children: "Nr of Cookies" },
          { children: "Nr of Pages" },
          { children: "Status", className: "text-left" },
        ]}
        values={(crawlerScans as IScansAll["scans"]) || []}
        renderTr={(el: IScan, index) => {
          // const lockoutEnd = new Date(el?.lockoutEnd) > new Date();
          const { scanMessage, scanStatus } = checkScanStatus(el?.statusEnum);
          return (
            <tr className="transition hover:bg-gray-50" key={index}>
              <Td className="py-3">
                <NavLink to="#" className="hover:text-primary transition">
                  {format(new Date(el.created), "yyyy-MM-dd, HH:mm:ss")}
                </NavLink>
              </Td>
              <Td className="relative py-3">{el?.cookiesDiscovered}</Td>
              <Td className="py-3">{el?.pagesDiscovered}</Td>
              <Td className="py-3">
                <InlineStatus
                  statusContent={scanMessage}
                  // statusContent={checkScanStatus(el?.statusEnum)}
                  scanStatus={scanStatus}
                  loading={loading}
                  // className="ml-6"
                />
              </Td>
              <Td className="text-primary-secText py-3" align="right">
                {/* <Dropdown
                  width="w-32"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        isLink
                        to={""}
                        withIcon
                        iconName="Show"
                        label="Details"
                      />
                    </>
                  }>
                  <ThreeDotsButton />
                </Dropdown> */}
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount: pagingInfo,
          onPageChange: handlePageChange,
          page: currentPage,
          length: scans?.length,
        }}
        emptyView={{ text: "No scans yet" }}
      />
    </>
  );
};
