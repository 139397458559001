import { FC, ReactElement } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { applicationsRoutes } from "./Routes";
import { Header, Sidebar } from "../components";
import {
  UserContextProvider,
  DomainContextProvider,
  ApplicationContextProvider,
  onlyForRoles,
  SystemRoles,
  Permission,
} from "../context";
import { Compose } from "../utils";
import { Page401, Page404 } from "../pages";
import { APPS_PATH, USERS_PATH } from "../constants";

export const Router: FC = (): ReactElement => {
  const notAuthorized = !onlyForRoles(SystemRoles);

  const { usersEditor, appsEditor } = Permission;
  const userHasOnlyUserEditor = onlyForRoles([usersEditor]);
  const userHasOnlyAppsEditor = onlyForRoles([appsEditor]);

  if (notAuthorized) {
    return <Page401 />;
  }

  return (
    <>
      <Header />

      <main className="flex flex-col md:flex-row md:pl-72 main-container h-full">
        <Sidebar />

        <section
          className="flex-grow min-w-0 relative mx-4 md:mx-6 my-6 sm:my-8"
          style={{ minHeight: "calc(100vh - 132px)" }}
        >
          <Compose
            components={[
              ApplicationContextProvider,
              UserContextProvider,
              DomainContextProvider,
              // TemplatesContextProvider
            ]}
          >
            <Routes>
              {applicationsRoutes.map((route, i) => {
                if (!!route?.permissions && onlyForRoles(route?.permissions)) {
                  return (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={i}
                      index={route?.index}
                    />
                  );
                }

                return null;
              })}

              {userHasOnlyAppsEditor && (
                <Route path="/" element={<Navigate to={APPS_PATH} />} />
              )}

              {userHasOnlyUserEditor && (
                <Route path="/" element={<Navigate to={USERS_PATH} />} />
              )}

              <Route path="*" element={<Page404 />} />
            </Routes>
          </Compose>
        </section>
      </main>
    </>
  );
};
