import { FC } from "react";
import { NavLink } from "react-router-dom";
import {
  BackButton,
  Paragraph,
  ParagraphSize,
  SectionTitle,
} from "../../../../components";
import { Edit } from "../../../../components/Icons";
import { MODELS_PATH } from "../../../../constants";
import { useModelDetailsContext } from "../../context/ModelDetailsContext";

export const ModelDetailsHeadline: FC = () => {
  const { model } = useModelDetailsContext();

  return (
    <div>
      <BackButton to={MODELS_PATH} className="mb-4" label="Back to Models" />

      {!model ? (
        <p>Sorry, this model does not have any details.</p>
      ) : (
        <div className="mb-10 flex justify-between items-center">
          <div>
            <SectionTitle noMargin className="flex">
              View Model Details
            </SectionTitle>
            <Paragraph parahraphSize={ParagraphSize.sm}>
              View model details to review all the configurations of the model
              properties and actions.
            </Paragraph>
          </div>
          <NavLink
            to={`${MODELS_PATH}/${model?.id}/edit`}
            className="w-10 h-10 flex items-center justify-center shadow rounded cursor-pointer hover:bg-gray-50 transition"
          >
            <Edit />
          </NavLink>
        </div>
      )}
    </div>
  );
};
