import { Dispatch, FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import Control from "react-select/src/components/Control";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  FormControl,
  Label,
  Input,
  InputSize,
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
  ModalSize,
} from "../../../../components";
import { Spinner, SpinnerSize } from "../../../../components/ui/Spinner";
import { CMP_API_URL } from "../../../../constants";
import { useDomainContext } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { IConsentStyle, IConsentStylesAll } from "../../../../interfaces";
import {
  cancelBtnClasses,
  consentStyleIState,
  cx,
  inputClasses,
  primaryBtnClasses,
  reactSelectStyle,
} from "../../../../utils";

interface IConsentStyleCreateModal {
  visible: boolean;
  toggle: () => void;
  setResponse: Dispatch<IConsentStylesAll>;
  response: IConsentStylesAll;
  template: IConsentStyle;
}

export const ConsentStyleCreateModal: FC<IConsentStyleCreateModal> = ({
  visible,
  toggle,
  setResponse,
  response,
}) => {
  const { domain } = useDomainContext();
  const [btnType, setBtnType] = useState<string>("");
  const { apiCall: createConsentStyle, loading } = useFetch("post");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    // setFocus,
    control,
    // watch,
  } = useForm();

  const bannerTypes = [
    // { name: "Banner Top", content: "top" },
    { name: "Banner Bottom", content: "bottom" },
    // { name: "Banner Left", content: "left" },
    // { name: "Banner Right", content: "right" },
    { name: "Banner Center", content: "center" },
    // { name: "Banner Top Right", content: "top-right" },
    // { name: "Banner Top Left", content: "top-left" },
    // { name: "Banner Bottom Right", content: "bottom-right" },
    { name: "Banner Bottom Left", content: "bottom-left" },
  ];

  const closeModal = () => {
    toggle();
    reset();
  };

  useEffect(() => {
    reset(consentStyleIState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateConsentStyle: any = (data: IConsentStyle | any) => {
    setBtnType(btnType);
    const consentStyleObj = {
      ...data,
      // id: uuidv4(),
      domainId: domain?.id,
      bannerName: data.bannerName,
      bannerPlacement: data?.bannerPlacement?.content,
    };
    createConsentStyle(
      `${CMP_API_URL}/AddConsentStyle`,
      consentStyleObj,
      (res) => {
        setResponse({
          ...response?.consentStyles,
          consentStyles: [
            ...response?.consentStyles,
            {
              // ...res,
              // id: uuidv4(),
              // ...template,
              ...consentStyleIState,
              ...res,
              domainId: domain?.id,
              bannerName: data?.bannerName,
              bannerPlacement: data?.bannerPlacement?.content,
            },
          ],
        });
      }
    );
    closeModal();
  };

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      modalSize={ModalSize.md}
      title="Create Template"
      blockOutsideClick
    >
      <form>
        <ModalBody>
          <FormControl>
            <Label required text="Template Name" htmlFor="template-name" />
            <Input
              id="template-name"
              autoFocus
              {...register("bannerName", {
                required: "Banner name is required",
                validate: {
                  notValidName: (value: string) =>
                    !!value.trim() || "Banner name is required",
                },
              })}
              error={!!errors.name && errors.name.message}
              inputSize={InputSize.sm}
              className={inputClasses}
              maxLength={40}
            />
          </FormControl>

          <div className="flex flex-wrap flex-col">
            <Controller
              control={control}
              name="bannerPlacement"
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  placeholder="Select Banner Position ..."
                  onChange={onChange}
                  options={bannerTypes}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.content}
                  value={value || ""}
                  isSearchable
                  ref={ref}
                  classNamePrefix="porta-react-select"
                  menuPortalTarget={document.body}
                  className="flex-1 text-sm mb-2 w-full sm:mt-0 mt-4"
                  styles={reactSelectStyle}
                />
              )}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            btnType={BtnType.secondary}
            btnStyle={BtnStyle.neutral}
            btnSize={BtnSize.normal}
            className={cancelBtnClasses}
            type="button"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="button"
            onClick={() =>
              // TODO: Should check type
              // handleSubmit((data: IUser) =>
              handleSubmit((data: any) => onCreateConsentStyle(data))()
            }
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx(["ml-4 flex items-center", primaryBtnClasses])}
            disabled={loading && btnType === "create"}
          >
            {loading && btnType === "create" && (
              <Spinner
                size={SpinnerSize.xs}
                color="text-white mr-2"
                padding="p-0"
              />
            )}
            Create
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
