import { Controller } from "react-hook-form";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import {
  Paragraph,
  ParagraphSize,
  SectionTitle,
  ToggleSwitch,
} from "../../../components";
import { cx } from "../../../utils";

interface ICategoryItem {
  className?: string;
  cat: any;
  control: any;
  index: number;
  isCustom?: boolean;
  onEditCategory?: () => void;
  onDeleteCategory?: () => void;
}

export const CategoryItem: React.FC<ICategoryItem> = ({
  cat,
  index,
  control,
  isCustom,
  onEditCategory,
  onDeleteCategory,
}) => {
  return (
    <div
      className={cx([
        "flex flex-wrap py-6 relative group",
        index === 0 && "pt-0",
      ])}
      key={cat?.encryptedId}>
      <div className="sm:w-2/5 sm:pr-6">
        <SectionTitle
          noMargin
          className="mb-4 group-hover:text-primary transition">
          {cat?.name}
        </SectionTitle>

        {!!cat?.description && (
          <div className="mb-4 sm:mb-0">
            <h6 className="text-sm text-primary-mainText mb-2">Description</h6>

            <Paragraph parahraphSize={ParagraphSize.sm}>
              {cat?.description}
            </Paragraph>
          </div>
        )}
      </div>

      <div className="sm:w-3/5">
        <h6 className="text-sm text-primary-mainText mb-4">Other Settings</h6>

        <div className="flex flex-wrap justify-between">
          <Controller
            control={control}
            defaultValue={cat.checked}
            name={`items.${index}.checked`}
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id={`is-deafult-${cat.id}`}
                label="Checked by default *"
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
                disabled={isCustom || cat?.name.toLowerCase() === "necessary"}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={cat.usedForTracking}
            name={`items.${index}.usedForTracking`}
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id={`used-for-tracking-${cat.id}`}
                label="Used for tracking (DNT)**"
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
                disabled={isCustom}
              />
            )}
          />

          <Controller
            control={control}
            defaultValue={cat.preconsent}
            name={`items.${index}.preconsent`}
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id={`preconsent-${cat.id}`}
                label="Preconsent***"
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
                disabled={isCustom}
              />
            )}
          />
        </div>
      </div>

      {!!isCustom && (
        <div
          className={cx([
            " absolute right-0",
            index === 0 ? "-top-1" : "top-4",
          ])}>
          <button
            type="button"
            onClick={onDeleteCategory}
            className="text-primary-secText hover:text-primary"
            title="Delete">
            <span className="inline-block w-5 h-5">
              <Icon name="Delete" size={22} />
            </span>
          </button>

          <button
            type="button"
            onClick={onEditCategory}
            className="text-primary-secText hover:text-primary ml-6"
            title="Edit">
            <span className="inline-block w-5 h-5">
              <Icon name="Edit" size={22} />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
