import { FC, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import {
  Card,
  CardSpace,
  CardStyle,
  PageHeadeline,
  Paragraph,
  ParagraphSize,
} from "../../components";
import { MainContainer } from "../../components/layout/MainContainer";
import {
  BOT_DETECTION_PATH,
  BREACHED_PASS_PATH,
  BRUTE_FORCE_PATH,
  IP_THROTTLING_PATH,
} from "../../constants";
import { useFetch } from "../../hooks";
import { SEO } from "../../utils";
import { Status } from "../clients/api-resources/components";

interface ISecuritySettings {
  captchaIsEnabled: boolean;
  bruteForceProtection: boolean;
  suspiciousIpThrottling: boolean;
  enablePwnValidation: boolean;
}

export const AttackProtection: FC = () => {
  const { pathname } = useLocation();
  const { apiCall: getSecuritySettings, response } = useFetch("get");

  const {
    captchaIsEnabled,
    bruteForceProtection,
    suspiciousIpThrottling,
    enablePwnValidation,
  } = (response as ISecuritySettings) || {};

  useEffect(() => {
    SEO({
      title: "Porta - Attack Protection",
    });

    getSecuritySettings("Users/SecuritySettings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const securityList = [
    {
      name: "Bot Detection",
      description:
        "Use Porta’s risk engine to detect bots and scripted attacks and enable a CAPTCHA to block them.",
      enabled: captchaIsEnabled,
      imgUrl: "bot-icon.svg",
      to: pathname + BOT_DETECTION_PATH,
    },
    {
      name: "Suspicious IP Throttling",
      description:
        "Protect your application against suspicious logins targeting too many accounts from a single IP address.",
      enabled: suspiciousIpThrottling,
      imgUrl: "analytics-icon.svg",
      to: pathname + IP_THROTTLING_PATH,
    },
    {
      name: "Brute-force Protection",
      description:
        "Safeguard agains brute-force attacks that target a single user account.",
      enabled: bruteForceProtection,
      imgUrl: "protection-icon.svg",
      to: pathname + BRUTE_FORCE_PATH,
    },
    {
      name: "Breached Password Detection",
      description:
        "Porta can detect if a specific user’s credentials were included in a major public security breach.",
      enabled: enablePwnValidation,
      imgUrl: "security-icon.svg",
      to: pathname + BREACHED_PASS_PATH,
    },
  ];

  return (
    <MainContainer>
      <PageHeadeline
        title="Attack Protection"
        description="Porta uses a variety of threat intelligence signals to protect your
        users from automated attacks."
        className="mb-10"
      />

      {securityList.map(({ name, description, enabled, imgUrl, to }) => (
        <Card
          cardStyle={CardStyle.bordered}
          cardSpace={CardSpace.none}
          className="mb-4"
          key={name}
        >
          <NavLink
            to={to}
            className="flex flex-wrap justify-between items-center p-4 transition hover:shadow-sm group"
          >
            <div className="flex flex-wrap">
              <div className="p-2 flex items-center justify-center rounded bg-gray-100 w-12 h-12 mr-4 flex-shrink-0 sm:mb-0 mb">
                <img
                  className="m-auto"
                  src={`images/security-icons/${imgUrl}`}
                  alt="Security Icon"
                />
              </div>
              <div>
                <h6 className="text-primary-mainText group-hover:text-primary transition">
                  {name}
                </h6>
                <Paragraph
                  noMargin
                  className="mt-1"
                  parahraphSize={ParagraphSize.xs}
                >
                  {description}
                </Paragraph>
              </div>
            </div>

            <div>
              <Status enabled={enabled} />
            </div>
          </NavLink>
        </Card>
      ))}
    </MainContainer>
  );
};
