//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { FC } from "react";

export const BannerCategories: FC<any> = ({
  watch,
  switchBannerType,
  width,
  setSwitchBannerType,
  setShow,
}) => {
  return (
    <div
      className="absolute top-1/2 left-1/2 -translate-x-1/2 transform -translate-y-1/2 overflow-hidden border shadow-configCard rounded-lg bg-white max-w-80 h-5/6 consent-banner-wrapper z-10 flex flex-col justify-center"
      style={{ width: width}}>
      <div className="control-section z-50 sticky top-0 px-6 py-4 flex justify-between items-center ">
        <div className="w-1/6">
          <img
            src="	https://accounts-admin.gjirafa.dev/images/porta-logo.svg"
            alt="Consent Logo"
          />
        </div>
        <div className="control-section p-2 absolute top-0 right-0  flex ">
          <span
            aria-label="dismiss cookie message"
            className={`cursor-pointer
               `}
            // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
            role="button"
            tab-index="0">
            <Icon name="Globe" size={20} />
          </span>
          <span
            onClick={() => setSwitchBannerType((prev: boolean) => !prev)}
            className={`cursor-pointer `}
            // className={`cursor-pointer ${!showCloseButton && "hidden"}`}
            aria-label="dismiss cookie message"
            role="button"
            tab-index="0">
            <Icon name="Close" size={20} />
          </span>
        </div>
      </div>
      <div className="tabs flex justify-start px-6  border-b border-gray-light">
        <button
          type="button"
          className="tab active pointer relative pb-4 mr-6 text-blue-secText  font-medium text-base hover:text-primary-mainText"
          data-tab-target="#cookie-display">
          Categories
        </button>
        <button
          type="button"
          className="tab pointer relative pb-4 mr-6 text-blue-secText font-medium text-base active:text-primary-mainText hover:text-primary-mainText"
          id="cookies-tab"
          data-tab-target="#cookie-settings">
          Cookies
        </button>
      </div>
      <div className="content-wrapper h-4/5  px-1 sm:px-6 overflow-y-auto my-3">
        <div className="description-section py-3">
          <p className="pb-2 text-primary-hover text-xs font-medium  leading">
            We and our partners use cookies, tags and other tracking
            technologies to customise advertisements, analyse how the site is
            used, enhance the site’s personalisation and perform some other
            necessary functions. More information about each cookie category can
            be found qbelow. Cookies under the “Necessary” category are
            essential for the site to function and cannot be turned off. However
            you can choose to give or withdraw consent by enabling / disabling
            all other categories below. Please be aware that disabling some of
            these categories may affect your browsing experience.
          </p>
          <div className="flex">
            <span
              id="cookieconsent:desc"
              className="text-primary-hover cursor-pointer text-xs mr-1 font-medium border-b border-primary-hover">
              Privacy Policy
            </span>
            <span
              id="cookieconsent:desc"
              className="text-primary-hover cursor-pointer text-xs font-medium border-b border-primary-hover">
              Cookie Policy
            </span>
          </div>
        </div>
        <div className="tab-content">
          <div
            id="cookie-display"
            className="tab-content active"
            data-tab-content>
            <ul id="basic-categories-banner" className=" cc-categories ">
              <li
                id="test-5"
                className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                <div
                  className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                  data-did="135">
                  <div className="flex items-end justify-between">
                    <p className=" category-title font-bold text-black-faded">
                      Necessary
                    </p>
                    <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                      <input
                        type="checkbox"
                        id="5"
                        checkedta-radio-parent-category-name="Necessary"
                        className="category-radio-button "
                        name="Necessary"
                        value="necessary"
                        checked
                        disabled
                      />
                      <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                        <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                      </div>
                    </button>
                  </div>
                  <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                    The necessary cookies are needed for the website to function
                    properly and cannot be switched off. Some of the basic
                    features that are enabled through these cookies are logging
                    in, filling in forms, or changing your consent settings.
                  </p>
                  <button
                    value="5"
                    data-settings-details-id="5"
                    className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                    data-category-id="5">
                    <span>Show Cookies</span>{" "}
                    <img
                      src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                      className="toggle-accordion  transition duration-300 ease-in-out transform"
                      alt="show cookies chevron"
                    />
                  </button>
                </div>
              </li>
              <li
                id="test-4"
                className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                <div
                  className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                  data-did="135">
                  <div className="flex items-end justify-between">
                    <p className=" category-title font-bold text-black-faded">
                      Preferences
                    </p>
                    <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                      <input
                        type="checkbox"
                        id="4"
                        data-radio-parent-category-name="Preferences"
                        className="category-radio-button "
                        name="Preferences"
                        value="preferences"
                      />
                      <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                        <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                      </div>
                    </button>
                  </div>
                  <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                    The preference cookies enhance the website’s personalisation
                    based on the user’s preferences. They are set by us or our
                    third-party service providers.
                  </p>
                  <button
                    value="4"
                    data-settings-details-id="4"
                    className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                    data-category-id="4">
                    <span>Show Cookies</span>{" "}
                    <img
                      src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                      className="toggle-accordion  transition duration-300 ease-in-out transform"
                      alt="show cookies chevron"
                    />
                  </button>
                </div>
              </li>
              <li
                id="test-3"
                className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                <div
                  className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                  data-did="135">
                  <div className="flex items-end justify-between">
                    <p className=" category-title font-bold text-black-faded">
                      Analytical
                    </p>
                    <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                      <input
                        type="checkbox"
                        id="3"
                        data-radio-parent-category-name="Analytical"
                        className="category-radio-button "
                        name="Analytical"
                        value="analytical"
                      />
                      <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                        <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                      </div>
                    </button>
                  </div>
                  <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                    The analytics cookies help us understand and improve the
                    performance of the site by tracking the user’s behavior to
                    report on the website’s usage. Some information on metrics
                    provided by these cookies is the number of visitors, and the
                    most and least popular pages.
                  </p>
                  <button
                    value="3"
                    data-settings-details-id="3"
                    className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                    data-category-id="3">
                    <span>Show Cookies</span>{" "}
                    <img
                      src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                      className="toggle-accordion  transition duration-300 ease-in-out transform"
                      alt="show cookies chevron"
                    />
                  </button>
                </div>
              </li>
              <li
                id="test-2"
                className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                <div
                  className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                  data-did="135">
                  <div className="flex items-end justify-between">
                    <p className=" category-title font-bold text-black-faded">
                      Marketing
                    </p>
                    <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                      <input
                        type="checkbox"
                        id="2"
                        data-radio-parent-category-name="Marketing"
                        className="category-radio-button "
                        name="Marketing"
                        value="marketing"
                      />
                      <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                        <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                      </div>
                    </button>
                  </div>
                  <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                    The targeting cookies are used by us and third parties that
                    represent our advertising partners. These cookies provide
                    our site visitors with custom advertising based on their
                    interests such as the pages they have visited. If not
                    allowed, the site visitor will not be shown advertising
                    tailored to their interests.
                  </p>
                  <button
                    value="2"
                    data-settings-details-id="2"
                    className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                    data-category-id="2">
                    <span>Show Cookies</span>{" "}
                    <img
                      src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                      className="toggle-accordion  transition duration-300 ease-in-out transform"
                      alt="show cookies chevron"
                    />
                  </button>
                </div>
              </li>
              <li
                id="test-1"
                className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                <div
                  className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                  data-did="135">
                  <div className="flex items-end justify-between">
                    <p className=" category-title font-bold text-black-faded">
                      Other
                    </p>
                    <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                      <input
                        type="checkbox"
                        id="1"
                        data-ra-parent-category-name="Other"
                        className="category-radio-button "
                        name="Other"
                        value="other"
                      />
                      <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                        <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                      </div>
                    </button>
                  </div>
                  <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                    The other cookies are cookies that have not been classified
                    into a category yet.
                  </p>
                  <button
                    value="1"
                    data-settings-details-id="1"
                    className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                    data-category-id="1">
                    <span>Show Cookies</span>{" "}
                    <img
                      src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                      className="toggle-accordion  transition duration-300 ease-in-out transform"
                      alt="show cookies chevron"
                    />
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div
            id="cookie-settings"
            className="tab-content py-6"
            data-tab-content>
            <div id="custom-categories-banner" className=" "></div>
          </div>
        </div>
      </div>
      <div className=" bottom-0 border-gray-200 border-t  buttons flex justify-start left-0 px-6 py-6 w-full z-50">
        <div>
          <button
            className="allow-all-button border border-primary rounded-sm text-primary-mainText bg-white px-5 py-1.5  ml-2"
            onClick={() => setShow("info")}>
            Confirm My Choices
          </button>
          <button
            type="button"
            className="border border-blue rounded-sm text-white bg-blue px-5 py-1.5  ml-2">
            Accept All Cookies
          </button>
        </div>
      </div>
    </div>
  );
};
