import { FC } from "react";

export const Integrations: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8C3 6.34314 4.34315 5 6 5L8 5C8 3.34315 9.34315 2 11 2C12.6569 2 14 3.34315 14 5L16 5C17.6569 5 19 6.34314 19 8V10C20.6569 10 22 11.3431 22 13C22 14.6569 20.6569 16 19 16V18C19 19.6569 17.6569 21 16 21H13V20C13 18.8954 12.1046 18 11 18C9.89543 18 9 18.8954 9 20V21H6C4.34315 21 3 19.6569 3 18V15H4C5.10457 15 6 14.1046 6 13C6 11.8954 5.10457 11 4 11H3V8ZM4.5 8C4.5 7.17157 5.17157 6.5 6 6.5L9.5 6.5L9.5 5C9.5 4.17157 10.1716 3.5 11 3.5C11.8284 3.5 12.5 4.17157 12.5 5V6.5L16 6.5C16.8284 6.5 17.5 7.17157 17.5 8V11.5H19C19.8284 11.5 20.5 12.1716 20.5 13C20.5 13.8284 19.8284 14.5 19 14.5H17.5V18C17.5 18.0518 17.4974 18.1029 17.4923 18.1534C17.4154 18.9097 16.7766 19.5 16 19.5H14.4646C14.2219 17.8039 12.7632 16.5 11 16.5C9.23676 16.5 7.77806 17.8039 7.53544 19.5H6C5.17157 19.5 4.5 18.8284 4.5 18V16.4646C6.19615 16.2219 7.5 14.7632 7.5 13C7.5 11.2368 6.19615 9.77806 4.5 9.53544V8Z"
      />
    </svg>
  );
};
