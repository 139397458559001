import { FC } from "react";
import { CopyCodeButton } from "../../../components";

interface IParameter {
  label: string;
  copyText: string;
}

export const Parameter: FC<IParameter> = ({ label, copyText }) => {
  return (
    <div className="group relative border border-primary-stroke bg-primary-stroke rounded-full py-1 px-3 text-sm inline-flex text-primary-mainText mr-2 mb-2 overflow-hidden">
      <span>{label}</span>
      <div className="bg-primary-stroke bg-opacity-95 flex items-center justify-center w-full h-full absolute left-0 top-0 transition transform translate-y-full group-hover:translate-y-0">
        <CopyCodeButton
          copyText={copyText}
          successMessage="Text copied to clipboard"
          className="sm:p-1 p-1 w-full hover:text-primary-mainText"
        />
      </div>
    </div>
  );
};
