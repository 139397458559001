import { FC, useEffect, useState } from "react";
import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import {
  DropdownOption,
  Loader,
  Modal,
  ModalSize,
  ThreeDotsButton,
} from "../../../components";
import { IconButton } from "../../../components/button/IconButton";
import { Card, CardStyle } from "../../../components/layout/Card";
import { Dropdown } from "../../clients/api-resources/components";
// import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import InfoFillPrimary from "../../../components/Icons/InfoFillPrimary";
import { CMP_API_URL, UI_BUILDER_PATH } from "../../../constants";
import { useDomainContext } from "../../../context";
import { IConsentStyle, IConsentStylesAll } from "../../../interfaces";
import {
  consentStyleIState,
  consentStylesAllIState,
  SEO,
} from "../../../utils";
import { ConsentStyleCreateModal } from "./components/ConsentStyleCreateModal";
import { ConsentStylePreviewModal } from "./ConsentStylePreviewModal";

export const Themes: FC<any> = ({ activeModal, setActiveModal }) => {
  const { domain } = useDomainContext();
  const { toggle, visible } = useToggle();
  const { apiCall: getConsentStyles, loading: getConsentLoading } =
    useFetch("get");
  const { apiCall: deleteTemplate, loading: deleteTemplateLoading } =
    useFetch("delete");
  const { apiCall: editSelectedTheme } = useFetch("put");
  const { encryptedId } = !!domain && domain;
  const PER_PAGE = 10;
  const { currentPage } = useCurrentPage();

  const [response, setResponse] = useState<IConsentStylesAll>(
    consentStylesAllIState
  );

  const [consentStyle, setConsentStyle] =
    useState<IConsentStyle>(consentStyleIState);
  // const [currentProperty, setCurrentPage] = useState<any>();
  const [isSelected, setIsSelected] = useState<string>("");
  const [consentId, setConsentId] = useState<string>("");
  const [initiatePublish, setInitiatePublish] = useState<boolean>(false);

  const {
    consentStyles,
    // pagingInfo
  } = !!response && response;
  // let data = {};

  //* GET ALL THE CONSENT STYLES
  useEffect(() => {
    if (encryptedId) {
      getConsentStyles(
        `${CMP_API_URL}/GetAllConsentStylesByDomainId?domainId=${encryptedId}&page=${currentPage}&pageSize=${PER_PAGE}`,
        {},
        (response) => {
          setResponse(response);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // const getThemeHandler = (themeId: string) =>
  //   getTheme(`${CMP_API_URL}/ConsentStyleOverview?consentStyleId=${themeId}`);

  useEffect(() => {
    SEO({
      title: "Porta - UI Builder",
    });
  }, []);

  const onClickHandler = (id: string) => {
    setIsSelected(id);
    // getThemeHandler(id);
  };

  const onPublishHandler = (id: string) => {
    response?.consentStyles.forEach((consentItem) => {
      // debugger;
      if (consentItem.encryptedId === id && consentItem.activeTheme !== true) {
        // data = { ...consentItem, activeTheme: true };
        editSelectedTheme(`${CMP_API_URL}/EditConsentStyle`, {
          ...consentItem,
          activeTheme: true,
        });
        // console.log({ ...consentItem, activeTheme: true });
      } else {
        // data = { ...consentItem, activeTheme: false };
        editSelectedTheme(`${CMP_API_URL}/EditConsentStyle`, {
          ...consentItem,
          activeTheme: false,
        });
        // console.log({ ...consentItem, activeTheme: false });
      }
    });
  };

  const closeModal = () => {
    setActiveModal("");
  };

  const onDeleteThemeHandler = () => {
    deleteTemplate(
      `${CMP_API_URL}/DeleteConsentStyle?id=${consentStyle?.encryptedId}`,
      {},
      () => {
        setResponse({
          ...response,
          consentStyles: response?.consentStyles.filter(
            (item: any) => item.encryptedId !== consentStyle.encryptedId
          ),
        });
      }
    );
    closeModal();
    toast.success("User deleted successfully!");
  };

  const banenrType = (type: string, id: string) => {
    switch (type?.toLowerCase()) {
      case "right":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-1/3 my-auto bottom-1/2 translate-y-1/2 left-2 transform rounded-sm h-5/6 `}
          ></span>
        );
      case "left":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue  w-1/3 my-auto bottom-1/2 translate-y-1/2 right-2 transform rounded-sm h-5/6 `}
          ></span>
        );
      case "top-right":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-1/3 mx-auto top-2 right-2 transform rounded-sm h-1/2 `}
          ></span>
        );
      case "top-left":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-1/3 mx-auto top-2 left-2 transform rounded-sm h-1/2 `}
          ></span>
        );
      case "bottom-right":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-1/3 mx-auto bottom-2 right-2 transform rounded-sm h-1/2 `}
          ></span>
        );
      case "bottom-left":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-1/3 mx-auto bottom-2 left-2 transform rounded-sm h-1/2 `}
          ></span>
        );
      case "top":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-11/12 mx-auto top-2 left-1/2 -translate-x-1/2 transform rounded-sm h-2.5 `}
          ></span>
        );
      case "bottom":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-11/12 mx-auto bottom-2 left-1/2 -translate-x-1/2 transform rounded-sm h-2.5 `}
          ></span>
        );
      case "center":
        return (
          <span
            className={`absolute ${
              isSelected === id ? "bg-blue" : "bg-gray-300"
            } group-hover:bg-blue w-1/3 mx-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-sm h-1/2 `}
          ></span>
        );

      default:
        break;
    }
  };

  return (
    <>
      {getConsentLoading ? (
        <Loader />
      ) : (
        <form>
          {initiatePublish && (
            <div className="flex justify-between mt-8 py-2 px-4 info-section bg-primary-100 border-1 border-blue rounded-md w-full">
              <div className="inline-block pt-1">
                <InfoFillPrimary />
              </div>
              <p className="max-w-[70%] text-primary-mainText mr-auto ml-2.5">
                Your changes are currently being published. Please wait for a
                while before reloading your website to view these changes.
              </p>{" "}
              <button
                className="flex align-top"
                onClick={() => {
                  setInitiatePublish((prevState) => (prevState = false));
                }}
              >
                <Icon name="Close" size="20" />
              </button>
            </div>
          )}
          <div className="flex flex-wrap mt-4">
            {
              // consentStyles?.length === 0 ? (
              //   <></>
              //   // <DefaultTemplate />
              // ) : (
              consentStyles?.map((item: any, index: number) => {
                return (
                  <>
                    {/* <DefaultTemplate /> */}
                    <Card
                      key={index}
                      // key={item.encryptedId}
                      cardStyle={CardStyle.bordered}
                      //   cardSpace={CardSpace.xl}
                      className={`relative group hover:border-blue ${
                        isSelected === item?.encryptedId && "border-blue"
                      } ${
                        item?.activeTheme && "border-blue"
                      } rounded-md mr-4 mt-4  px-4  pt-2.5 pb-4 w-full md:w-1/2 lg:w-auto max-w-max bg-gray-100`}
                      onClick={() => onClickHandler(item?.encryptedId)}
                    >
                      <div className="flex justify-between text-primary-mainText ">
                        {/* <NavLink
                      to={`${UI_BUILDER_PATH}/${item?.encryptedId}/theme-edit`}> */}
                        <IconButton
                          // iconSize={IconSize.medium}
                          text="Preview"
                          iconName="Redirect"
                          className=" bg-white border-gray-200 text-primary-mainText text-sm items-center mr-8"
                          disabled={false}
                          rounded="rounded-md"
                          onButtonClick={() => {
                            toggle();
                            setConsentId(item.bannerPlacement);
                          }}
                        />
                        {/* </NavLink> */}

                        <Dropdown
                          width="w-28 px-auto py-1"
                          noPadding
                          dropdownContent={
                            <>
                              {item.bannerName
                                .trim()
                                .split(" ")
                                .join("")
                                .toLowerCase() !== "defaulttemplate" && (
                                <DropdownOption
                                  isLink
                                  to={`${UI_BUILDER_PATH}/${item.encryptedId}/theme-edit`}
                                  withIcon
                                  iconName="Edit"
                                  onClick={() => {
                                    setActiveModal("edit-theme");
                                    setConsentStyle(item);
                                  }}
                                  label="Edit"
                                />
                              )}

                              <DropdownOption
                                // isLink
                                to=""
                                withIcon
                                iconName="Upload"
                                onClick={() => {
                                  setInitiatePublish(
                                    (prevState) => (prevState = true)
                                  );
                                  setTimeout(() => {
                                    setInitiatePublish(
                                      (prevState) => (prevState = false)
                                    );
                                    toast.success(
                                      "Template Published Successfully"
                                    );
                                  }, 2000);
                                  // setActiveModal("publish-theme");
                                  setConsentStyle(item);
                                  onPublishHandler(item.encryptedId);
                                }}
                                label="Publish"
                              />
                              {item.bannerName
                                .trim()
                                .split(" ")
                                .join("")
                                .toLowerCase() !== "defaulttemplate" && (
                                <DropdownOption
                                  withIcon
                                  iconName="Delete"
                                  disabled={item.activeTheme}
                                  onClick={() => {
                                    setActiveModal("delete-theme");
                                    setConsentStyle(item);
                                    // setCurrentPage(item);
                                  }}
                                  label="Delete"
                                />
                              )}
                            </>
                          }
                        >
                          <ThreeDotsButton />
                        </Dropdown>
                      </div>

                      <div className="group theme-display-section relative bg-white h-16 px-2 mt-4 mb-2  w-full rounded-md shadow ">
                        {banenrType(item?.bannerPlacement, item.encryptedId)}
                      </div>
                      <div className=" text-center group-hover:text-primary-mainText">
                        {item.bannerName}
                      </div>
                    </Card>
                  </>
                );
              })
              // )
            }
          </div>

          {activeModal === "create-template" && (
            <ConsentStyleCreateModal
              toggle={closeModal}
              visible={activeModal === "create-template"}
              // setCurrentPage={setCurrentPage}
              response={response}
              template={consentStyle}
              setResponse={setResponse}
            />
          )}
          {activeModal === "delete-theme" && (
            <Modal
              hide={closeModal}
              visible={activeModal === "delete-theme"}
              title="Delete Template"
              headerClassName="border border-b-gray pt-0"
              onConfirmClick={onDeleteThemeHandler}
              confirmBtnText="Delete"
              warningModal
              modalSize={ModalSize.sm}
              loading={deleteTemplateLoading}
              withFooter
              blockOutsideClick
            >
              {" "}
              <p className="text-md text-center font-bold text-primary-mainText mb-4">
                Are you sure you want to delete this template?
              </p>
              <p className="text-sm text-center  text-primary-mainText mb-4">
                You can’t undo this action.
              </p>
            </Modal>
          )}
        </form>
      )}

      {visible && (
        <ConsentStylePreviewModal
          theme={consentStyles}
          visible={visible}
          toggle={toggle}
          consentId={consentId}
        />
      )}
    </>
  );
};

// export default Themes;
