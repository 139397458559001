import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { FC, useEffect, useState } from "react";

import { PageHeadeline, Table } from "../../components";

import { MainContainer } from "../../components/layout/MainContainer";
import { USER_PROFILE_API, USER_PROFILE_API_HEADERS } from "../../constants";
import { useFetch, useToggle } from "../../hooks";
import {
  CreateModelButton,
  ModelListItem,
  ModelListItemDefault,
  UseModelTemplatesModal,
} from "./components";
import { IModel } from "./interface";

export const Models: FC = () => {
  const [models, setModels] = useState<IModel[]>();
  // const [formDirty, setFormDirty] = useState<boolean>(false);

  const {
    toggle: createTemplateModelToggle,
    visible: createModelTemplateVisible,
  } = useToggle();
  const { apiCall: getModels, response, loading } = useFetch("get");
  // const { apiCall: reorderModels } = useFetch("put");

  useEffect(() => {
    getModels(
      `${USER_PROFILE_API}/integration-models/list`,
      USER_PROFILE_API_HEADERS
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModels(response?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    // setFormDirty(true);

    if (over && active.id !== over?.id) {
      setModels((items: any) => {
        const oldIndex = items?.findIndex((item: any) => {
          return item?.order === active.id;
        });

        const newIndex = items?.findIndex((item: any) => {
          return item.order === over.id;
        });

        const sortedModels = arrayMove(items, oldIndex, newIndex)?.map(
          (newArrItem: any, index) => {
            return {
              ...newArrItem,
              order: index + 1,
            };
          }
        );

        return sortedModels;
      });
    }
  };

  const SortableModelComponent = (props: any) => {
    return <ModelListItem {...props} />;
  };

  // const handleModelsOrder = () => {
  //   let sortedModelsIdObject: any = {};

  //   models?.map((model: any) => {
  //     if (model.isDefault) return null;
  //     return (sortedModelsIdObject[model.id] = model.order);
  //   });

  //   sortedModelsIdObject &&
  //     reorderModels(
  //       `${USER_PROFILE_API}/integration-models/order`,
  //       sortedModelsIdObject,
  //       () => {
  //         toast.success("Models list reordered successfully!");
  //       },
  //       (err) => {
  //         toast.error(err?.response?.status);
  //       },
  //       USER_PROFILE_API_HEADERS
  //     );
  // };

  return (
    <MainContainer>
      <PageHeadeline
        title="Models"
        description="Bring in data from external sources and display it in users’ profiles to create a full-fledged user profile where users can interact with the various information related to their profile such as their subscriptions to premium content, newsletters, comments they have placed on your site, their activities and more.

        "
        className="mb-10"
      />

      <CreateModelButton onCreateReadyUseTemplate={createTemplateModelToggle} />

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={models || ([] as any)}
          strategy={rectSortingStrategy}
        >
          <Table
            loading={loading}
            wrapperClassName="mt-4 sm:mt-6"
            names={[
              { children: "Model" },
              // { children: "Model Type" },
              { children: "Created Date" },
              { children: "Status" },
              // { children: "0rder" },
              { children: "" },
            ]}
            values={models || []}
            renderTr={(el, index) => {
              if (el?.isDefault) return <ModelListItemDefault el={el} />;
              return (
                <SortableModelComponent
                  {...el}
                  models={models}
                  setModels={setModels}
                  key={index}
                  index={index}
                />
              );
            }}
            emptyView={{ text: "No models yet." }}
          />
        </SortableContext>
      </DndContext>

      {/* <FormFooter
        onSubmit={handleModelsOrder}
        loading={reorderModelsLoading}
        disabled={!formDirty}
        className="w-full"
        noCancelButton
      /> */}

      {createModelTemplateVisible && (
        <UseModelTemplatesModal
          isVisible={createModelTemplateVisible}
          toggle={createTemplateModelToggle}
        />
      )}
    </MainContainer>
  );
};
