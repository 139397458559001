import { FC } from "react";

interface IChatMiddle {
  width?: string;
  height?: string;
}

export const ChatMiddle: FC<IChatMiddle> = ({ width, height }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
      style={{
        width: width || 32,
        height: height || 32,
      }}
    >
      <path d="M8.33329 9.83333C8.33329 9.28105 8.78101 8.83333 9.33329 8.83333H22.6666C23.2189 8.83333 23.6666 9.28105 23.6666 9.83333C23.6666 10.3856 23.2189 10.8333 22.6666 10.8333H9.33329C8.78101 10.8333 8.33329 10.3856 8.33329 9.83333Z" />
      <path d="M9.33329 14.1667C8.78101 14.1667 8.33329 14.6144 8.33329 15.1667C8.33329 15.719 8.78101 16.1667 9.33329 16.1667L16 16.1667C16.5522 16.1667 17 15.719 17 15.1667C17 14.6144 16.5522 14.1667 16 14.1667L9.33329 14.1667Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2647 23.1667H25.3333C27.5424 23.1667 29.3333 21.3758 29.3333 19.1667V7.16667C29.3333 4.95753 27.5424 3.16667 25.3333 3.16667H6.66663C4.45749 3.16667 2.66663 4.95753 2.66663 7.16667V19.1667C2.66663 21.3758 4.45749 23.1667 6.66663 23.1667H9.7352C11.1402 23.1667 12.4423 23.9039 13.1652 25.1087L14.8566 27.9278C15.3745 28.7909 16.6254 28.7909 17.1433 27.9278L18.8347 25.1087C19.5576 23.9039 20.8597 23.1667 22.2647 23.1667ZM22.2647 21.1667H25.3333C26.4379 21.1667 27.3333 20.2712 27.3333 19.1667V7.16667C27.3333 6.0621 26.4379 5.16667 25.3333 5.16667H6.66663C5.56206 5.16667 4.66663 6.0621 4.66663 7.16667V19.1667C4.66663 20.2712 5.56206 21.1667 6.66663 21.1667H9.7352C11.8428 21.1667 13.7958 22.2725 14.8802 24.0797L16 25.946L17.1198 24.0797C18.2041 22.2725 20.1571 21.1667 22.2647 21.1667Z"
      />
    </svg>
  );
};
