import { FC } from "react";
// import { useParams } from "react-router-dom";
import {  useFormContext } from "react-hook-form";
// import { useTemplateContext } from "../../../../../context/TemplateContext";
import {
  Bottom,
  BottomLeft,
  BottomRight,
  Center,
  Left,
  Right,
  Top,
  TopLeft,
  TopRight,
} from "../../config/LayoutCards.config";

interface ILayoutProps {
  title?: string;
  type: string;
  bannerId?: any;
  selected: boolean;
  onClick: () => void;
}
export const Layout: FC<ILayoutProps> = ({
  title,
  type,
  bannerId,
  selected,
  onClick,
}) => {

  const { register } = useFormContext();

  // const onClicHandler = () => {
  //   onClick();
  //   // setClicked((prev) => !prev);
  //   // removeActiveThemes(id);
  //   // setTheme({ ...theme, bannerPlacement: type });
  // };

  const banenrType = () => {
    switch (type?.toLowerCase()) {
      case "right":
        return <Right selectedLayout={selected} />;
      case "left":
        return <Left selectedLayout={selected} />;
      case "top-right":
        return <TopRight selectedLayout={selected} />;
      case "top-left":
        return <TopLeft selectedLayout={selected} />;
      case "bottom-right":
        return <BottomRight selectedLayout={selected} />;
      case "bottom-left":
        return <BottomLeft selectedLayout={selected} />;
      case "top":
        return <Top selectedLayout={selected} />;
      case "bottom":
        return <Bottom selectedLayout={selected} />;
      case "center":
        return <Center selectedLayout={selected} />;

      default:
        break;
    }
  };

  return (
    <div
      className={` relative layout-item group  border  hover:border-blue col-span-1 flex flex-col items-center py-4 px-3 rounded-lg bg-gray-100 transform transition-all duration-300 ${
        selected ? "bg-green-100" : ""
      }`}
      style={
        selected ? { borderColor: "blue" } : { borderColor: "transparent" }
      }
      onClick={onClick}>
      <>
        <input
          {...register("bannerPlacement")}
          type="radio"
          id={bannerId}
          value={type}
          className="absolute top-0 left-0 w-full h-full z-50 opacity-0 max-h-32"
          // onClick={onClicHandler}
        />
        {banenrType()}
        <span
          className={`text-center w-full group-hover:text-primary-mainText ${
            selected ? "text-primary-mainText" : ""
          }`}>
          {title}
        </span>
      </>
    </div>
  );
};
