import { useEffect, FC, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  FormControl,
  Label,
  Modal,
  ModalSize,
  ToggleSwitch,
  Checkbox,
  Input,
  InputSize,
  Textarea,
  Loader,
} from "../../../components";
import {
  cookieCategoryIState,
  inputClasses,
  scrollToSection,
  textareaClasses,
} from "../../../utils";
import { ICookieCategory } from "../../../interfaces";
import { useFetch } from "../../../hooks";
import { CMP_API_URL } from "../../../constants";

interface ICreateCategoryModal {
  visible: boolean;
  toggle: () => void;
  category?: ICookieCategory;
  setCategories?: any;
  fromEdit?: boolean;
  encryptedDomainId: string;
  append: any;
}

export const ManageCategoryModal: FC<ICreateCategoryModal> = (props) => {
  const [load, setLoad] = useState<boolean>(props.fromEdit ? true : false);
  const {
    visible,
    toggle,
    category,
    append,
    setCategories,
    fromEdit,
    encryptedDomainId,
  } = props;

  const { apiCall: addCategory, loading } = useFetch("post");
  const { apiCall: editCategory, loading: editCatLoading } = useFetch("post");
  const { apiCall: getCategoryList } = useFetch("get");
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.fromEdit) {
      reset(category);
    } else {
      reset(cookieCategoryIState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    toggle();
    reset();
  };

  const onAddCategory = (data: any) => {
    const categoryObj = {
      ...data,
      encryptedDomainId,
    };

    addCategory(`${CMP_API_URL}​/AddCategory`, categoryObj, (response) => {
      const categoryObj2 = {
        ...categoryObj,
        encryptedId: response,
      };

      append(categoryObj2);
      toast.success("Category added successfully!");
      scrollToSection("non-default-categories");
      toggle();
    });
  };

  const onEditCategory = (data: any) => {
    delete data?.id;

    editCategory(`${CMP_API_URL}​/EditCategory`, data, () => {
      getCategoryList(
        `${CMP_API_URL}​/CategoryListView?domainId=${encryptedDomainId}`,
        {},
        (response) => {
          setCategories(response);
          // debugger;
        }
      );

      toggle();
      toast.success("Category updated successfully!");
    });
  };

  useEffect(() => {
    if (!!category && fromEdit) setLoad(false);
  }, [category, fromEdit]);

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      modalSize={ModalSize.md}
      title={fromEdit ? "Edit custom category" : "Add custom category"}
      confirmBtnText={fromEdit ? "Save" : "Create"}
      onConfirmClick={handleSubmit(fromEdit ? onEditCategory : onAddCategory)}
      onCancel={toggle}
      withFooter
      loading={fromEdit ? editCatLoading : loading}
      blockOutsideClick
    >
      <>
        {load && <Loader />}
        {!load && (
          <>
            <FormControl>
              <Label required text="Name" htmlFor="name" />
              <Input
                id="name"
                autoFocus
                {...register("name", { required: "Category name is required" })}
                error={!!errors.name && errors.name.message}
                inputSize={InputSize.sm}
                className={inputClasses}
              />
            </FormControl>

            <FormControl>
              <Label htmlFor="description" text="Description" />
              <Textarea
                className={textareaClasses}
                {...register("description")}
                maxLength={3000}
              />
            </FormControl>

            <p className="text-primary-mainText mb-6 mt-2 text-sm">
              Other Settings
            </p>

            <Controller
              control={control}
              name="checked"
              render={({ field: { onChange, value, ref } }: any) => (
                <ToggleSwitch
                  id="is-checked-"
                  label="Checked by default *"
                  onChange={onChange}
                  checked={value || false}
                  inputRef={ref}
                  wrapperClassName="mb-4"
                />
              )}
            />
            <Controller
              control={control}
              name="usedForTracking"
              render={({ field: { onChange, value, ref } }: any) => (
                <ToggleSwitch
                  id="used-for-tracking-"
                  label="Used for tracking (DNT)**"
                  onChange={onChange}
                  checked={value || false}
                  inputRef={ref}
                  wrapperClassName="mb-4"
                />
              )}
            />

            <Controller
              control={control}
              name="preconsent"
              render={({ field: { onChange, value, ref } }: any) => (
                <ToggleSwitch
                  id="preconsent-"
                  label="Preconsent***"
                  onChange={onChange}
                  checked={value || false}
                  inputRef={ref}
                  wrapperClassName="mb-4"
                />
              )}
            />

            {/* TODO: should change the name of input, its only for test */}
            <Controller
              control={control}
              name="addUncategorizedCookies"
              render={({ field: { onChange, value, ref } }: any) => (
                <Checkbox
                  label="Add all the uncategorized cookies to this category"
                  id="addUncategorizedCookies"
                  checked={value || false}
                  inputRef={ref}
                  onChange={onChange}
                  wrapperClassName="mb-2"
                />
              )}
            />

            {/* <Checkbox
              label="Add all the uncategorized cookies to this category"
              id="addUncategorizedCookies"
              {...register("addUncategorizedCookies")}
              wrapperClassName="mr-2"
            /> */}
          </>
        )}
      </>
    </Modal>
  );
};
