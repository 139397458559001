import { NavLink } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Spinner, SpinnerSize } from "./Spinner";
import { cx } from "../../utils";

interface IDropdownOption {
  className?: string;
  onClick?: () => void;
  label: string;
  to?: any;
  isLink?: boolean;
  withIcon?: boolean;
  state?: any;
  loading?: boolean;
  iconName?: string;
  disabled?: boolean;
  icon?: any;
}

export const DropdownOption: React.FC<IDropdownOption> = (props) => {
  const optionClasses = `${
    props.iconName === "Delete"
      ? "text-red hover:bg-red-100 hover:text-red focus:shadow-outlineWarning hover:bg-opacity-20"
      : "hover:bg-gray-100 focus:bg-gray-200 hover:text-primary-mainText"
  } inline-flex rounded items-center py-2 px-2 w-full text-left transition focus:outline-none`;
  return (
    <>
      {props.isLink ? (
        <NavLink
          to={props.to || ""}
          state={props.state}
          className={optionClasses}>
          {props.withIcon && (
            <div className="mr-2 w-4 h-4">
              <Icon name={props.iconName} size={18} />
            </div>
          )}

          <span className="text-sm">{props.label}</span>
        </NavLink>
      ) : (
        <button
          type="button"
          onClick={props.onClick}
          className={cx([
            "relative group",
            optionClasses,
            props.loading || (props.disabled && "opacity-75 cursor-default"),
          ])}
          disabled={props.loading || props.disabled}>
          {props.loading && (
            <div className="absolute h-full left-0 top-0 w-full bg-opacity-75 flex items-center justify-center">
              <Spinner size={SpinnerSize.xs} padding="p-0" />
            </div>
          )}

          {props.withIcon && (
            <div className="mr-2 w-4 h-4">
              <Icon name={props.iconName} size={18} />
            </div>
          )}

          <span className={cx(["text-sm"])}>{props.label}</span>
        </button>
      )}
    </>
  );
};
