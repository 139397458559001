import { FC, useEffect } from "react";

import { useParams } from "react-router";
import { BackButton, Tab, Tabs } from "../../../components";
import { ROLES_PATH } from "../../../constants";
import { useUserContext } from "../../../context/UserContext";
import { RoleSettings } from "./RoleSettings";
import { RoleUsers } from "./RoleUsers";
import { onlyForRoles, Permission } from "../../../context";
import { SEO } from "../../../utils";
import { MainContainer } from "../../../components/layout/MainContainer";

export const Role: FC = () => {
  const { setRoleId, role } = useUserContext();

  const { admin } = Permission;
  let { id } = useParams();

  useEffect(() => {
    SEO({
      title: "Porta - Role Settings",
    });

    setRoleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = [
    ...(onlyForRoles([admin])
      ? [{ path: `settings`, element: <RoleSettings /> }]
      : []),
    { path: `users`, element: <RoleUsers /> },
  ];

  return (
    <MainContainer>
      <div>
        <BackButton to={ROLES_PATH} className="mb-4" label="Back to Roles">
          <></>
        </BackButton>

        <h6 className="text-md sm:text-2xl font-medium text-primary-mainText">
          {role?.name}
        </h6>
        <p className="text-sm text-primary-secText">{role?.description}</p>
      </div>
      <Tabs routes={routes} wrapperClassname="mt-8 h-full flex flex-col">
        {onlyForRoles([admin]) && (
          <Tab
            statePath={{
              pathname: `settings`,
              state: {
                role,
              },
            }}
            label="Settings"
          />
        )}

        <Tab
          statePath={{
            pathname: `${ROLES_PATH}/${role?.id}/users`,
            state: {
              role,
            },
          }}
          label="Users"
        />
      </Tabs>
    </MainContainer>
  );
};
