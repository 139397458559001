export const applicationTypes = [
    { label: "Machine to Machine", value: "Machine" },
    { label: "Native App", value: "Native" },
    { label: "Regular Web App", value: "Web" },
    { label: "Single Page App", value: "Spa" },
    {
      label: "TV and Limited-Input Device Application",
      value: "Device",
    },
  ];

  export const secretTypes = [
    { label: "SharedSecret", value: "SharedSecret" },
    { label: "X509Thumbprint", value: "X509Thumbprint" },
    { label: "X509Name", value: "X509Name" },
    {
      label: "X509CertificateBase64",
      value: "X509CertificateBase64",
    },
  ]

  export const hashTypes = [
    { label: "Sha256", value: "Sha256" },
    { label: "Sha512", value: "Sha512" },
  ]

  export const languages = [
    { label: "English", value: "english" },
    { label: "Albanian", value: "albanian" },
    { label: "German", value: "german" },
  ]

  export const cookieCategories = [
    { label: "Necessary", value: "necessary" },
    { label: "Preferences", value: "preferences" },
    { label: "Analytics", value: "analytics" },
    { label: "Marketing", value: "marketing" },
    { label: "Other", value: "other" },
  ]

  export const cookieExprity = [
    // { label: "years", value: "y" },
    // { label: "days", value: "d" },
    // { label: "hours", value: "h" },
    { label: "minutes", value: "m" }
  ]