import { toast } from "react-toastify";
import { ButtonSection } from ".";
import { useFetch } from "../../../../hooks";

interface IDownloadUserData {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    id: string;
  };
}

export const DownloadUserData: React.FC<IDownloadUserData> = ({ user }) => {
  const { apiCall: downloadUserData, loading: downloadLoading } =
    useFetch("post");

  const downloadFile = (content: any, name: string, contentType: any) => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);
    a.download = name;
    a.click();
  };

  const onDownloadUserData = () => {
    downloadUserData(
      `ManageApi/DownloadPersonalDataByUserId?userId=${user?.id}`,
      {},
      (response) => {
        downloadFile(
          JSON.stringify(response),
          `${user?.firstName}__${user?.lastName}__Data.json`,
          "text/plain"
        );
        toast.success("User data downloaded successfully!");
      }
    );
  };

  return (
    <ButtonSection
      title="Export Data"
      description="Download a copy of this user's data. This will download a JSON file."
      btnText="Export data"
      primary
      onClick={onDownloadUserData}
      disabled={downloadLoading}
      loading={downloadLoading}
    />
  );
};
