import { FC } from "react";
import { Initials } from "../../../../components";

export const AssignUserSelectOption: FC = (props: any) => {
  const { innerProps, innerRef, data } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="custom-option px-4 py-2 hover:bg-primary-stroke transition flex"
    >
      {data?.picture ? (
        <div className="w-8 h-8 mr-4">
          <img
            src={data?.picture}
            alt=""
            className="max-w-full rounded-full w-full h-full"
          />
        </div>
      ) : (
        <Initials text={data?.firstName} className="mr-4" />
      )}

      <div>
        <h6 className="text-sm">{data.fullName}</h6>
        <p className="text-primary-secText text-xs">{data.email} </p>
      </div>
    </div>
  );
};
