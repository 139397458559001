import { useEffect, useState, FC } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { IApiScope } from "../../../interfaces";
import { apiScopeIState, SEO } from "../../../utils";
import { ApiScopeForm } from "./components";
import { API_SCOPES_PATH } from "../../../constants";
import { useFetch } from "../../../hooks";
import { Loader } from "../../../components";

export const ApiScopeCreate: FC = () => {
  const [claims, setClaims] = useState<string[]>([]);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const { apiCall: createApiScope, loading } = useFetch("post");

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta - API Scope Create",
    });

    reset(apiScopeIState);

    setTimeout(() => {
      setLoadingVisible(false);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateApiScope: SubmitHandler<any> = (data: IApiScope) => {
    const apiScopeDataObj = {
      ...data,
      name: data.name.trim(),
      userClaims: claims,
    };

    createApiScope(
      `/ApiScopes`,
      apiScopeDataObj,
      () => {
        toast.success("API scope created successfully!");
        navigate(API_SCOPES_PATH);
      },
      (error) => {
        const errRes = error?.response?.data;
        toast.error(errRes);

        if (errRes?.errors?.Name) {
          toast.error(errRes?.errors?.Name[0]);
        }
      }
    );
  };

  const props = {
    register,
    control,
    onSubmit: handleSubmit(onCreateApiScope),
    claims,
    setClaims,
    errors,
    loading,
  };

  return (
    <>
      {loadingVisible && <Loader />}
      {!loadingVisible && <ApiScopeForm {...props} loading={loading} />}
    </>
  );
};
