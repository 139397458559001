import { useEffect, FC, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "../../components";
import { BRANDINGS_PATH } from "../../constants";
import { useFetch } from "../../hooks";
import { IBrandingDetails } from "../../interfaces";
import { linkLikeBtnClasses, SEO } from "../../utils";
import { BrandingForm } from "./components";

export const Branding: FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);

  const { apiCall: getBrand, loading, response: branding } = useFetch("get");
  const { apiCall: updateBrandingRequest, loading: updateLoading } =
    useFetch("post");

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta - Branding Details",
    });

    getBrand(
      `BrandingConfigurations/Edit?id=${id}`,
      {},
      (response) => {
        // setBranding(res);
        reset(response);
      },
      () => {
        setHasError(true);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateBranding: SubmitHandler<any> = (data: IBrandingDetails) => {
    const brandingObj = {
      ...data,
      name: data.name.trim(),
    };
    updateBrandingRequest(
      `BrandingConfigurations/Edit`,
      brandingObj,
      () => {
        navigate(BRANDINGS_PATH);
        toast.success("Costumization updated successfully!");
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (hasError) {
    return (
      <div>
        <p className="mb-4">
          {" "}
          Costumization with this <strong>{id}</strong> does not exist!
        </p>

        <a href={BRANDINGS_PATH} className={linkLikeBtnClasses}>
          Back to branding
        </a>
      </div>
    );
  }

  return (
    <BrandingForm
      onSubmit={handleSubmit(updateBranding)}
      errors={errors}
      register={register}
      control={control}
      watch={watch}
      setValue={setValue}
      loading={loading}
      updateLoading={updateLoading}
      branding={branding}
      isDirty={isDirty}
      editMode
    />
  );
};
