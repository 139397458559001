import { cx } from "../../utils";
import { Spinner, SpinnerPosition, SpinnerSize } from "./Spinner";

export enum ScanStatusProps {
  completed = "bg-green-500",
  failed = "bg-red-500",
  scanning = "bg-orange-500",
}

interface IScanStatus {
  className?: string;
  scanStatus?: ScanStatusProps | string;
  loading?: boolean;
  statusContent?: string;
}

export const InlineStatus: React.FC<IScanStatus> = (props) => {
  const { className, scanStatus, loading, statusContent } = props;
  return (
    <div className={cx(["flex items-center text-sm h-5", className])}>
      {loading ? (
        <Spinner
          size={SpinnerSize.xs}
          position={SpinnerPosition.left}
          padding="p-0"
        />
      ) : (
        <>
          <span
            className={cx(["w-2 h-2 rounded-full block mr-2", scanStatus])}
          ></span>
          {statusContent}
        </>
      )}
    </div>
  );
};
