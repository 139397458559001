import { FC, useEffect } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import Select from "react-select";
import {
  ActionOutputs,
  AddNewButton,
  RemoveButton,
  RequestHeaders,
  VisibilitySettings,
} from "..";
import {
  Accordion,
  Checkbox,
  FormControl,
  Input,
  InputSize,
  Label,
  Message,
  Paragraph,
  ParagraphSize,
  Textarea,
} from "../../../../components";
import {
  cx,
  inputClasses,
  reactSelectStyle,
  textareaClasses,
  validateUrl,
} from "../../../../utils";
import { actionIState, IAction } from "../../interface";

// get expectedStatusCodes from back constants???
import {
  expectedSuccessStatusCodesData,
  requestMethodTypes,
} from "../../dummy-data";
import { ConstTypes, useConstant } from "../../utils/useConstant";

interface IModelActions {
  isFromEdit?: boolean;
  actionsData?: IAction[];
}

export const ModelActions: FC<IModelActions> = ({
  isFromEdit,
  actionsData,
}) => {
  const { constTypes: actionTypes } = useConstant(ConstTypes.actionTypes);

  let {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "actions",
  });

  useEffect(() => {
    isFromEdit && replace(actionsData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replace, actionsData]);

  const addNewAction = () => {
    append(actionIState);
  };

  const removeAction = (index: number) => {
    remove(index);
  };

  const [actions, properties] = useWatch({
    name: ["actions", "properties"],
  });

  const actionNames = actions?.map((action: any) => action.name);
  const actionsDataLength = actionsData?.length || 0;

  return (
    <div>
      <Paragraph parahraphSize={ParagraphSize.sm} className="mb-6 mt-4">
        Model actions allow you to define what actions an end user can take with
        specific functions offered by the model. For example, a user can
        ‘Cancel’ an active subscription, or ‘Resubscribe’.
      </Paragraph>

      {fields?.map((field, index) => {
        const action = actions && actions[index];
        const actionErrors = errors?.actions?.[index];

        return (
          <div className="flex justify-between mb-6" key={field.id}>
            <Accordion
              title={action?.name || `Action ${index + 1}`}
              className="flex-1"
              key={field.id}
              opened={index === 0 ? true : false}
              content={
                <>
                  <FormControl>
                    <Label required text="Action Name" htmlFor="action-name" />
                    <Input
                      id="action-name"
                      {...register(`actions.${index}.name`, {
                        required: "Action Name is required",
                        validate: {
                          notValidActionName: (value: string) =>
                            actionNames.indexOf(value) ===
                              actionNames.lastIndexOf(value) ||
                            "Action Name needs to be unique",
                        },
                      })}
                      error={
                        !!actionErrors?.name && actionErrors?.name?.message
                      }
                      inputSize={InputSize.sm}
                      className={inputClasses}
                      placeholder="Enter Action Name"
                      disabled={
                        isFromEdit &&
                        index + 1 <= actionsDataLength &&
                        action?.id
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <Label text="Model Action Type" />

                    <Controller
                      rules={{
                        required: true,
                      }}
                      name={`actions.${index}.actionType`}
                      control={control}
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          noOptionsMessage={() => "No model action type found."}
                          options={actionTypes}
                          getOptionLabel={(x) => x?.name}
                          getOptionValue={(x) => x?.id}
                          isSearchable
                          onChange={onChange}
                          ref={ref}
                          value={!!value?.name ? value : null}
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                          className={cx([
                            "text-sm",
                            actionErrors?.actionType && "invalid-field",
                          ])}
                          classNamePrefix="porta-react-select"
                          placeholder="Select Action Type"
                          styles={reactSelectStyle}
                          isDisabled={
                            isFromEdit &&
                            index + 1 <= actionsDataLength &&
                            action?.id
                          }
                          // isDisabled
                          // TODO:  isDisabled={isFromEdit && (index + 1 <= actionsDataLength)}
                        />
                      )}
                    />

                    {actionErrors?.actionType && (
                      <Message message="Action Type is required" />
                    )}
                  </FormControl>

                  {(action?.actionType?.id === 1 ||
                    action?.actionType?.id === 2) && (
                    <>
                      <FormControl>
                        <Label required text="URL" htmlFor="url" />
                        <Input
                          id="url"
                          {...register(`actions.${index}.url`, {
                            required: "URL is required",
                            validate: (value) =>
                              validateUrl(value) || "Please enter a valid URL.",
                          })}
                          error={
                            !!actionErrors?.url && actionErrors?.url?.message
                          }
                          inputSize={InputSize.sm}
                          className={inputClasses}
                          placeholder="Enter URL"
                        />

                        {action?.actionType?.id === 1 && (
                          <Controller
                            control={control}
                            name={`actions.${index}.redirectInNewTab`}
                            render={({ field: { onChange, value, ref } }) => (
                              <Checkbox
                                label="Open in new tab"
                                id={`openInNewTab-${index}`}
                                checked={value || false}
                                inputRef={ref}
                                onChange={onChange}
                                wrapperClassName="mt-3"
                              />
                            )}
                          />
                        )}
                      </FormControl>

                      {/* Visibility Settings */}
                      <div className="mb-4">
                        <p className="text-primary-secText">
                          Visibility Settings
                        </p>

                        <FormControl>
                          <Controller
                            control={control}
                            name={`actions.${index}.showVisibilitySettings`}
                            render={({ field: { onChange, value, ref } }) => (
                              <Checkbox
                                label="Show visibility settings"
                                checked={value || false}
                                inputRef={ref}
                                onChange={onChange}
                                wrapperClassName="mb-1 mt-3"
                              />
                            )}
                          />
                        </FormControl>

                        {action?.showVisibilitySettings && (
                          <VisibilitySettings
                            index={index}
                            properties={properties}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {/* Headers */}
                  {action?.actionType?.id === 2 && (
                    <>
                      <div>
                        <p className="text-primary-secText mb-4">
                          Configure request headers
                        </p>

                        <RequestHeaders index={index} />
                      </div>

                      <FormControl className="mt-8">
                        <Label required text="Request Method" />
                        <Controller
                          name={`actions.${index}.methodType`}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, ref, value } }) => (
                            <Select
                              noOptionsMessage={() =>
                                "No Request Method found."
                              }
                              options={requestMethodTypes}
                              getOptionLabel={(x) => x?.label}
                              getOptionValue={(x) => x?.value}
                              isSearchable
                              onChange={onChange}
                              ref={ref}
                              value={!!value?.label ? value : null}
                              menuPosition="fixed"
                              menuPortalTarget={document.body}
                              className={cx([
                                "text-sm",
                                actionErrors?.methodType && "invalid-field",
                              ])}
                              classNamePrefix="porta-react-select"
                              placeholder="Method Type"
                              styles={reactSelectStyle}
                            />
                          )}
                        />
                        {actionErrors?.methodType && (
                          <Message message="Method Type is required" />
                        )}
                      </FormControl>

                      <FormControl>
                        <Label text="Body" htmlFor="body" />
                        <Textarea
                          id="body"
                          {...register(`actions.${index}.body`)}
                          className={textareaClasses}
                        />
                      </FormControl>

                      <FormControl>
                        <Label required text="Expected HTTP Status Code" />
                        <Controller
                          name={`actions.${index}.expectedSuccessStatusCodes`}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, ref, value } }) => (
                            <Select
                              noOptionsMessage={() =>
                                "No expected HTTP Status Code found."
                              }
                              options={expectedSuccessStatusCodesData}
                              getOptionLabel={(x) => x?.label}
                              getOptionValue={(x) => x?.value}
                              isSearchable
                              onChange={onChange}
                              ref={ref}
                              value={value || null}
                              menuPosition="fixed"
                              menuPortalTarget={document.body}
                              className={cx([
                                "text-sm",
                                actionErrors?.expectedSuccessStatusCodes &&
                                  "invalid-field",
                              ])}
                              classNamePrefix="porta-react-select"
                              placeholder="Expected HTTP Status Code"
                              styles={reactSelectStyle}
                              isMulti
                            />
                          )}
                        />
                        {actionErrors?.expectedSuccessStatusCodes && (
                          <Message message="Expected Status Code is required" />
                        )}
                      </FormControl>

                      <FormControl description="This message is displayed to end-users in case of an error occurrence during the communication, otherwise there is a default error message">
                        <Label
                          text="Error Message (Optional)"
                          htmlFor="errorMessage"
                        />
                        <Input
                          id="errorMessage"
                          {...register(`actions.${index}.errorMessage`)}
                          inputSize={InputSize.sm}
                          className={inputClasses}
                          placeholder="Enter Error Message"
                        />
                      </FormControl>

                      {/* Action Output */}
                      <div className="mt-8 mb-2">
                        <p className="text-primary-secText">
                          Action Output (Optional)
                        </p>

                        <Controller
                          control={control}
                          name={`actions.${index}.addActionOutput`}
                          render={({
                            field: { onChange, value, ref },
                          }: any) => (
                            <Checkbox
                              label="Add Action Output"
                              id={`addActionOutput-${index}`}
                              checked={value || false}
                              inputRef={ref}
                              onChange={onChange}
                              wrapperClassName="mt-4 mb-3"
                            />
                          )}
                        />

                        {action?.addActionOutput && (
                          <ActionOutputs
                            index={index}
                            properties={properties || []}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              }
            />
            <RemoveButton
              onClick={() => removeAction(index)}
              className="ml-2 top-4"
            />
          </div>
        );
      })}

      <AddNewButton text="Add New Action" onClick={addNewAction} />
    </div>
  );
};
