import { createContext, useState, useContext, useEffect } from "react";

import { CMP_API_URL } from "../constants";
import { useFetch } from "../hooks";
import { IDomain } from "../interfaces";

const ApplicationContext = createContext<any>(null);

const DomainContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [domain, setDomain] = useState<IDomain>();
  const [domainId, setDomainId] = useState<string>();
  const { apiCall: getDomain, loading } = useFetch("get");

  useEffect(() => {
    if (!!domainId) {
      getDomain(
        `${CMP_API_URL}/DomainOverview?domainId=${domainId}`,
        {},
        (response) => {
          setDomain(response);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  return (
    <ApplicationContext.Provider
      value={{
        domain,
        setDomain,
        setDomainId,
        loading,
      }}>
      {children}
    </ApplicationContext.Provider>
  );
};

const useDomainContext = () => {
  return useContext(ApplicationContext);
};

export { DomainContextProvider, useDomainContext };
