import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, PageHeadeline, StaticTabs } from "../../components";
import { MainContainer } from "../../components/layout/MainContainer";
// @ts-ignore
import { GLOBAL_CSS_PATH, ORGANIZATION, TEMPLATES_API } from "../../constants";
import { useFetch } from "../../hooks";
import { cx, primaryBtnClasses } from "../../utils";
import { TemplateHeadline, TemplateItem } from "./components";
import { ITemplate } from "./interface";

export const Templates = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({});
  const [activeTemplate, setActiveTemplate] = useState<number>(0);

  const { apiCall: getTemplates, response: templates } = useFetch("get");

  useEffect(() => {
    getTemplates(`${TEMPLATES_API}?Organization=${ORGANIZATION}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchParam = searchParams.get("type");

  useEffect(() => {
    searchParam && setActiveTemplate(searchParam === "Default" ? 0 : 1);
  }, [searchParam]);

  const onTabSelect = (tab: number) => {
    setSearchParams({ type: tab === 0 ? "Default" : "Custom" });
  };

  const templateTypes = [
    {
      name: "Default",
      isActive: activeTemplate === 0 ? true : false,
    },
    {
      name: "Custom",
      isActive: activeTemplate === 1 ? true : false,
    },
  ];

  const defaultTemplates = templates?.filter(
    (template: ITemplate) =>
      template?.type === "Default" && template.name !== "Global"
  );

  const customTemplates = templates?.filter(
    (template: ITemplate) => template?.type === "Custom"
  );

  return (
    <MainContainer className="relative">
      <PageHeadeline
        className="mb-10"
        title="Templates"
        description="Customize your user profile to align with your application's branding and improve user loyalty."
      />

      <Button
        type="button"
        role="link"
        onClick={() => navigate(GLOBAL_CSS_PATH)}
        className={cx([primaryBtnClasses, "absolute right-4 top-[10px]"])}
      >
        <span className="mr-1">{`{ } `}</span> Global CSS
      </Button>

      <div className="mb-10">
        <StaticTabs
          tabs={templateTypes}
          getActiveTab={(tab) => onTabSelect(tab)}
          initialActiveTab={activeTemplate}
        >
          <div tabIndex={0}>
            <TemplateHeadline />
            {defaultTemplates?.map((template: ITemplate) => (
              <TemplateItem
                searchParam={searchParam || ""}
                template={template}
              />
            ))}
          </div>

          <div tabIndex={1}>
            <TemplateHeadline />
            {customTemplates?.map((template: ITemplate) => (
              <TemplateItem
                searchParam={searchParam || ""}
                template={template}
              />
            ))}
          </div>
        </StaticTabs>
      </div>
    </MainContainer>
  );
};
