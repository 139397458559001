interface IBlockSection {
  title: string;
  className?: string;
  buttonText?: string;
  message?: string;
  onButtonClick?: () => void;
  withButton?: boolean;
}

export const BlockSection: React.FC<IBlockSection> = (props) => {
  return (
    <div className={props.className || ""}>
      <p className="text-sm text-blue-secText">
        {props.title}

        {props.withButton && (
          <button
            type="button"
            onClick={props.onButtonClick}
            className="ml-4 text-xs text-primary hover:underline focus:outline-none"
          >
            {props.buttonText}
          </button>
        )}
      </p>
      <p className="text-sm text-blue-mainText mt-2">{props.message}</p>
    </div>
  );
};
