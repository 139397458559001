import { useEffect } from "react";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router";

import {
  Modal,
  FormControl,
  Label,
  Input,
  InputSize,
  Message,
  MessageTypes,
} from "../../components";
import { IApplication } from "../../interfaces";
import {
  applicationTypes,
  cx,
  inputClasses,
  newAppState,
  reactSelectStyle,
} from "../../utils";
import { useFetch } from "../../hooks";
import { APPS_PATH } from "../../constants";

interface IAddNewAppModal {
  visible: boolean;
  toggle: () => void;
}

export const AddNewAppModal: React.FC<IAddNewAppModal> = ({
  visible,
  toggle,
}) => {
  let navigate = useNavigate();
  const { apiCall: addNewApplication, loading } = useFetch("post");
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(newAppState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewApp: SubmitHandler<any> = (data: IApplication) => {
    const appObject = {
      ...data,
      clientName: data?.clientName?.trim(),
      clientType: data.clientType?.value,
    };

    addNewApplication(
      "/Clients",
      appObject,
      (response) => {
        toast.success("Application successfully created!");
        navigate(`${APPS_PATH}/${response.id}`, {
          state: {
            index: response.colorId,
          },
        });
        toggle();
      },
      (errors) => {
        const errRes = errors?.response?.data;
        toast.error(errRes?.description);

        if (errRes?.errors?.ClientName) {
          toast.error(errRes?.errors?.ClientName[0]);
        }
      }
    );
  };

  const closeModal = () => {
    toggle();
    reset();
  };

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      title="Create new application"
      withFooter
      onConfirmClick={handleSubmit(onAddNewApp)}
      confirmBtnText="Create"
      loading={loading}
      blockOutsideClick
    >
      <FormControl>
        <Label required text="Application Name" />
        <Input
          autoFocus
          {...register("clientName", {
            required: "Application name is required",
            validate: {
              notValidName: (value: string) =>
                !!value.trim() || "Application name is required",
            },
          })}
          error={!!errors.clientName && errors.clientName.message}
          inputSize={InputSize.sm}
          className={inputClasses}
          maxLength={100}
          type="text"
        />
      </FormControl>

      <FormControl lastChild>
        <Label required text="Application type" />

        <Controller
          control={control}
          name="clientType"
          rules={{ required: "Application type is required" }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              onChange={onChange}
              options={applicationTypes}
              value={value || ""}
              isSearchable
              ref={ref}
              placeholder="Select application type"
              menuPosition="fixed"
              portalPlacement="auto"
              className={cx([
                "flex-1 text-sm mb-2 sm:mt-0 mt-4",
                errors.clientType && "invalid-field",
              ])}
              classNamePrefix="porta-react-select"
              styles={reactSelectStyle}
            />
          )}
        />

        {errors.clientType && (
          <div className="mt-2">
            <Message
              message={errors.clientType.message || ""}
              type={MessageTypes.error}
            />
          </div>
        )}
      </FormControl>
    </Modal>
  );
};
