import { FC, useEffect, useState } from "react";
import { cx } from "../../utils";

interface ITab {
  name: string;
  isActive: boolean;
  dotVisible?: boolean;
  dotError?: boolean;
}

interface IStaticTabs {
  children: any;
  className?: string;
  wrapperClassName?: string;
  tabs: ITab[];
  getActiveTab?: (tab: number) => void;
  initialActiveTab?: number;
}

export const StaticTabs: FC<IStaticTabs> = ({
  tabs,
  children,
  getActiveTab,
  initialActiveTab,
  wrapperClassName,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const onClickTabItem = (tab: number) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    !!getActiveTab && getActiveTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    initialActiveTab && setActiveTab(initialActiveTab);
  }, [initialActiveTab]);

  return (
    <div className={cx([wrapperClassName])}>
      <div className="flex border-b border-gray-200">
        {tabs?.map((tab, index) => {
          return (
            <button
              key={index}
              type="button"
              onClick={() => onClickTabItem(index)}
              className={cx([
                "inline-block pb-4 mr-6 sm:mr-10 relative",
                "text-sm leading-4 font-medium ",
                "hover:text-primary-mainText focus:outline-none",
                activeTab === index
                  ? "text-primary-mainText"
                  : "text-primary-secText",
              ])}
            >
              {tab.name}

              {activeTab === index && (
                <div className="w-full absolute border-b-2 -bottom-px border-primary" />
              )}

              {tab?.dotVisible && (
                <span
                  title={`${tab?.dotError ? "Error" : "Changed"}`}
                  className="w-1.5 h-1.5 rounded-full inline-block absolute -right-3 top-1.5 bg-primary"
                ></span>
              )}
            </button>
          );
        })}
      </div>

      {children?.map((one: any) => {
        return (
          one.props.tabIndex === activeTab && (
            <div key={one.props.indexNumber}>{one.props.children}</div>
          )
        );
      })}

      {/* {children?.map((one: any) => {
        return (
          <div
            key={one.props.indexNumber}
            className={cx([
              one.props.tabIndex === activeTab
                ? "opacity-1 visible"
                : "opacity-0 hidden",
            ])}
          >
            {one.props.children}
          </div>
        );
      })} */}
    </div>
  );
};
