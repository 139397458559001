
import {useState, useEffect} from 'react'

export const useMounted = () => {
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        setMounted(true);
    
        return () => {
            setMounted(false);
        };
      }, [mounted]);

    return {
        mounted,
        setMounted
    };
  };