import { Card, CardSpace, CardStyle, Label } from "../../components";
import { cx } from "../../utils";
import { ModelActionsDetails } from "./components";
import { useModelDetailsContext } from "./context/ModelDetailsContext";
import { IProperty } from "./interface";

export const ModelDetails = () => {
  const { model } = useModelDetailsContext();

  const properties = model?.body?.properties;

  return (
    <Card
      cardStyle={CardStyle.bordered}
      cardSpace={CardSpace.xl}
      className="mt-8"
    >
      <div className="mb-6">
        <Label text="Name" />
        <p className="text-sm text-primary-mainText break-all">{model?.name}</p>
      </div>
      <div className="mb-6">
        <Label text="Description" />
        <p className="text-sm text-primary-mainText break-all">
          {model?.description}
        </p>
      </div>
      <div className="mb-8">
        <Label text="Alias" />
        <p className="text-sm text-primary-mainText break-all">
          {model?.alias}
        </p>
      </div>

      {model?.icon && (
        <div className="mb-6">
          <Label text="Icon" />
          <div className="border-2 border-primary-stroke rounded p-2 inline-block">
            <img src={model?.icon} alt={`${model?.name} icon`} width={40} />
          </div>
        </div>
      )}

      <h3 className="text-xl text-primary-mainText font-medium mb-4">
        Model Properties
      </h3>
      <div>
        <div className="grid grid-cols-4 border-b">
          <Label text="Property Name" />
          <Label text="Data Type" />
          <Label text="Designation Type" />
          <Label text="Mode" />
        </div>
        {properties?.map((property: IProperty, index: number) => {
          const lastChild = index === properties?.length - 1;

          return (
            <div
              className={cx([
                "grid grid-cols-4 py-2.5",
                !lastChild && "border-b",
              ])}
              key={index}
            >
              <p className="text-sm">{property?.name}</p>
              <p className="text-sm">{property?.inputType?.name}</p>
              <p className="text-sm">
                {property?.designationType?.name || "-"}
              </p>
              <p className="text-sm">
                {property?.isRequired ? "Required" : "Nullable"}
              </p>
            </div>
          );
        })}
      </div>

      {model?.body?.actions.length > 0 && (
        <ModelActionsDetails actions={model?.body?.actions} />
      )}
    </Card>
  );
};
