import { useEffect, FC, Dispatch, useState } from "react";

import { useForm } from "react-hook-form";
import Select, { components } from "react-select";

import {
  FormControl,
  Label,
  Modal,
  ModalSize,
  Input,
  InputSize,
} from "../../../components";
import {
  defaultConsentIState,
  inputClasses,
  reactSelectStyle,
} from "../../../utils";
import { IConsentStyle, IDomains } from "../../../interfaces";
import { useFetch } from "../../../hooks";
import { CMP_API_URL } from "../../../constants";

interface ICreateDomainModal {
  visible: boolean;
  toggle: () => void;
  setResponse: Dispatch<IDomains>;
  response: IDomains;
}

interface IApplication {
  clientId: string;
  clientName: string;
}

const ApplicationIState = {
  clientId: "",
  clientName: "",
};

export const CreateDomainModal: FC<ICreateDomainModal> = ({
  visible,
  toggle,
  setResponse,
  response,
}) => {
  const [application, setApplication] =
    useState<IApplication>(ApplicationIState);
  const { apiCall: getApplications, response: applications } = useFetch("get");
  const { apiCall: createDomain, loading } = useFetch("post");
  const { apiCall: createConsentStyle, loading: defaultTemplateLoading } =
    useFetch("post");
  const defaultTemplate: IConsentStyle = defaultConsentIState;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getApplications(`Clients/GetClientsForDropdown`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    toggle();
    reset();
  };

  const handleSelectValue = (e: any) => {
    setApplication(e);
  };

  const onCreateDomain = (data: any) => {
    const createDomainObj = {
      ...data,
      name: data.name?.trim(),
      application: application.clientId,
    };

    createDomain(`${CMP_API_URL}/AddDomain`, createDomainObj, (res) => {
      toggle();
      const dataObject = {
        ...data,
        name: data.name?.trim(),
        encryptedId: res,
      };

      setResponse({
        ...response,
        domains: [dataObject, ...response.domains],
        pagingInfo: {
          ...response.pagingInfo,
          totalItems: response.pagingInfo?.totalItems + 1,
        },
      });
      !loading &&
        createConsentStyle(
          `${CMP_API_URL}/AddConsentStyle`,
          // TODO substitute domainId with the dynamic domainId
          { ...defaultTemplate, domainId: res.id },
          (res) => {
            !defaultTemplateLoading && console.log("created default template");
            console.log("default theme", res);
            // setResponse({
            //   ...response?.consentStyles,
            //   consentStyles: [
            //     ...response?.consentStyles,
            //     {
            //       // ...res,
            //       // id: uuidv4(),
            //       // ...template,
            //       ...consentStyleIState,
            //       ...res,
            //       domainId: domain?.id,
            //       bannerName: data?.bannerName,
            //       bannerPlacement: data?.bannerPlacement?.content,
            //     },
            //   ],
            // });
          }
        );
    });
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No applications</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      modalSize={ModalSize.md}
      title="Add Domain"
      confirmBtnText="Add"
      onConfirmClick={handleSubmit(onCreateDomain)}
      onCancel={toggle}
      withFooter
      loading={loading}
      blockOutsideClick
    >
      <>
        <FormControl>
          <Label required text="Domain Name" htmlFor="domain-name" />
          <Input
            id="domain-name"
            autoFocus
            {...register("name", {
              required: "Domain name is required",
              validate: {
                notValidName: (value: string) =>
                  !!value.trim() || "Domain name is required",
              },
            })}
            error={!!errors.name && errors.name.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label required text="Site URL" htmlFor="website-url" />
          <Input
            id="website-url"
            {...register("websiteUrl", { required: "Site URL is required" })}
            error={!!errors.websiteUrl && errors.websiteUrl.message}
            inputSize={InputSize.sm}
            className={inputClasses}
            required
          />
        </FormControl>

        <FormControl>
          <Label
            required
            text="Domain’s privacy policy URL"
            htmlFor="domains-privacy-url"
          />
          <Input
            id="domains-privacy-url"
            {...register("privacyPolicyUrl", {
              required: "Domain’s privacy policy URL is required",
            })}
            error={!!errors.privacyPolicyUrl && errors.privacyPolicyUrl.message}
            inputSize={InputSize.sm}
            className={inputClasses}
            required
          />
        </FormControl>

        <FormControl lastChild>
          <Label text="Application" />

          <Select
            components={{ NoOptionsMessage }}
            options={applications || []}
            getOptionLabel={(x) => x?.clientName}
            getOptionValue={(x) => x?.clientId}
            isSearchable
            menuPosition="fixed"
            portalPlacement="auto"
            onChange={handleSelectValue}
            className="text-sm"
            classNamePrefix="porta-react-select"
            placeholder="Search..."
            styles={reactSelectStyle}
          />
        </FormControl>
      </>
    </Modal>
  );
};
