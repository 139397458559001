import { Dispatch, memo, FC } from "react";

import { Controller } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import {
  BackButton,
  DeleteSection,
  FormControl,
  FormWrapper,
  Label,
  Modal,
  SectionTitle,
  ToggleSwitch,
  Input,
  InputSize,
  Textarea,
  BtnSize,
  BtnStyle,
  Button,
} from "../../../../components";
import { MultiTagsCard } from "../../../../components/ui/MultiTagsCard";
import { API_RES_PATH } from "../../../../constants";
import { IApiResource, IReactHookForm } from "../../../../interfaces";
import { useToggle } from "../../../../hooks";
import {
  inputClasses,
  primaryBtnClasses,
  textareaClasses,
} from "../../../../utils";

interface IApiResourceForm extends IReactHookForm {
  onSubmit: () => void;
  accessTokens: string[];
  setAccessTokens: Dispatch<string[]>;
  claims: string[];
  setClaims: Dispatch<string[]>;
  scopes: string[];
  setScopes: Dispatch<string[]>;
  editMode?: boolean;
  apiResource?: IApiResource;
  paramId?: any;
  name?: string;
  loading?: boolean;
}

export const ApiResourceForm: FC<IApiResourceForm> = memo((props) => {
  const { toggle, visible } = useToggle();
  const navigate = useNavigate();

  const deleteApiResource = async () => {
    try {
      await axios.delete(`/ApiResources/${props.paramId}`);
      toast.success("API Resource deleted successfully!");
      navigate(API_RES_PATH);
      toggle();
    } catch (err: any) {
      toast.error(err.status);
    }
  };

  return (
    <>
      <FormWrapper onSubmit={props.onSubmit} loading={props.loading}>
        <BackButton
          to={API_RES_PATH}
          className="mb-4"
          label="Back to API Resources"
        >
          <></>
        </BackButton>

        <SectionTitle>API Resource</SectionTitle>
        <FormControl description="The unique name of the API. This value is used for authentication with introspection and will be added to the audience of the outgoing access token.">
          <Label required text="Name" />
          <Input
            autoFocus={!props.editMode}
            className={inputClasses}
            {...props.register("name", {
              required: "API Resource name is required",
              validate: {
                notValidName: (value: string) =>
                  !!value.trim() || "API Resource name is required",
              },
            })}
            maxLength={100}
            error={!!props.errors.name && props.errors.name.message}
            inputSize={InputSize.sm}
          />
        </FormControl>

        <FormControl description="This value can be used e.g. on the consent screen.">
          <Label text="Display Name" htmlFor="display-name" />
          <Input
            id="display-name"
            {...props.register("displayName")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl description="This value can be used e.g. on the consent screen.">
          <Label text="Description" htmlFor="description" />
          <Textarea
            id="description"
            {...props.register("description")}
            maxLength={3000}
            className={textareaClasses}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="showInDiscoveryDocument"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="discovery-doc"
                label="Show In Discovery Document"
                description="Specifies whether this scope is shown in the discovery document. Defaults to true."
                onChange={onChange}
                checked={value}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="enabled"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="enabled"
                label="Enabled"
                description="Indicates if this resource enabled and can be requested. Defaults to true."
                onChange={onChange}
                checked={value}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        {props.editMode && (
          <>
            <FormControl description="The API secret is used for the introspection endpoint. The API can uthenticate with introspection using the API name and secret">
              <Label text="Secrets" />
              <NavLink
                to={`${API_RES_PATH}/api-secrets/${props.paramId}`}
                state={{
                  id: props.apiResource?.id,
                  name: props.apiResource?.name,
                }}
              >
                <Button
                  type="button"
                  btnStyle={BtnStyle.primary}
                  btnSize={BtnSize.normal}
                  className={primaryBtnClasses}
                >
                  Manage API Secrets
                </Button>
              </NavLink>
            </FormControl>

            <FormControl description="Dictionary to hold any custom API resource-specific values as needed.">
              <Label text="Properties" />
              <NavLink
                to={`${API_RES_PATH}/api-properties/${props.paramId}`}
                state={{
                  id: props.apiResource?.id,
                  name: props.apiResource?.name,
                }}
              >
                <Button
                  type="button"
                  btnStyle={BtnStyle.primary}
                  btnSize={BtnSize.normal}
                  className={primaryBtnClasses}
                >
                  Manage API Resource Properties
                </Button>
              </NavLink>
            </FormControl>
          </>
        )}

        <FormControl description="List of allowed signing algorithms for access token. If empty, will use the server default signing algorithm.">
          <Label text="Allowed Access Token Signing Algorithms" />
          <MultiTagsCard
            tags={props.accessTokens}
            setTags={props.setAccessTokens}
            suggestedEndpoint="/Clients/SearchSigningAlgorithms?limit=0"
          />
        </FormControl>

        <FormControl description="List of associated user claim types that should be included in the access token.">
          <Label text="User Claims" />
          <MultiTagsCard
            tags={props.claims}
            hasInput
            setTags={props.setClaims}
            suggestedEndpoint="/Clients/SearchClaims?limit=0"
          />
        </FormControl>

        <FormControl description="An API must have at least one scope . each scope can have different settings.">
          <Label text="Scopes" />
          <MultiTagsCard
            tags={props.scopes}
            hasInput
            setTags={props.setScopes}
            suggestedEndpoint="/Clients/SearchScopes?limit=0"
          />
        </FormControl>

        {props.editMode && (
          <DeleteSection
            title="Delete API Resource"
            message="Deleting the API resource will not allow the application to accept and respond to protected resource requests made by applications."
            onDelete={toggle}
          />
        )}
      </FormWrapper>

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          title="Delete API Resource"
          onConfirmClick={deleteApiResource}
          confirmBtnText="Delete"
          withFooter
          warningModal
        >
          <p className="text-sm text-gray-700">
            Are you sure you want to delete <strong>{props.name}</strong>?
          </p>
        </Modal>
      )}
    </>
  );
});
