import { FC } from "react";

export const Home: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5053 5.36372C12.6496 4.5936 11.3506 4.5936 10.4949 5.36372L4.11332 11.1072C4.09059 11.1276 4.08723 11.1409 4.08587 11.1473C4.08355 11.1583 4.08352 11.1763 4.09163 11.1975C4.09974 11.2186 4.11184 11.232 4.12088 11.2386C4.12616 11.2424 4.13756 11.25 4.16815 11.25C5.04184 11.25 5.7501 11.9583 5.7501 12.832V19C5.7501 19.6904 6.30975 20.25 7.0001 20.25H8.0001C8.69046 20.25 9.2501 19.6904 9.2501 19V17C9.2501 15.4813 10.4813 14.25 12.0001 14.25C13.5189 14.25 14.7501 15.4813 14.7501 17V19C14.7501 19.6904 15.3097 20.25 16.0001 20.25H17.0001C17.6905 20.25 18.2501 19.6904 18.2501 19V12.832C18.2501 11.9583 18.9584 11.25 19.8321 11.25C19.8626 11.25 19.874 11.2424 19.8793 11.2386C19.8884 11.232 19.9005 11.2186 19.9086 11.1975C19.9167 11.1763 19.9167 11.1583 19.9143 11.1473C19.913 11.1409 19.9096 11.1276 19.8869 11.1072L13.5053 5.36372ZM9.49148 4.24878C10.9176 2.96525 13.0826 2.96525 14.5087 4.24878L20.8903 9.99223C21.968 10.9621 21.2819 12.75 19.8321 12.75C19.7868 12.75 19.7501 12.7867 19.7501 12.832V19C19.7501 20.5188 18.5189 21.75 17.0001 21.75H16.0001C14.4813 21.75 13.2501 20.5188 13.2501 19V17C13.2501 16.3097 12.6905 15.75 12.0001 15.75C11.3097 15.75 10.7501 16.3097 10.7501 17V19C10.7501 20.5188 9.51889 21.75 8.0001 21.75H7.0001C5.48132 21.75 4.2501 20.5188 4.2501 19V12.832C4.2501 12.7867 4.21341 12.75 4.16815 12.75C2.71831 12.75 2.03222 10.9621 3.10987 9.99223L9.49148 4.24878Z"
      />
    </svg>
  );
};
