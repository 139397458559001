import { FC } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../../utils";

interface IStatusTag {
  value: number;
}

export const StatusTag: FC<IStatusTag> = ({ value }) => {
  const hasPositiveValue = value > -1;
  return (
    <div
      className={cx([
        "py-1 px-2 mt-4 rounded-full bg-opacity-10 font-bold text-xs inline-flex items-center",
        hasPositiveValue && "bg-green text-green",
        !hasPositiveValue && "bg-warning text-warning",
      ])}
    >
      {value}%
      <div className="ml-1">
        <Icon
          name={hasPositiveValue ? "ArrowUpAlt" : "ArrowDownAlt"}
          size={18}
        />
      </div>
    </div>
  );
};
