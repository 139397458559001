import { ShowMoreText } from "../../../../components";
import { cx } from "../../../../utils";

interface ITag {
  className?: string;
  text: string;
  showMoreBtn?: boolean;
}

export const Tag: React.FC<ITag> = ({ className, text, showMoreBtn }) => {
  return (
    <div
      className={cx([
        "py-1 px-2 rounded-full border border-primary-stroke break-all text-sm text-primary-mainText",
        className,
      ])}
    >
      {showMoreBtn ? <ShowMoreText text={text} /> : text}
    </div>
  );
};
