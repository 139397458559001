import { useState } from "react";

import { useAuth } from "oidc-react";
import { Link } from "react-router-dom";
import { Header as HeaderLayout } from "./";

import { Avatar, AvatarRounded, AvatarSize } from "..";
import { Dropdown } from "../../pages/clients/api-resources/components";
import { getPostLoginUri, getUserInfo } from "../../utils";
import { cx } from "../../utils/classnames";
import { Docs } from "../Icons";
import { Breadcrumbs } from "./Breadcrumbs";
import { DropdownOption } from "./DropdownOption";
import { SettingsDropdown } from "./SettingsDropdown";

interface IDropdownVisibility {
  settingsDropdown: boolean;
  docsDropdown: boolean;
}

export const Header = () => {
  const auth = useAuth();
  const [visible, setVisible] = useState<IDropdownVisibility>({
    settingsDropdown: false,
    docsDropdown: false,
  });

  const {
    profile: { picture, email, name },
  } = getUserInfo();

  const settingsDropdownVisiblity = (visiblity: boolean) => {
    setVisible({
      ...visible,
      settingsDropdown: visiblity,
    });
  };

  const docsDropdownVisiblity = (visiblity: boolean) => {
    setVisible({
      ...visible,
      docsDropdown: visiblity,
    });
  };

  return (
    <HeaderLayout
      headerClass="header-wrapper z-30 bg-blue-dark"
      logo={
        <Link to="/">
          <img
            src="/images/porta-logo.svg"
            alt="Porta logo"
            style={{ width: 100, objectFit: "cover", height: "auto" }}
          />
        </Link>
      }
      buttonProps={{ onChange: () => {}, className: "md:mr-6 mr-1" }}
    >
      <>
        <div className="flex items-center flex-grow">
          <div className="hidden md:inline">
            <Breadcrumbs />
          </div>
          <div className="flex items-center ml-auto">
            <Dropdown
              className="mr-5"
              noPadding
              dropdownVisibility={docsDropdownVisiblity}
              dropdownContent={
                <>
                  <DropdownOption
                    isLink
                    path="https://guide.porta.gjirafa.tech/section/user-guide"
                    name="User Guide"
                    icon={<Docs />}
                    target="_blank"
                  />

                  <DropdownOption
                    isLink
                    path="https://guide.porta.gjirafa.tech/section/technical-documentation"
                    name="Developer Guide"
                    icon={<Docs />}
                    target="_blank"
                  />

                  <DropdownOption
                    isLink
                    path="https://guide.porta.gjirafa.tech/section/administrator-guide"
                    name="Administrator Guide"
                    icon={<Docs />}
                    target="_blank"
                  />
                </>
              }
            >
              <button
                type="button"
                className={cx([
                  "flex text-white items-center rounded px-3 py-2 hover:bg-gray-100 hover:bg-opacity-40 transition focus:shadow-outlineGray focus:outline-none active:bg-gray-100",
                  visible.docsDropdown && "shadow-outlineGray",
                ])}
              >
                <span>
                  <Docs />
                </span>
                <span className="ml-1 leading-3">Docs</span>
              </button>
            </Dropdown>

            <Dropdown
              noPadding
              dropdownVisibility={settingsDropdownVisiblity}
              dropdownContent={
                <SettingsDropdown
                  user={{
                    name: name || "",
                    email: email || "",
                    picture: picture || "",
                  }}
                  onSignOut={() => {
                    auth?.signOutRedirect();
                    getPostLoginUri(false);
                  }}
                />
              }
            >
              <div
                className={`rounded-full hover:shadow-outlineGray transition cursor-pointer ${
                  visible.settingsDropdown && "shadow-outlineGray"
                }`}
                title={name}
              >
                <Avatar
                  className="bg-white"
                  imgUrl={picture}
                  text={name || ""}
                  size={AvatarSize.sm}
                  rounded={AvatarRounded["rounded-full"]}
                />
              </div>
            </Dropdown>
          </div>
        </div>
      </>
    </HeaderLayout>
  );
};
