import { FC } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../../../utils";

interface IRemoveButton {
  onClick: () => void;
  className?: string;
}

export const RemoveButton: FC<IRemoveButton> = ({ onClick, className }) => {
  return (
    <button
      className={cx([
        "w-6 h-6 relative top-2 flex items-center justify-center text-primary-mainText hover:text-warning-hover",
        className,
      ])}
      title="Remove"
      type="button"
      onClick={onClick}
    >
      <Icon name="Close" size={24} />
    </button>
  );
};
