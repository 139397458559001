import { FC } from "react";

interface IPending {
  width?: string;
  height?: string;
}

export const Pending: FC<IPending> = ({ width, height }) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      style={{
        width: width || 24,
        height: height || 24,
      }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#FFAB00"
      />
    </svg>
  );
};
