import { FC } from "react";

interface ICheck {
  width?: string;
  height?: string;
}

export const Check: FC<ICheck> = ({ width, height }) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      style={{
        width: width || 24,
        height: height || 24,
      }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5303 9.53033C17.8232 9.23744 17.8232 8.76256 17.5303 8.46967C17.2374 8.17678 16.7626 8.17678 16.4697 8.46967L10 14.9393L7.53033 12.4697C7.23744 12.1768 6.76256 12.1768 6.46967 12.4697C6.17678 12.7626 6.17678 13.2374 6.46967 13.5303L9.11612 16.1768C9.60427 16.6649 10.3957 16.6649 10.8839 16.1768L17.5303 9.53033Z"
        fill="#29CC6A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
        fill="#29CC6A"
      />
    </svg>
  );
};
