import { useEffect, FC } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { BRANDINGS_PATH } from "../../constants";
import { useFetch } from "../../hooks";
import { IBranding } from "../../interfaces";
import { brandingConfigItemIState, SEO } from "../../utils";
import { BrandingForm } from "./components";

export const BrandingCreate: FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { apiCall: createBranding, loading } = useFetch("post");

  useEffect(() => {
    SEO({
      title: "Porta - Branding Create",
    });

    reset(brandingConfigItemIState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateBranding: SubmitHandler<any> = (data: IBranding) => {
    const brandingObj = {
      ...data,
      name: data.name.trim(),
    };
    createBranding(
      `/BrandingConfigurations/CreateBrandingConfiguration`,
      brandingObj,
      () => {
        toast.success("Costumization added successfully!");
        navigate(BRANDINGS_PATH);
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  return (
    <BrandingForm
      onSubmit={handleSubmit(onCreateBranding)}
      errors={errors}
      register={register}
      control={control}
      setValue={setValue}
      watch={watch}
      loading={loading}
      isCreate
    />
  );
};
