import { FC } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Avatar, AvatarRounded, AvatarSize } from "..";
import { DropdownOption } from "./DropdownOption";

interface ISettingsDropdown {
  user: {
    name: string;
    email: string;
    picture: string;
  };
  onSignOut: () => void;
}

export const SettingsDropdown: FC<ISettingsDropdown> = ({
  user,
  onSignOut,
}) => {
  return (
    <div className="w-full">
      <div className="flex py-2.5 px-4 border-b border-primary-stroke mb-2">
        <Avatar
          imgUrl={user.picture}
          text={user?.name}
          size={AvatarSize.sm}
          rounded={AvatarRounded["rounded-full"]}
        />

        <div className="ml-4">
          <h6 className="hover text-sm text-primary-mainText">{user.name}</h6>

          <p className="text-xs text-gray-600">
            {(user.email as any) instanceof Array ? user.email[0] : user?.email}
          </p>
        </div>
      </div>

      <DropdownOption
        isLink
        path={process.env.REACT_APP_USER_PROFILE}
        name="Profile"
        icon={<Icon name="Avatar" size={22} />}
      />

      <DropdownOption
        name="Log out"
        icon={<Icon name="Signout" size={20} />}
        onButtonClick={onSignOut}
      />
    </div>
  );
};
